import { API_URLS } from '../constants/appConstants'
import { BuyNotificationsData, CalculatePrice } from '../types/notifications'
import API from './api'

const getPerEmailPrice = async () => {
  try {
    const response = await API.get(API_URLS.PER_EMAIL_PRICE)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getPerSMSPrice = async () => {
  try {
    const response = await API.get(API_URLS.PER_SMS_PRICE)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const buyNotifications = async (data: BuyNotificationsData) => {
  return new Promise((resolve, reject) => {
    API.post(API_URLS.BUY_NOTIFICATIONS, data)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          if (response.data.success) {
            return resolve(response.data)
          } else {
            return reject(response.data)
          }
        } else {
          throw `request failed with status ${response.status}`
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

const getCreditCardDetail = async () => {
  try {
    const response = await API.get(API_URLS.GET_CREDIT_CARD)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getEmailCredits = async () => {
  try {
    const response = await API.get(API_URLS.GET_EMAILS_CREDITS)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}
const getSMSCredits = async () => {
  try {
    const response = await API.get(API_URLS.GET_SMS_CREDITS)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const calculatePrice = async (data: CalculatePrice) => {
  return new Promise((resolve, reject) => {
    API.post(API_URLS.CALCULATE_PRICE, data)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          return resolve(response.data)
        } else {
          throw `request failed with status ${response.status}`
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

export const notificationService = {
  getPerEmailPrice,
  getPerSMSPrice,
  buyNotifications,
  getCreditCardDetail,
  getEmailCredits,
  getSMSCredits,
  calculatePrice,
}
