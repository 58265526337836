import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import CompanyInfo from '../../components/CompanyInfo/CompanyInfo'
import SignInForm from '../../components/SignInForm'
import { ROUTES } from '../../constants/routesConstant'
import { ErrorToast } from '../../helpers/toster'
import { setToken, setUser } from '../../helpers/userService'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHook'
import { login, clearAuthState, setUserAsTanet } from '../../store/auth/authSlice'
import { loginFormStates } from '../../types/auth'

const Login: React.FC = (): JSX.Element => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {
    loginUserDetail,
    loginSucces,
    loginError,
    loginErrMessage = '',
  } = useAppSelector((state) => state.authReducer)

  useEffect(() => {
    return () => {
      dispatch(clearAuthState())
    }
  }, [])

  useEffect(() => {
    if (loginSucces) {
      processLogin()
    }
    if (loginError) {
      ErrorToast(loginErrMessage)
    }
  }, [loginSucces, loginError])

  const processLogin = async () => {
    setUser(loginUserDetail)
    setToken(loginUserDetail.tokens.access)
    const user = JSON.parse(localStorage.getItem('pmanagement.user') as string)
    if (loginUserDetail?.portal === 'tenant' || user.portal === 'tenant') {
      navigate(ROUTES.TENANT_MAIN_PAGE)
      dispatch(setUserAsTanet(true))
    } else {
      navigate(ROUTES.LANDLORD_MAIN_PAGE)
    }
  }

  const handleSubmit = async (formValues: loginFormStates) => {
    dispatch(login(formValues))
  }

  return (
    <div className='login-container'>
      <Row className='d-flex justify-content-center align-items-center h-100 w-100 p-0'>
        <Col className='col-lg-6 col-12 h-100 p-0'>
          <div className="login-wrapper">
            <SignInForm handleSubmit={handleSubmit} />
          </div>
        </Col>
        <Col className='col-lg-6 position-relative d-lg-flex d-none p-0'>
          <CompanyInfo />
        </Col>
      </Row>
    </div>
  )
}

export default Login
