import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { PropertiesListData, TenantsListData, UnitsListData } from '../../types/properties';

interface PropertiesStates {
    propertiesList: PropertiesListData[];
    selectedProperty: PropertiesListData | null;
    totalPropertiesCount: number;
    unitsList: UnitsListData[];
    selectedPropertyUnit: UnitsListData | null;
    totalUnitsCount: number;
    tenantsList: TenantsListData[];
    selectedTenant: TenantsListData | null;
    totalTenantsCount: number;
}

const initialState: PropertiesStates = {
    propertiesList: [],
    selectedProperty: null,
    totalPropertiesCount: 0,
    unitsList: [],
    selectedPropertyUnit: null,
    totalUnitsCount: 0,
    tenantsList: [],
    selectedTenant: null,
    totalTenantsCount: 0
}

const propertiesSlice = createSlice({
    name: 'properties',
    initialState,
    reducers: {
        setPropertiesList: (state, action) => {
            state = { ...state, propertiesList: action.payload.propertiesList, totalPropertiesCount: action.payload.totalPropertiesCount }
            return state;
        },
        selectedProperty: (state, action) => {
            state = { ...state, selectedProperty: action.payload }
            return state;
        },
        setUnitsList: (state, action) => {
            state = { ...state, unitsList: action.payload.unitsList, totalUnitsCount: action.payload.totalUnitsCount }
            return state;
        },
        selectedPropertyUnit: (state, action) => {
            state = { ...state, selectedPropertyUnit: action.payload }
            return state;
        },
        setTenantsList: (state, action) => {
            state = { ...state, tenantsList: action.payload.tenantsList, totalTenantsCount: action.payload.totalTenantsCount }
            return state;
        },
        selectedTenant: (state, action) => {
            state = { ...state, selectedTenant: action.payload }
            return state;
        },
        unsetSelecteditem: (state, action) => {
            state = { ...state, [action.payload]: null }
            return state;
        }
    },
});

const { setPropertiesList, selectedProperty, setUnitsList, selectedPropertyUnit, unsetSelecteditem, setTenantsList, selectedTenant } = propertiesSlice.actions;

export const savePropertiesListData = (propertiesList: PropertiesListData[], totalPropertiesCount: number) => async (dispatch: Dispatch) => {
    return dispatch(setPropertiesList({propertiesList, totalPropertiesCount}));
}

export const setSelectedProperty = (propertyData: PropertiesListData) => async (dispatch: Dispatch) => {
    return dispatch(selectedProperty(propertyData));
}

export const saveUnitsListData = (unitsList: UnitsListData[], totalUnitsCount: number) => async (dispatch: Dispatch) => {
    return dispatch(setUnitsList({unitsList, totalUnitsCount}));
}

export const setSelectedPropertUnit = (unitsData: UnitsListData) => async (dispatch: Dispatch) => {
    return dispatch(selectedPropertyUnit(unitsData));
}

export const saveTenantsListData = (tenantsList: TenantsListData[], totalTenantsCount: number) => async (dispatch: Dispatch) => {
    return dispatch(setTenantsList({tenantsList, totalTenantsCount}));
}

export const setSelectedTenant = (tenantsData: TenantsListData) => async (dispatch: Dispatch) => {
    return dispatch(selectedTenant(tenantsData));
}

export const clearSelecteditem = (selectedItem: string) => async (dispatch: Dispatch) => {
    return dispatch(unsetSelecteditem(selectedItem));
}

export default propertiesSlice.reducer;