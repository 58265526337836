import { createSlice, Dispatch } from '@reduxjs/toolkit'
import { MaintenanceListData } from '../../types/maintenance'

interface MaintenanceStates {
  maintenances: MaintenanceListData[]
  selectedMaintenance: MaintenanceListData
  totalListCount: number
  actionItems: number[]
}

const initialState: MaintenanceStates = {
  maintenances: [],
  selectedMaintenance: {
    id: 0,
    assigned_unit_name: '',
    assignee_name: '',
    description: '',
    task_type: '',
    created: '',
    status: '',
    title: '',
    images: [],
    property_address: '',
    property_title: '',
    status_name: '',
    type: 0,
    unit: 0,
  },
  totalListCount: 0,
  actionItems: [],
}

const maintenanceSlice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
    setMaintenanceList: (state, action) => {
      state = {
        ...state,
        maintenances: action.payload.maintenanceList,
        totalListCount: action.payload.totalListCount,
      }
      return state
    },
    setSelectedMaintenanceData: (state, action) => {
      state = { ...state, selectedMaintenance: action.payload }
      return state
    },
    updateSelectedMaintenance: (state, action) => {
      state = {
        ...state,
        selectedMaintenance: { ...state.selectedMaintenance, status_name: action.payload },
      }
      return state
    },
    setActionItems: (state, action) => {
      state = { ...state, actionItems: [...action.payload] }
      return state
    },
    clearActionItems: (state) => {
      state = { ...state, actionItems: [] }
      return state
    },
  },
})

const {
  setMaintenanceList,
  setSelectedMaintenanceData,
  updateSelectedMaintenance,
  setActionItems,
  clearActionItems,
} = maintenanceSlice.actions

export const saveMaintenance =
  (maintenanceList: MaintenanceListData[], totalListCount: number) =>
  async (dispatch: Dispatch) => {
    return dispatch(setMaintenanceList({ maintenanceList, totalListCount }))
  }

export const setSelectedMaintenance =
  (maintenanceData: MaintenanceListData) => async (dispatch: Dispatch) => {
    return dispatch(setSelectedMaintenanceData(maintenanceData))
  }

export const updateMaintenance = (status: string) => async (dispatch: Dispatch) => {
  return dispatch(updateSelectedMaintenance(status))
}

export const setActionRows = (data: number[]) => async (dispatch: Dispatch) => {
  return dispatch(setActionItems(data))
}

export const clearActionRows = () => async (dispatch: Dispatch) => {
  return dispatch(clearActionItems())
}

export default maintenanceSlice.reducer
