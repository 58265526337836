import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'

import { Col, Row, Container } from 'reactstrap'
import { DataTable } from 'primereact/datatable'
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator'
import { Column } from 'primereact/column'
import { Badge } from 'primereact/badge';

import { ROUTES } from '../../../constants/routesConstant'
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHook'
import {
  clearSelecteditem,
  setSelectedPropertUnit,
} from '../../../store/properties/propertiesSlice'
import { findDayNth } from '../../../helpers/utils'
import { ErrorToast } from '../../../helpers/toster'
import { propertiesService } from '../../../helpers/propertiesService'
import { UnitsListData } from '../../../types/properties'
import DeleteConfirmModal from '../../../components/common/DeleteConfirm/DeleteConfirmModal'
import Dropdown from '../../../components/common/Dropdown/Dropdown'
import DetailView from "../../../components/common/DetailView/DetailView"
import ImagePreview from '../../../components/common/ImagePreview/ImagePreview'


const paginationTemplate = {
  layout: 'PrevPageLink PageLinks NextPageLink',
}

interface UnitListProps {
  getUnitsData: () => void
}

const UnitsList = (props: UnitListProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { unitsList, totalUnitsCount } = useAppSelector((state) => state.propertiesReducer)
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(8)
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsToShow, setRowsToShow] = useState<UnitsListData[]>([])
  const [selectedUnit, setSelectedUnit] = useState<UnitsListData | null>(null)
  const [showDeleteConfirmModal, setDeleteConfirmModal] = useState(false)
  const [selectedUnitId, setSelectedUnitId] = useState<number | null>(null)
  const [showDetailsView, setShowDetailsView] = useState(false)

  useEffect(() => {
    dispatch(clearSelecteditem('selectedPropertyUnit'))
    setRowsToShow(unitsList.slice(0, 8))
  }, [])

  useEffect(() => {
    setRowsToShow(unitsList.slice(0, 8))
  }, [unitsList])

  const onPageChange = (e: PaginatorPageChangeEvent) => {
    setFirst(e.first)
    setRows(e.rows)
    setCurrentPage(e.page + 1)
    setRowsToShow(unitsList.slice(e.first, e.rows * (e.page + 1)))
  }

  const formatRent = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleRowSelect = (rowData: UnitsListData) => {
    dispatch(setSelectedPropertUnit(rowData))
  }

  const handleDeletePropertyUnit = async () => {
    try {
      await propertiesService.deletePropertyUnit(selectedUnitId)
      props.getUnitsData()
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.data?.detail) {
          ErrorToast(err.response?.data?.detail || 'Error in delete property unit')
        } else {
          ErrorToast('Error in delete property unit')
        }
      }
    }
  }

  /* Templates */

  const tenantTemplate = (rowData: UnitsListData) => {
    return rowData.assigned_to
      ? rowData.assigned_to?.first_name
        ? `${rowData.assigned_to?.first_name} ${rowData.assigned_to?.last_name}`
        : `${rowData.assigned_to?.first_name_by_ll} ${rowData.assigned_to?.last_name_by_ll}`
      : 'N/A'
  }

  const featureTemplate = (rowData: UnitsListData) => {
    return (
      <div className='features-list'>
        {rowData.features.map((feature, index) => {
          if (index > 3) {
            return null
          } else if (index === 3) {
            return <span key={index} className='me-1'>...</span>
          }
          return <Badge key={index} className='me-1 badge-list' value={feature} />
        })}
      </div>
    )
  }

  const createdDatedTemplate = (rowData: UnitsListData) => {
    return moment(rowData.created).format('MMM D, YYYY')
  }

  const descriptionTemplate = (rowData: UnitsListData) => {
    return (
      <div>{`${rowData.description.slice(0, 26)}${rowData.description.length > 26 ? '...' : ''
        }`}</div>
    )
  }

  const dropDownTemplate = (rowData: UnitsListData) => {
    const DropdownRef = useRef<any>(null)
    const dropdownItems: Array<any> = [
      {
        text: 'Open details',
        onClick: (e: any) => {
          DropdownRef.current?.toggle(e)
          setSelectedUnit(rowData)
          setShowDetailsView(true)
        }
      },
      {
        text: 'Edit unit',
        onClick: (e: any) => {
          DropdownRef.current?.toggle(e)
          setSelectedUnit(rowData)
          navigate(`${ROUTES.EDIT_UNITS}/${rowData.id}`)
        }
      },
      {
        text: 'Delete unit',
        onClick: (e: any) => {
          DropdownRef.current?.toggle(e)
          setSelectedUnit(rowData)
          setDeleteConfirmModal(true)
          setSelectedUnitId(rowData.id)
        },
        className: 'text-danger'
      },
    ]
    return (
      <Dropdown ref={DropdownRef} onClick={(e: any) => DropdownRef.current?.toggle(e)} content={dropdownItems} />
    )
  }

  return (
    <>
      <DetailView closeButton={showDetailsView} onCloseView={(e: boolean) => { setShowDetailsView(e) }}
        editButton={() => navigate(`${ROUTES.EDIT_UNITS}/${selectedUnit?.id}`)}
        deteleButton={() => setDeleteConfirmModal(true)}>
        <h2>{selectedUnit?.unit_name}</h2>
        <Row>
          <Col className='col-12 col-sm-6'><h4>Property</h4></Col>
          <Col className='col-12 col-sm-6'><p>{selectedUnit?.property_title}</p></Col>
        </Row>
        <Row>
          <Col className='col-12 col-sm-6'><h4>Tenant</h4></Col>
          <Col className='col-12 col-sm-6'><p>{selectedUnit && tenantTemplate(selectedUnit)}</p></Col>
        </Row>
        <Row>
          <Col className='col-12 col-sm-6'><h4>Rent</h4></Col>
          <Col className='col-12 col-sm-6'><p>{selectedUnit?.monthly_rent ? `USD ${formatRent(selectedUnit?.monthly_rent)}` : "N/A"}</p></Col>
        </Row>
        <Row>
          <Col className='col-12 col-sm-6'><h4>Unit size</h4></Col>
          <Col className='col-12 col-sm-6'><p>{selectedUnit?.area_size ? `${selectedUnit?.area_size} sq ft` : "N/A"}</p></Col>
        </Row>
        <hr />
        <h3 className='mb-4'>Unit Details</h3>
        <Row>
          <Col className='col-12'><h4>Features</h4></Col>
          <Col className='col-12'><p>{selectedUnit?.features.map((feature, index) => {
            return <Badge key={index} className='me-1 badge-list' value={feature} />
          })}</p>
          </Col>
        </Row>
        <Row>
          <Col className='col-12'><h4>Description</h4></Col>
          <Col className='col-12'><p>{selectedUnit?.description}</p></Col>
        </Row>
        <Row>
          <Col className='col-12'><h4>Images</h4></Col>
          <Col className='col-12'><ImagePreview imagesData={selectedUnit?.images || []} /></Col>
        </Row>
      </DetailView>
      <div className='data-list datatable-listing pb-2 mb-3'>
        <DataTable
          scrollable
          editMode='row'
          value={rowsToShow}
          dataKey='unit_id'
          tableStyle={{ minWidth: '50rem' }}
        >
          <Column
            sortable
            className='fw-semibold text-black'
            header='Unit Name'
            body={(rowData) => (
              <span onClick={(e) => {
                e.stopPropagation()
                handleRowSelect(rowData)
              }}>
                {rowData.unit_name}
              </span>
            )}
          />
          <Column
            sortable
            className='fw-semibold text-black'
            field='property_title'
            header='Property'
          />
          <Column
            sortable
            className='fw-semibold text-black'
            field='assigned_to'
            header='Tenant'
            body={(rowData: UnitsListData) =>
              rowData?.assigned_to
                ? rowData.assigned_to?.first_name
                  ? `${rowData.assigned_to?.first_name} ${rowData.assigned_to?.last_name}`
                  : `${rowData.assigned_to?.first_name_by_ll} ${rowData.assigned_to?.last_name_by_ll}`
                : 'N/A'
            }
          />
          <Column
            sortable
            className='fw-semibold text-black'
            field='rent_due_day'
            header='Rent due date'
            body={(rowData: UnitsListData) =>
              ` ${rowData.rent_due_day}${findDayNth(rowData.rent_due_day)} of each month`
            }
          />
          <Column
            sortable
            className='fw-semibold text-black'
            field='monthly_rent'
            header='Monthly rent, USD'
            body={(rowData: UnitsListData) => `$${formatRent(rowData.monthly_rent)}`}
          />

          <Column sortable className='fw-semibold text-black' field='area_size' header='Unit size, sq ft' />
          <Column sortable className='fw-semibold text-black' field='features' header='Features' body={featureTemplate} />
          <Column sortable className='fw-semibold text-black' field='description' header='Description' body={descriptionTemplate} />
          <Column sortable className='fw-semibold text-black' field='created' header='Creation date' body={createdDatedTemplate} />
          <Column headerStyle={{ width: '4rem' }} body={dropDownTemplate} />
        </DataTable>

        <div className='pagination-section mt-3 d-flex align-items-center'>
          <div className='total-records'>
            Results: {first + 1}-
            {totalUnitsCount - first < rows
              ? first + (totalUnitsCount - first)
              : rows * currentPage}{' '}
            of {totalUnitsCount}
          </div>
          {unitsList.length > 8 && (
            <div className='pagination-container ms-auto'>
              <Paginator
                template={paginationTemplate}
                first={first}
                rows={rows}
                totalRecords={totalUnitsCount}
                onPageChange={(e) => onPageChange(e)}
                className='justify-content-start'
              />
            </div>
          )}
        </div>
      </div>

      <DeleteConfirmModal
        handleReject={() => {
          setDeleteConfirmModal(false)
          setSelectedUnitId(null)
        }}
        handleAccept={() => { handleDeletePropertyUnit(); setDeleteConfirmModal(false) }}
        isModalOpen={showDeleteConfirmModal}
        header={'Delete Confirmation'}
        message={'Do you want to delete this property unit'}
      />
    </>
  )
}

export default UnitsList
