import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHook'
import { FormGroup, Col, Input, Label, Row, Card, CardHeader, CardBody } from 'reactstrap'
import { Button } from "primereact/button";
import { Formik, Form } from 'formik'
import { RentRequest, TenantPaymentSchedulingRequest } from '../../types/tenantDetails'
import DialogBox from '../../components/common/Dialog/Index'
import { DialogData } from '../../types/setting'
import searchIcon from '../../assets/images/svgImages/search_icon.svg'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../constants/routesConstant'
import { setActivePageHeader } from '../../store/auth/authSlice'
import { tenantService } from '../../helpers/tenantService'
import { ErrorToast } from '../../helpers/toster'
import { setTenantBankDetails } from '../../store/tenant/tenantSlice'
import * as Yup from 'yup'
import addBankIcon from '../../assets/images/svgImages/add bank_icon.svg'
import { DaysSelection } from '../../constants/appConstants'

const initialValue: RentRequest = {
  amount: 0,
  dueDate: '',
  paymentMethod: '',
}

const schedulePaymentValidationSchema = () =>
  Yup.object().shape({
    amount: Yup.number().required('Amount is required*'),
    dueDate: Yup.number().required('Scheduled Day is required*'),
  })

const SchedulePaymentComponent = () => {
  const dispatch = useAppDispatch()
  const [visible, setVisible] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(setActivePageHeader('Schedule Payment'))
    checkIfBankAvailable()
  }, [])

  const { currentActivatedBankAccount } = useAppSelector((state) => state.tenantReducer)
  const dialogData = () => {
    let data = {} as DialogData
    data = {
      heading: '',
      subheading: 'Thank You!',
      buttonText: 'Go to dashboard',
    }
    data.heading = 'Your payment is successfully Scheduled'

    return data
  }

  const checkIfBankAvailable = async () => {
    if (currentActivatedBankAccount?.id) {
      initialValue.paymentMethod = currentActivatedBankAccount?.id
    } else {
      const response = await tenantService.getPaymentMethod()
      if (response?.data) {
        dispatch(setTenantBankDetails(response.data))
        initialValue.paymentMethod = response?.data?.id
      } else {
        ErrorToast(response)
        initialValue.paymentMethod = ''
      }
    }
  }

  const handleSubmit = async (values: RentRequest) => {
    let active = false
    if (currentActivatedBankAccount.id) {
      active = true
    } else {
      active = false
    }

    const payload: TenantPaymentSchedulingRequest = {
      payment_day: values.dueDate as string,
      active: active,
    }
    const response = await tenantService.TenantPaymentScheduling(payload)
    if (!response.success) {
      ErrorToast(response?.msg)
    }
    if (response?.id) {
      setVisible(true)
    }
  }

  const setPageRendering = () => {
    navigate(ROUTES.TENANT_PAY_RENT)
  }

  const addBank = async () => {
    navigate(ROUTES.TENANT_ADDBANK_DETAILS)
  }

  return (
    <>
      {currentActivatedBankAccount?.id ? (
        <>
          <DialogBox
            open={visible}
            setOpen={(status: boolean) => setVisible(status)}
            isSuccess={true}
            data={dialogData()}
          ></DialogBox>

          <Row className='pb-4'>
            <Col md={8}>
              <Col md={6}>
                {/* <div className='searchbar-container me-4'>
                  <input className='form-control' placeholder='Search here...' />
                  <div className='search-icon cursor-pointer'>
                    <img src={searchIcon} alt='search-icon' />
                  </div>
                </div> */}
              </Col>
            </Col>
            <Col md={4}>
              <div className='d-flex justify-content-end'>
                <Button
                  type='button'
                  className='headerButton btn submit-btn'
                  onClick={setPageRendering}
                >
                  {'Pay Rent'}
                </Button>
              </div>
            </Col>
          </Row>

          <Card className='form-card'>
            <CardHeader>Schedule Payment</CardHeader>
            <CardBody>
              <Formik
                initialValues={initialValue}
                onSubmit={(value: RentRequest) => {
                  handleSubmit(value)
                }}
                validationSchema={() => schedulePaymentValidationSchema()}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  touched,
                  errors,
                  dirty,
                  isValid,
                }) => (
                  <Form>
                    <Row className='mt-5'>
                      <Col md={7}>
                        <Col md={8}>
                          <FormGroup className='mb-4'>
                            <Label className='formLabel' for='amount'>
                              Amount
                            </Label>
                            <Input
                              id='amount'
                              name='amount'
                              type='number'
                              placeholder='Enter Amount'
                              value={values?.amount}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                            />
                            {touched.amount && errors.amount && (
                              <span className='error-text text-danger'>{errors.amount}</span>
                            )}
                          </FormGroup>
                        </Col>

                        <Col md={8}>
                          <FormGroup className='mb-4'>
                            <Label className='formLabel' for='dueDate'>
                              Scheduled Day For Every Month
                            </Label>
                            <Input
                              id='dueDate'
                              type='select'
                              bsSize='lg'
                              name='dueDate'
                              placeholder='0'
                              value={values?.dueDate}
                              onChange={(e) => setFieldValue('dueDate', e.target.value)}
                              onBlur={handleBlur}
                            >
                              <>
                                <option>select Scheduled Day</option>
                                {DaysSelection.map((data, index) => (
                                  <option key={index} value={data}>
                                    {data}
                                  </option>
                                ))}
                              </>
                            </Input>
                            {touched.dueDate && errors.dueDate && (
                              <span className='error-text text-danger'>{errors.dueDate}</span>
                            )}
                          </FormGroup>
                        </Col>

                        <Row>
                          <Col md={8}>
                            <FormGroup className='mb-4'>
                              <Label className='formLabel' for='paymentMethod'>
                                Payment method
                              </Label>
                              <Input
                                id='paymentMethod'
                                name='paymentMethod'
                                type='text'
                                value={values.paymentMethod}
                                onChange={handleChange}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <Col md={6}>
                              <div className='addBankbuttonStyling'>
                                <Button className='addBankDetails submit-btn'>
                                  <span>
                                    {currentActivatedBankAccount?.id ? 'Edit Bank' : 'Add Details'}
                                  </span>
                                </Button>
                              </div>
                            </Col>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className='mt-5'>
                      <Col md={6}>
                        <p className='mb-0'>
                          Note: By Scheduling Your rent will be paid automatically on due date
                        </p>
                      </Col>
                      <Col md={6}>
                        <div className='addBankButton d-flex justify-content-end'>
                          <Button
                            type='button'
                            className='btn submit-btn'
                            onClick={() => handleSubmit(values)}
                            disabled={!dirty || !isValid}
                          >
                            {'Schedule Payment'}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </>
      ) : (
        <>
          <div className='paymentLanding'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='text-center'>
                  <img src={addBankIcon} alt='Add bank' />
                  <p className='paymentLanding-prop'>{'No Payment Method Attached'}</p>
                  <div className='xyzz'>
                    <Button className='PaymentAddbutton' onClick={addBank}>
                      <span>Add Bank</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default SchedulePaymentComponent
