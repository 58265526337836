import { useEffect, useState } from 'react'
import { setActivePageHeader } from '../../store/auth/authSlice'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHook'
import { OverviewDetailCard } from '../../components/common/OverviewDetailCard/OverviewDetailCard'
import { Card, CardBody, Col, Row } from 'reactstrap'
import propertiesIcon from '../../assets/images/svgImages/properties_icon.svg'
import propertiesOverIcon from '../../assets/images/svgImages/ic_property_overview.svg'
import maintenanceIcon from '../../assets/images/svgImages/maintenance_icon.svg'
import TenantPaymentListData from './TenantPaymentListPage'
import NoListingView from '../../components/common/NoListingView/NoListingView'
import { tenantService } from '../../helpers/tenantService'

const TenantOverview = () => {
  const dispatch = useAppDispatch()

  const { overviewPaymentList, totalListCount } = useAppSelector((state) => state.tenantReducer)
  const [ nonFinancialData, setNonFinancialData ] = useState({
        property_name:'',
        monthly_rent:0,
        area_size:'0',
        rent_due_day:1,
        open_tasks:0,
        completed_tasks:0
  })
 
  useEffect(() => {
    dispatch(setActivePageHeader('Overview'))
    getNonFinacialData();
  }, [])

    const getNonFinacialData = async () => {
      const response = await tenantService.getNonFinancialStatsForTenant();
        if(response){
          setNonFinancialData({
            property_name:(response.property_name ? response.property_name : 'N/A') as string ,
            monthly_rent:(response.monthly_rent ? response.monthly_rent : 0 ),
            area_size:(response.area_size ? response?.area_size : 'N/A'),
            rent_due_day:(response.rent_due_day ? response.rent_due_day : 1 ),
            open_tasks:(response.open_tasks ? response?.open_tasks : 0 ),
            completed_tasks:(response.completed_tasks ? response.completed_tasks : 0 ),
          })
    }
  }
  

  return (
    <>
      <div className='p-4'>
        <Row className='pb-4'>
          <Col md={6}>
            <Card className='tenantOverViewCard'>
              <CardBody>
                <div className='d-flex align-items-center'>
                  <div className='iconDetails d-flex align-items-center justify-content-center'>
                    <div className='icon-custom'>
                      {<img className='m-1' src={propertiesOverIcon} alt='' />}
                    </div>
                  </div>
                  <div className='property-card-details'>
                    {/* static */}
                    <div className='property-heading'>{nonFinancialData.property_name}</div>
                    <span className='property-subHeading'>{'Assigned Property'}</span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col  md={6}>
            <OverviewDetailCard 
              heading={  nonFinancialData.monthly_rent !== 0 ?  (`${'$'}${nonFinancialData?.monthly_rent} ${'Rent'}`) : '0 Rent'}
              subHeading={ nonFinancialData?.rent_due_day ? `${'By '}${nonFinancialData?.rent_due_day}${ ' of Month' }` : 'By Month End' }
              icon={maintenanceIcon}
            ></OverviewDetailCard>
          </Col>
        </Row>
        <Row className='pb-4'>
          <Col md={4}>
            <OverviewDetailCard
              heading={  nonFinancialData.area_size !== 'N/A' ?  (`${nonFinancialData.area_size} ${'Sq ft'}`) : 'N/A'}
              subHeading='Unit Size'
              icon={propertiesIcon}
            ></OverviewDetailCard>
          </Col>
          <Col md={4}>
            <OverviewDetailCard
              heading={nonFinancialData.open_tasks}
              subHeading='Opened Tasks'
              icon={maintenanceIcon}
            ></OverviewDetailCard>
          </Col>
          <Col md={4}>
            <OverviewDetailCard
              heading={nonFinancialData.completed_tasks}
              subHeading='Completed Tasks'
              icon={maintenanceIcon}
            ></OverviewDetailCard>
          </Col>
        </Row>

        {/* hide payments history data for now */}
        {/* {overviewPaymentList?.length ? <TenantPaymentListData  paymentListData= { overviewPaymentList } totalCount={ totalListCount } /> :
          <NoListingView noPropText="You Do Not Have Any Payment History" addPropText="Please Check" />
        } */}
        <NoListingView noPropText="You Do Not Have Any Payment History" addPropText="Please Check" />


      </div>
    </>
  )
}

export default TenantOverview
