import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import axios from 'axios'

import { Formik, Form } from 'formik'
import {
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Tooltip,
} from 'reactstrap'
import { Button } from "primereact/button";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { Calendar } from 'primereact/calendar'
import { ROUTES } from '../../../constants/routesConstant'
import { useAppDispatch } from '../../../hooks/storeHook'
import { setActivePageHeader } from '../../../store/auth/authSlice'
import { AddTenantFormStates, DisplayNamesData, TenantDetailById } from '../../../types/properties'
import { propertiesService } from '../../../helpers/propertiesService'
import { ErrorToast, InfoToast } from '../../../helpers/toster'
import CommonAlertModal from '../../../components/common/CommonAlertModal/CommonAlertModal'

import infoIcon from '../../../assets/images/svgImages/icon_info.svg'
import clearIcon from '../../../assets/images/svgImages/clear_input_icon.svg'
import cartFilledIcon from '../../../assets/images/svgImages/cart_filled_icon.svg'
import moment from 'moment'

const initialValues: AddTenantFormStates = {
  first_name_by_ll: '',
  last_name_by_ll: '',
  email: '',
  phone: '',
  property: '',
  unit: '',
  lease_start_date: null,
  lease_end_date: null,
}

const addTenantValidationSchema = () =>
  Yup.object().shape({
    first_name_by_ll: Yup.string().required('First Name is Required'),
    last_name_by_ll: Yup.string().required('Last Name is Required'),
    email: Yup.string().required('Email is required*').email('invalid email'),
    phone: Yup.string().required('Phone Number is required*'),
    lease_start_date: Yup.date().nullable(),
    lease_end_date: Yup.date().nullable(),
  })

const AddTenants = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { tenantId } = useParams()
  const [displayProperties, setDisplayProperties] = useState<DisplayNamesData[]>([])
  const [displayPropertyUnits, setDisplayPropertyUnits] = useState<DisplayNamesData[]>([])
  const [isEditMode] = useState(tenantId ? true : false)
  const [formInitialValues, setFormInitialValues] = useState(initialValues)
  const [showOutOfUnitConnectsModal, setShowOutOfUnitConnectsModal] = useState(false)
  const [assignTenantCheck, setAssignTenantCheck] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [tooltipOpen1, setTooltipOpen1] = useState(false)
  const [tooltipOpen2, setTooltipOpen2] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  const toggle1 = () => setTooltipOpen1(!tooltipOpen1)
  const toggle2 = () => setTooltipOpen2(!tooltipOpen2)


  useEffect(() => {
    dispatch(setActivePageHeader(isEditMode ? 'Edit Tenant' : 'New Tenant'))
    getDisplayPropertiesData()
  }, [])

  const getDisplayPropertiesData = async () => {
    const response = await propertiesService.getDisplayProperties()
    if (response.results && response.results.length > 0) {
      setDisplayProperties(response.results)
      if (isEditMode) {
        processEditFormData()
      }
    }
  }
  let selectedTenantData: TenantDetailById
  const processEditFormData = async () => {
    const response = await propertiesService.getTenantById(tenantId)
    if (axios.isAxiosError(response)) {
      if (response.response?.data?.detail) {
        ErrorToast(response.response?.data?.detail || 'Error in get tenant data')
      } else {
        ErrorToast('Error in get tenant data')
      }
    } else {
      selectedTenantData = response
      if (selectedTenantData?.property_id !== null) {
        getDisplayPropertyUnitsData(selectedTenantData.property_id)
      }
      setFormInitialValues({
        first_name_by_ll: selectedTenantData.first_name_by_ll,
        last_name_by_ll: selectedTenantData.last_name_by_ll,
        email: selectedTenantData.email,
        phone: selectedTenantData.phone,
        property: selectedTenantData.property_id || '',
        unit: selectedTenantData.unit_id,
        lease_start_date: selectedTenantData?.lease_start_date
          ? new Date(selectedTenantData?.lease_start_date)
          : null,
        lease_end_date: selectedTenantData?.lease_end_date
          ? new Date(selectedTenantData?.lease_end_date)
          : null,
      })

      if (selectedTenantData.property_id) {
        setAssignTenantCheck(true)
      }
    }
  }

  const getDisplayPropertyUnitsData = async (propertyId: string) => {
    const response = await propertiesService.getDisplayPropertyUnits(
      propertyId,
      isEditMode ? false : true,
    )
    if (response.results && response.results.length > 0) {
      setDisplayPropertyUnits(response.results)
    } else {
      setDisplayPropertyUnits([])
      InfoToast('There is no unit available for selected property')
    }
  }

  const handleSubmit = async (formvalues: AddTenantFormStates) => {
    setIsLoading(true)
    try {
      if (isEditMode) {
        await propertiesService.editTenant(formvalues, tenantId)
        navigate(ROUTES.TENANTS)
      } else {
        await propertiesService.addTenant(formvalues)
        navigate(ROUTES.TENANTS)
      }
      setIsLoading(false)
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.data) {
          if (err.response?.data?.email) {
            ErrorToast(err.response?.data?.email[0] || 'Error in add tenant')

          } else if (err.response?.data?.unit) {
            ErrorToast(err.response?.data?.unit[0] || 'Error in add tenant')

          } else {
            ErrorToast('Error in add tenant')

          }
        } else {
          ErrorToast('Error in add tenant')

        }
      } else {
        if (err === 'You are out of unit connects.') {
          setShowOutOfUnitConnectsModal(true)
        } else {
          ErrorToast('Error in add tenant')

        }
      }
      setIsLoading(false)
    }
  }

  return (
    <>

      <div className='col-12 col-sm-10'>
        <h2 className="title mb-5">{isEditMode ? "Edit a tenant" : "Create a tenant"}</h2>
        <Formik
          initialValues={formInitialValues}
          enableReinitialize={true}
          onSubmit={(values: AddTenantFormStates) => handleSubmit(values)}
          validationSchema={() => addTenantValidationSchema()}
        >
          {({
            errors,
            values,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
            dirty,
            isValid,
          }) => (
            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for='first_name_by_ll'>
                      First Name<span className='required-astrick'>*</span>
                    </Label>
                    <Input
                      id='first_name_by_ll'
                      name='first_name_by_ll'
                      value={values.first_name_by_ll}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type='text'
                      placeholder='First Name'
                    />
                    {touched.first_name_by_ll && errors.first_name_by_ll && (
                      <span className='error-text text-danger'>
                        {errors.first_name_by_ll}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for='last_name_by_ll'>
                      Last Name<span className='required-astrick'>*</span>
                    </Label>
                    <Input
                      id='last_name_by_ll'
                      name='last_name_by_ll'
                      value={values.last_name_by_ll}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type='text'
                      placeholder='Last Name'
                    />
                    {touched.last_name_by_ll && errors.last_name_by_ll && (
                      <span className='error-text text-danger'>{errors.last_name_by_ll}</span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup className='mb-4'>
                    <Label for='email'>
                      Email Address<span className='required-astrick'>*</span>
                    </Label>
                    <Input
                      id='email'
                      name='email'
                      placeholder='Email Address'
                      onChange={handleChange}
                      value={values.email}
                      onBlur={handleBlur}
                      disabled={isEditMode}
                    />
                    {touched.email && errors.email && (
                      <span className='error-text text-danger'>{errors.email}</span>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className='mb-4'>
                    <Label for='phone'>
                      Phone Number<span className='required-astrick'>*</span>
                    </Label>
                    <PhoneInput
                      className='form-control'
                      value={values.phone}
                      defaultCountry='US'
                      smartCaret={true}
                      onChange={(e: any) => setFieldValue('phone', e)}
                      required
                      onBlur={handleBlur}
                      name='phone'
                      maxLength={14}
                      id='phone'
                    />
                    {touched.phone && errors.phone && (
                      <span className='error-text text-danger'>{errors.phone}</span>
                    )}
                    {values.phone &&
                      !errors.phone &&
                      isValidPhoneNumber(values.phone) === false && (
                        <span className='error-text text-danger'>Invalid Phone Number</span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className='select-input'>
                    <Label>
                      <Input
                        id='assign-tenant'
                        name='assign-tenant'
                        type="checkbox"
                        className='form-check-input me-2'
                        checked={assignTenantCheck}
                        onChange={(e) => { setAssignTenantCheck(e.target.checked) }}
                      />
                      Assign Property{' '}
                      <img src={infoIcon} id='checkbox-tooltip' />
                      <Tooltip
                        placement='top'
                        isOpen={tooltipOpen2}
                        autohide={false}
                        target='checkbox-tooltip'
                        toggle={toggle2}
                      >
                        Please fill in all required fields
                      </Tooltip>
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              {assignTenantCheck ? (
                <>
                  <Row>
                    <Col md={6}>
                      <FormGroup className='select-input'>
                        <Label for='property'>Property name</Label>
                        {!isEditMode && (
                          <>
                            <img src={infoIcon} id='infoTooltip' />
                            <Tooltip
                              placement='top'
                              isOpen={tooltipOpen}
                              autohide={false}
                              target='infoTooltip'
                              toggle={toggle}
                            >
                              You can edit this information later by editing the tenant details
                            </Tooltip>
                          </>
                        )}
                        {values.property && (
                          <a
                            onClick={() => {
                              setFieldValue('unit', '')
                              setFieldValue('property', '')
                              setDisplayPropertyUnits([])
                            }}
                          >
                            <img src={clearIcon} className='clear-icon cursor-pointer' />
                          </a>
                        )}
                        <Input
                          id='property'
                          name='property'
                          type='select'
                          bsSize='lg'
                          value={values.property}
                          onChange={(e: ChangeEvent) => {
                            setFieldValue('unit', '')
                            setFieldValue('lease_start_date', null)
                            setFieldValue('lease_end_date', null)
                            setFieldValue('property', (e.target as HTMLInputElement).value)
                            getDisplayPropertyUnitsData((e.target as HTMLInputElement).value)
                          }}
                          onBlur={handleBlur}
                        >
                          <option disabled value={''}>
                            Please choose a Property
                          </option>
                          {displayProperties.map((properties, index) => (
                            <option key={index} value={properties.id}>
                              {properties.display_name}
                            </option>
                          ))}
                        </Input>
                        {touched.property && errors.property && (
                          <span className='error-text text-danger'>{errors.property}</span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className='select-input'>
                        <Label for='unit'>Unit name</Label>
                        {!isEditMode && (
                          <>
                            {' '}
                            <img src={infoIcon} id='infoTooltip1' />
                            <Tooltip
                              placement='top'
                              isOpen={tooltipOpen1}
                              autohide={false}
                              target='infoTooltip1'
                              toggle={toggle1}
                            >
                              You can edit this information later by editing the tenant details
                            </Tooltip>
                          </>
                        )}
                        {values.unit && (
                          <a
                            onClick={() => {
                              setFieldValue('unit', '')
                              setFieldValue('lease_start_date', null)
                              setFieldValue('lease_end_date', null)
                            }}
                          >
                            <img src={clearIcon} className='clear-icon cursor-pointer' />
                          </a>
                        )}
                        <Input
                          id='unit'
                          name='unit'
                          type='select'
                          bsSize='lg'
                          value={values.unit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option disabled value={''}>
                            {!displayPropertyUnits.length
                              ? 'Please assign Property First'
                              : 'Choose a Unit'}
                          </option>
                          {displayPropertyUnits.map((units, index) => (
                            <option key={index} value={units.id}>
                              {units.display_name}
                            </option>
                          ))}
                        </Input>
                        {touched.unit && errors.unit && (
                          <span className='error-text text-danger'>{errors.unit}</span>
                        )}
                      </FormGroup>
                    </Col>
                    {/* } */}
                  </Row>

                  <Row>
                    <Col md={6} className='mt-3'>
                      <FormGroup className='mb-4'>
                        <Label for='lease_start_date'>Lease Start Date</Label>
                        <Calendar
                          showIcon
                          value={values.lease_start_date}
                          onChange={(date) => setFieldValue('lease_start_date', date.value)}
                          placeholder='Select a date'
                          name='lease_start_date'
                          minDate={new Date()}
                          required={values.unit ? true : false}
                          dateFormat='yy-mm-dd'
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} className='mt-3'>
                      <FormGroup className='mb-4'>
                        <Label for='lease_end_date'>Lease End Date</Label>
                        <Calendar
                          showIcon
                          value={values.lease_end_date}
                          onChange={(date) => setFieldValue('lease_end_date', date.value)}
                          placeholder='Select a date'
                          name='lease_end_date'
                          required={values.unit ? true : false}
                          minDate={values.lease_start_date ? moment(values.lease_start_date).add(1, 'days').toDate() : moment().toDate()}
                          dateFormat='yy-mm-dd'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              ) : null}

              <div className='submit-wrapper'>
                <Button className='btn cta' disabled={!dirty || !isValid}>
                  {isEditMode ? 'Update Tenant' : 'Create Tenant'}
                </Button>
                <Button
                  className='btn simple'
                  icon="pi pi-loading"
                  loading={isLoading}
                  onClick={() => navigate(ROUTES.TENANTS)}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      {showOutOfUnitConnectsModal && (
        <CommonAlertModal
          isModalOpen={showOutOfUnitConnectsModal}
          handleClose={() => setShowOutOfUnitConnectsModal(false)}
          message='You’re out of unit connects'
          subHeading='Upgrade your plan to add more units.'
          bottonText='Upgrade Plan'
          modalIcon={cartFilledIcon}
          handleButtonAction={() => {
            navigate(ROUTES.SUBSCRIPTIONS)
            setShowOutOfUnitConnectsModal(false)
          }}
        />
      )}
    </>
  )
}

export default AddTenants
