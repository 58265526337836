import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import authReducer from './auth/authSlice';
import propertiesReducer from './properties/propertiesSlice';
import maintenanceReducer from './maintenance/maintenanceSlice';
import settingReducer from './setting/settingSlice';
import overViewReducer from './overview/overviewSlice';
import tenantReducer from './tenant/tenantSlice';
import todoReducer from './todo/todoSlice';

const reducer = combineReducers({
  authReducer,
  propertiesReducer,
  maintenanceReducer,
  settingReducer,
  overViewReducer,
  tenantReducer,
  todoReducer
})
const store = configureStore({
  reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;