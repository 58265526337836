export const ROUTES = {
  LOGIN: '/',
  SIGNUP: '/signup',
  PASSWORD_RECOVERY: '/password-recovery',
  SET_NEW_PASSWORD: '/reset-password',
  EMAIL_VERIFICATION: '/verification',
  EMAIL_UPDATE: '/email-update',
  LANDLORD_MAIN_PAGE: '/landlord',
  PROPERTIES: '/landlord/properties',
  NEW_PROPERTIES: '/landlord/properties/new-property',
  EDIT_PROPERTY: '/landlord/properties/edit-property',
  PROPERTY_DETAILS: '/landlord/properties/property-details',
  OVERVIEW: '/landlord/overview',
  UNITS: '/landlord/units',
  ADD_UNITS: '/landlord/units/add-units',
  EDIT_UNITS: '/landlord/units/edit-units',
  UNIT_DETAILS: '/landlord/units/unit-details',
  TENANTS: '/landlord/tenants',
  ADD_TENANTS: '/landlord/tenants/add-tenants',
  EDIT_TENANT: '/landlord/tenants/edit-tenants',
  TENANTS_DETAILS: '/landlord/tenants/tenant-details',
  PAYMENTS_OVERVIEW: '/landlord/payments',
  MAINTENANCE: '/landlord/maintenance',
  TODO: '/landlord/todo',
  EDIT_TODO: '/landlord/edit-todo',
  CREATE_TODO_TASK: '/landlord/todo/create-todo-task',
  TASK_DETAILS: '/landlord/todo/task-details',
  LANDLORD_SETTINGS: '/landlord/settings',
  MAINTENANCE_DETAILS: '/landlord/maintenance/details',
  SUBSCRIPTIONS_DETAIL: '/landlord/subscriptions',
  ADDCARD: '/landlord/settings/',
  SUBSCRIPTIONS: '/landlord/settings/subscriptions',
  BUY_NOTIFICATIONS: '/landlord/todo/buy-notifications',
  TENANT_MAIN_PAGE: '/tenant',
  TENANT_OVERVIEW: '/tenant/overview',
  TENANT_PAYMENTS: '/tenant/payments',
  TENANT_MAINTENANCE: '/tenant/maintenance',
  TENANT_SIGNUP: '/tenant-registration',
  TENANT_PAY_RENT: '/tenant/pay-rent',
  TENANT_SCHEDULE_PAYMENT: '/tenant/schedule-payment',
  TENANT_MAINTENANCE_CREATE: '/tenant/maintenance-create',
  TENANT_MAINTENANCE_DETAIL: '/tenant/maintenance-detail',
  TENANT_ADDBANK_DETAILS: '/tenant/add-bank-details',
  TENANT_SETTINGS: '/tenant/settings',
  TENANT_MAINTENANCE_EDIT: '/tenant/maintenance-edit',
  CHAT: '/Chat',
  CHATS: '/Chat/Chats',
}
