import { Chart } from "react-google-charts";

export interface PieChartProps {
  chartData: (string | number)[][]
  chartOptions: object
}

export const data = [
  ["Task", "Hours per Day"],
  ["Paid", 11],
  ["Due", 2],
];

export const options = {
  
  width: 100,
  height: 90,
  pieHole: 0.7
  ,
  is3D: false,
  legend: {
    position: 'bottom',
  },
  radius: "10px",
  pieSliceText: 'none',

  colors: [ "#02AD88", "#D02F44"]
};

export const GooglePieChart = (chartProps: PieChartProps) => {
  return (
    <Chart
      chartType="PieChart"
      data={chartProps.chartData}
      options={chartProps.chartOptions}
    />
  )
}
export default GooglePieChart;