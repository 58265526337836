import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export interface DoughnutChartProps {
  color1?: string
  color2?: string
  borderWidth?: number
  borderRadius?: number
  cutout?: string
  chartData?: number[],
  chartLabel?: string,
}

export function DoughnutChart(props: DoughnutChartProps) {
    const data = {
      labels: [],
      datasets: [
        {
          // label: props?.chartLabel || 'February payment',
          data: props?.chartData || [50, 220],
          backgroundColor: [
            props?.color1 || '#02AD88',
            props?.color2 || '#02AD8814',
          ],
          borderWidth: props?.borderWidth || 0,
          borderRadius: props?.borderRadius || 3,
        },
      ],
    };
        
  return <Doughnut className='doughnutLayout' data={ data } options={{
    cutout: props?.cutout || '50%'
  }} />;
}
export default Doughnut ;

