import { createSlice, Dispatch } from '@reduxjs/toolkit';
import {  CreditCardResponse, AccountDetail, Subscription, SchedulePaymentRequest } from '../../types/setting';


interface SettingStates {
    subscriptionList: Subscription | null,
    selectedSubscription:Subscription
    currentCard: CreditCardResponse
    currentActiveAccount: AccountDetail,
    connectedAccounts: AccountDetail[],
    schedulePayoutsDetails: SchedulePaymentRequest[]
    selectedSchedulePayout: SchedulePaymentRequest
}

const initialState: SettingStates = {
    subscriptionList : null,
    selectedSubscription: {
        id:0,
        total_connects:0,
        free_connects:0,
        paid_connects: 0,
        created: " "
    },
    currentCard: {
        card_id: '',
        cc_last4: '',
        created: '',
        exp_month: '',
        exp_year: '',
        id: 0,
        landlord: 0,
    },

    currentActiveAccount: {
        id: '',
        account_id: '',
        created: '',
        email:'',
        landlord: 0
    },
    connectedAccounts: [],
    schedulePayoutsDetails: [],
    selectedSchedulePayout: {
        amount: 0,
        day: '',
        status: false,
        created: '',
        payment_method: '',
        id: ''
    }
}

const settingSlice = createSlice({

    name: 'settting',
    initialState,
    reducers: {
        setSubscriptionList: (state, action) => {
            state = { ...state, subscriptionList: action.payload.subscriptionList }
            return state;
        },
        setSelectedSubscription: (state, action) => {
            state = { ...state, selectedSubscription: action.payload }
            return state;
        },
        setCurrentCard: (state, action) => {
            state = { ...state, currentCard: action.payload }
            return state;
        },

        setCurrentActiveAccount: (state, action) => {
            state = { ...state, currentActiveAccount: action.payload }
            return state;
        },

        setConnectedAccounts: (state, action) => {
            state = { ...state, connectedAccounts: action.payload }
            return state;
        },

        setSchedulePayoutDetails: (state, action) => {
            state = { ...state, schedulePayoutsDetails: action.payload }
            return state;
        },

        setSelectedSchedulePayout: (state, action) => {
            state = { ...state, selectedSchedulePayout: action.payload }
            return state;
        },

    },
});
const { setSubscriptionList, setSelectedSubscription, setCurrentCard, setCurrentActiveAccount, setConnectedAccounts, setSchedulePayoutDetails, setSelectedSchedulePayout  } = settingSlice.actions;

export const saveSubscriptionList = (subscriptionList: Subscription) => async (dispatch: Dispatch) => {
    return dispatch(setSubscriptionList({subscriptionList}));
}

export const SelectedSubscription = (selectedSubscription: Subscription) => async (dispatch: Dispatch) => {
    return dispatch(setSelectedSubscription(selectedSubscription));
}

export const setCurrentCardDetail = (currentCard: CreditCardResponse) => async (dispatch: Dispatch) => {
    return dispatch(setCurrentCard(currentCard));
}

export const setCurrentAccountDetail = (currentAccount: AccountDetail) => async (dispatch: Dispatch) => {
    return dispatch(setCurrentActiveAccount(currentAccount));
}

export const setConnectedAccountsDetails = (accounts: AccountDetail[]) => async (dispatch: Dispatch) => {
    return dispatch(setConnectedAccounts(accounts));
}

export const setSchedulePayoutData = (schedulePayouts: SchedulePaymentRequest[]) => async (dispatch: Dispatch) => {
    return dispatch(setSchedulePayoutDetails(schedulePayouts));
}

export const saveSelectedSchedulePayout = (schedulePayoutData: SchedulePaymentRequest) => async (dispatch: Dispatch) => {
    return dispatch(setSelectedSchedulePayout(schedulePayoutData));
}

export default settingSlice.reducer;