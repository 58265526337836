import axios from "axios";

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const processApisError = (err: unknown) => {
  if (axios.isAxiosError(err)) {
    if(err.response?.data?.detail) {
        return err.response?.data;
    } else {
        return "Error in get properties list";
    }
  }
}

export const findDayNth = function(day: number) {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}

export const getMonthName = (monthNumber: number) => {
  const months = ['Jan', "Feb", "March", 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return months[monthNumber - 1];
}

export const getCurrentMonthName = () => {
  const date = new Date();
  return date.toLocaleString('default', { month: 'long' });
}

export const getCurrentDayOfWeek = () => {
  const weekday = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
  return weekday[(new Date).getDay()];
}

export const getWeekOfMonth = (date: Date, sendDisplayName: boolean) => {
  const adjustedDate = date.getDate()+date.getDay();
  const prefixes = ['0', '1', '2', '3', '4', '5'];
  const WeekDisplayName = ["first", "second", "third", "fourth", "last"]
  return sendDisplayName ? WeekDisplayName[(parseInt(prefixes[0 | adjustedDate / 7])+1)] : (parseInt(prefixes[0 | adjustedDate / 7])+1);
}