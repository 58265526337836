import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { TabMenu } from 'primereact/tabmenu'

import { Card, CardBody, CardHeader } from 'reactstrap'
import PersonalProfile from './components/ProfilePage/ProfilePage'
import SubscriptionsDetails from './components/SubscriptionDetail/SubscriptionDetail'
import { useAppDispatch } from '../../hooks/storeHook'
import { setActivePageHeader } from '../../store/auth/authSlice'
import profileIcon from '../../assets/images/svgImages/profileUselected.svg'
import profileSelectedIcon from '../../assets/images/svgImages/profile_selected_icon.svg'
import cardSelectedIcon from '../../assets/images/svgImages/payout_selected_icon.svg'
import cardIcon from '../../assets/images/svgImages/creditCard_icon.svg'
import PaymentMethodLandingPage from './components/PaymentMethods/Payment-Method-Landing-Page'
import { ROUTES } from '../../constants/routesConstant'
import { SettingsTabLabels } from '../../constants/appConstants'

const Settings = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation().search
  const queryParams = new URLSearchParams(location)
  const locationType = queryParams.get('locationType')

  const [selectedTab, setSelectedTab] = useState(
    locationType ? locationType : SettingsTabLabels.PERSONAL,
  )
  const [activeIndex, setActiveIndex] = useState(0)
  const [userType, setUserType] = useState('landlord')

  const landlordTabitems = [
    {
      label: SettingsTabLabels.PERSONAL,
      icon: (
        <img
          src={selectedTab === SettingsTabLabels.PERSONAL ? profileSelectedIcon : profileIcon}
          alt='Home'
        />
      ),
    },
    {
      label: SettingsTabLabels.SUBSCRIPTIONS,
      icon: (
        <img
          src={selectedTab === SettingsTabLabels.SUBSCRIPTIONS ? cardSelectedIcon : cardIcon}
          alt='card'
        />
      ),
    },
    {
      label: SettingsTabLabels.PAYMENT_METHODS,
      icon: (
        <img
          src={selectedTab === SettingsTabLabels.PAYMENT_METHODS ? cardSelectedIcon : cardIcon}
          alt='card'
        />
      ),
    },
  ]

  const tenantItems = [
    {
      label: SettingsTabLabels.PERSONAL,
      icon: (
        <img
          src={selectedTab === SettingsTabLabels.PERSONAL ? profileSelectedIcon : profileIcon}
          alt='Home'
        />
      ),
    },
    /* {
      label: SettingsTabLabels.PAYMENT_METHODS,
      icon: (
        <img
          src={selectedTab === SettingsTabLabels.PAYMENT_METHODS ? cardSelectedIcon : cardIcon}
          alt='card'
        />
      ),
    }, */
  ]

  useEffect(() => {
    getUser()
    dispatch(setActivePageHeader('Settings'))
  }, [])

  useEffect(() => {
    if (locationType) {
      setSelectedTab(locationType ? locationType : SettingsTabLabels.PERSONAL)
      if (userType === 'tenant') {
        setActiveIndex(
          tenantItems.findIndex((data) => data.label.toLowerCase() === locationType.toLowerCase()),
        )
      } else {
        setActiveIndex(
          landlordTabitems.findIndex(
            (data) => data.label.toLowerCase() === locationType.toLowerCase(),
          ),
        )
      }
    }
  }, [locationType])

  const getUser = () => {
    const currentUser = JSON.parse(localStorage.getItem('pmanagement.user') as string)
    const currentUserType = currentUser?.portal
    if (currentUserType === 'tenant') {
      setUserType(currentUserType)
    }
  }

  return (
    <div className='py-2 tab settings'>
      <Card className='form-card h-100'>
        <CardHeader>General Settings</CardHeader>
        <CardBody>
          <TabMenu
            onTabChange={(event) => {
              setSelectedTab(event.value.label as string)
              setActiveIndex(event.index)
              navigate(userType === 'tenant' ? ROUTES.TENANT_SETTINGS : ROUTES.LANDLORD_SETTINGS)
            }}
            className={userType === 'tenant' ? 'tabMenuTenant' : 'tabMenu'}
            model={userType === 'tenant' ? tenantItems : landlordTabitems}
            activeIndex={activeIndex}
          />
          {selectedTab === SettingsTabLabels.PERSONAL && <PersonalProfile />}
          {selectedTab === SettingsTabLabels.SUBSCRIPTIONS && <SubscriptionsDetails />}
          {selectedTab === SettingsTabLabels.PAYMENT_METHODS && <PaymentMethodLandingPage />}
        </CardBody>
      </Card>
    </div>
  )
}

export default Settings
