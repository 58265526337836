import { ChangeEvent, useEffect, useState, useRef } from 'react'
import axios from 'axios'
import * as Yup from 'yup'

import { Button } from "primereact/button";
import { Col, Row, FormGroup, Label, Input } from 'reactstrap'
import { Formik, Form } from 'formik'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { setActivePageHeader, setCurrentUserInfo } from '../../../../store/auth/authSlice'
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHook'
import { settingService } from '../../../../helpers/settingService'
import { ErrorToast, SuccessToast } from '../../../../helpers/toster'
import { PersonalProfile } from '../../../../types/setting'
import CommonAlertModal from '../../../../components/common/CommonAlertModal/CommonAlertModal'

import userImage from '../../../../assets/images/svgImages/userImage.svg'
import eyeIcon from '../../../../assets/images/svgImages/eye_icon.svg'
import closeEyeIcon from '../../../../assets/images/svgImages/close_eye_icon.svg'

let initialValue: PersonalProfile = {
  first_name: '',
  last_name: '',
  note: '',
  phone: '',
  old_password: '',
  new_password: '',
  avatar: {} as File,
  role: '',
  portal: '',
  confirmNewPassword: '',
  email: '',
}

const profileUpdateValidationSchema = () =>
  Yup.object().shape({
    first_name: Yup.string(),
    phone: Yup.string().required(),
  })

const passwordConfirmation = (values: PersonalProfile) => {
  if (values.new_password !== values.confirmNewPassword) {
    return false
  }
  return true
}

const PersonalProfilePage = () => {
  const toastRef = useRef<any>(null)
  const dispatch = useAppDispatch()
  const { userInfo } = useAppSelector((state) => state.authReducer)
  const [isShowOldPassword, setIsShowOldPassword] = useState(false)
  const [isShowNewPassword, setIsShowNewPassword] = useState(false)
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [selectedFile, setSelectedFile] = useState<File[]>([])

  useEffect(() => {
    getUserDetail()
    dispatch(setActivePageHeader('Settings'))
  }, [])

  const getUserDetail = async () => {
    const response = await settingService.getUserDetails()
    initialValue = {
      ...initialValue,
      ...response,
      first_name: response.first_name + ' ' + response.last_name,
    }
    setIsLoaded(true)
    dispatch(setCurrentUserInfo(response))
  }

  const handleSubmit = async (values: PersonalProfile) => {
    setIsLoading(true)
    let response = null
    if (values.new_password) {
      const passwordValid = await passwordConfirmation(values)
      if (passwordValid) {
        response = await saveUserDetails(values)
        setIsLoading(false)
      } else {
        ErrorToast(`Passwords doesn't match`)
        setIsLoading(false)
        return
      }
    } else {
      response = await saveUserDetails(values)
    }
    if (axios.isAxiosError(response)) {
      setIsLoading(false)
      const error = response.response?.data
      for (const key in error) {
        ErrorToast(error[key].toString())
      }
    } else {
      setIsLoading(false)
      SuccessToast('Profile Updated Successfully')
    }
  }

  const saveUserDetails = async (values: PersonalProfile) => {
    const formData = new FormData()
    const firstName = values.first_name as string
    const newname = firstName.split(' ')
    if (selectedFile.length > 0) {
      formData.append('avatar', selectedFile[0])
    }
    if (values.new_password) {
      formData.append('old_password', values.old_password)
      formData.append('new_password', values.new_password)
    }
    formData.append('first_name', newname[0])
    formData.append('last_name', newname[1] ? newname[1] : '')
    formData.append('note', values.note)
    formData.append('phone', values.phone)

    if (values.email !== userInfo?.email) {
      formData.append('email', values.email as string)
      setIsModalOpen(true)
    }


    return settingService.updateUserPersonalDetail(formData)
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    const filesData = target.files ? Array.from(target.files) : []
    if (filesData.length) {
      setSelectedFile(filesData)
    }
  }

  return (
    <>
      {isLoaded ? (
        <div className='p-4 profile'>
          <Row className='pb-2'>
            <Col md={6}>
              <div className='imageCard'>
                <div>
                  <div className='d-flex align-items-center'>
                    <div className='image position-relative'>
                      {selectedFile.length <= 0 ? (
                        !userInfo?.avatar ? (
                          <img src={userImage} />
                        ) : (
                          <img
                            className='w-100 h-100 rounded-circle'
                            src={userInfo.avatar as string}
                          />
                        )
                      ) : (
                        <img
                          className='w-100 h-100 rounded-circle'
                          src={URL.createObjectURL(selectedFile[0])}
                        />
                      )}
                    </div>
                    <div className='heading'>
                      <div className='NameDetail'>
                        {userInfo?.first_name + ' ' + userInfo?.last_name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className='d-flex justify-content-end position-relative imageUpload'>
                <Input
                  className='position-absolute w-100 h-100'
                  accept='image/png, image/jpeg'
                  onChange={handleFileChange}
                  type='file'
                ></Input>
                <Button className='btn outline-btn mt-1'>
                  {selectedFile.length <= 0 ? 'Upload new picture' : selectedFile[0].name}
                </Button>
              </div>
            </Col>
          </Row>
          <hr className='mb-5' />

          <Formik
            initialValues={initialValue}
            onSubmit={(value: PersonalProfile) => {
              handleSubmit(value)
            }}
            validationSchema={() => profileUpdateValidationSchema()}
          >
            {({ errors, values, touched, handleChange, handleBlur, setFieldValue, dirty }) => (
              <Form className='profile-form'>
                <Row>
                  <Col md={6}>
                    <div className='formHeadingtitle'>Basic Information</div>
                    <Row>
                      <Col md={10}>
                        <Row>
                          <Col md={6}>
                            <FormGroup className='mb-4'>
                              <Label className='formLabel'>Full Name</Label>
                              <Input
                                id='name'
                                name='first_name'
                                placeholder='Enter Name'
                                value={values.first_name}
                                onChange={handleChange}
                              />
                              {touched.first_name && errors.first_name && (
                                <span className='error-text text-danger'>{errors.first_name}</span>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className='mb-4'>
                              <Label className='formLabel'>Email Address</Label>
                              <Input
                                id='email'
                                name='email'
                                placeholder='Enter email'
                                value={values.email}
                                onChange={handleChange}
                              />
                              {touched.email && errors.email && (
                                <span className='error-text text-danger'>{errors.email}</span>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <FormGroup className='mb-4'>
                              <Label className='formLabel'>Notes</Label>
                              <Input
                                id='note'
                                name='note'
                                placeholder='Say Something about you...'
                                type='textarea'
                                className='formTextarea'
                                value={values.note}
                                onChange={handleChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <div className='formHeadingtitle'>More Information</div>
                        <Row>
                          <Col md={6}>
                            <FormGroup className='mb-4'>
                              <Label className='formLabel'>Phone Number</Label>

                              <PhoneInput
                                className='form-control'
                                value={values.phone}
                                defaultCountry='US'
                                smartCaret={true}
                                onChange={(e) => { setFieldValue('phone', e) }}
                                required
                                onBlur={handleBlur}
                                name='phone'
                                maxLength={14}
                              />
                              {values.phone &&
                                !errors.phone &&
                                isValidPhoneNumber(values.phone) === false && (
                                  <span className='error-text text-danger'>
                                    Invalid Phone Number
                                  </span>
                                )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>

                      <Col md={2}></Col>
                    </Row>
                  </Col>

                  <Col md={6}>
                    <div className='formHeadingtitle'>Security Information</div>
                    <Row>
                      <Col md={7}>
                        <FormGroup>
                          <Label className='formLabel'>Old Password</Label>
                          <div className='position-relative'>
                            <Input
                              name='old_password'
                              type={isShowOldPassword ? 'text' : 'password'}
                              onChange={handleChange}
                              value={values.old_password}
                              placeholder={'Enter Old Password'}
                              onBlur={handleBlur}
                            />
                            <img
                              alt='eye-icon'
                              className='img-icon'
                              src={isShowOldPassword ? eyeIcon : closeEyeIcon}
                              onClick={() => setIsShowOldPassword((prevProp) => !prevProp)}
                            />
                          </div>
                          {touched.old_password && errors.old_password && (
                            <span className='error-text text-danger'>{errors.old_password}</span>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={7}>
                        <FormGroup className='position-relative'>
                          <Label className='formLabel'>New Password</Label>
                          <Input
                            name='new_password'
                            type={isShowNewPassword ? 'text' : 'password'}
                            onChange={handleChange}
                            value={values.new_password}
                            placeholder={'Enter New Password'}
                            onBlur={handleBlur}
                          />
                          <img
                            alt='eye-icon'
                            className='img-icon'
                            src={isShowNewPassword ? eyeIcon : closeEyeIcon}
                            onClick={() => setIsShowNewPassword((prevProp) => !prevProp)}
                          />
                          {touched.new_password && errors.new_password && (
                            <span className='error-text text-danger'>{errors.new_password}</span>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={7}>
                        <FormGroup className='position-relative'>
                          <Label className='formLabel'>Confirm Password</Label>
                          <Input
                            name='confirmNewPassword'
                            type={isShowConfirmPassword ? 'text' : 'password'}
                            onChange={handleChange}
                            value={values.confirmNewPassword}
                            placeholder={'Enter Confirm Password'}
                            onBlur={handleBlur}
                          />
                          <img
                            alt='eye-icon'
                            className='img-icon'
                            src={isShowConfirmPassword ? eyeIcon : closeEyeIcon}
                            onClick={() => setIsShowConfirmPassword((prevProp) => !prevProp)}
                          />
                          {touched.new_password && errors.confirmNewPassword && (
                            <span className='error-text text-danger'>
                              {errors.confirmNewPassword}
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}></Col>
                  <Col md={6}>
                    <div className='d-flex justify-content-end'>
                      <Button
                        type='submit'
                        className='btn cta'
                        icon="pi pi-loading"
                        loading={isLoading}
                        disabled={!(dirty || selectedFile.length > 0)}
                      >
                        Save Changes
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        ''
      )}
      <CommonAlertModal
        isModalOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        message="Verify your email"
        subHeading="You changed your email address, please verify your new email to update it."
        bottonText="Got it"
        handleButtonAction={() => {
          setIsModalOpen(false)
        }} />
    </>
  )
}

export default PersonalProfilePage
