import { Button } from "primereact/button";
import { Modal, ModalBody } from 'reactstrap';
import closeIcon from "../../../assets/images/svgImages/close_icon.svg";
import individualBankImg from '../../../assets/images/individual_bank.png';
import commercialBankImg from '../../../assets/images/commercial_bank.png';
interface SelectBankTypeModalProps {
    isModalOpen: boolean;
    handleClose: () => void;
    handleBankTypeSelection: (bankType: string) => void;
}

const SelectBankTypeModal = (props: SelectBankTypeModalProps) => {
    const { isModalOpen, handleClose, handleBankTypeSelection } = props;

    return (
        <>
            <Modal size="lg" centered className="common-alert-modal" isOpen={isModalOpen} toggle={handleClose}>
                <div className="modal-header-container">
                    <div className="close-icon text-end cursor-pointer" onClick={handleClose}>
                        <img src={closeIcon} />
                    </div>
                </div>
                <ModalBody className="common-body-container text-center p-5 d-flex justify-content-center align-items-center">
                    <div className="modal-body-content h-100 w-100">

                        <div className='choose-bank-view py-5 px-5 mx-3'>
                            <div className="choose-bank-content mb-4">Please Choose Your Bank Type</div>
                            <div className='choose-bank-block d-flex align-items-center justify-content-between mb-4'>
                                <div className="individual-bank">
                                    <img className='mb-4' height={120} src={individualBankImg} alt="individual-bank-img" />
                                    <Button className='btn submit-btn alert-modal-btn' onClick={() => handleBankTypeSelection('Individual')}>Individual Bank</Button>
                                </div>

                                <div className='commercial-bank'>
                                    <img className='mb-4' height={120} src={commercialBankImg} alt="commercial-bank-img" />
                                    <Button className="submit-btn alert-modal-btn" onClick={() => handleBankTypeSelection('Company')}>Commercial Bank</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default SelectBankTypeModal