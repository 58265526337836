import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import { Row, Col } from 'reactstrap'
import { Button } from "primereact/button";
import { ROUTES } from '../../constants/routesConstant'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHook'
import { setActivePageHeader } from '../../store/auth/authSlice'
import { saveTodotasksListData } from '../../store/todo/todoSlice'
import { todoService } from '../../helpers/todoService'
import { notificationService } from '../../helpers/notificationsService'
import ToDoTasksList from './components/ToDoTasksList'
import { CountCard, CardData } from '../../components/common/CountCard/CountCard'
import NoListingView from '../../components/common/NoListingView/NoListingView'

import noTodoImage from '../../assets/images/svgImages/no-list-todo.svg'
import searchIcon from '../../assets/images/svgImages/search_icon.svg'
import clearIcon from '../../assets/images/svgImages/clear_input_icon.svg'
import summaryNotificationIcon from '../../assets/images/svgImages/summary-notification-icon.svg'


const ToDoTasks = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { todotaskList } = useAppSelector((state) => state.todoReducer)
  const [filters, setFilters] = useState('')
  const [cardData, setCardData] = useState<CardData[]>([])

  useEffect(() => {
    dispatch(setActivePageHeader('To-Do'))
    getTotalNotifications()
  }, [])

  useEffect(() => {
    if (filters) {
      const filteredData = todotaskList.filter((data) => data.name.toLowerCase().includes(filters.toLowerCase()))
      dispatch(saveTodotasksListData(filteredData, filteredData.length))
    } else {
      getToDoTasks()
    }
  }
    , [filters])

  const getToDoTasks = async () => {
    const response = await todoService.getTodoTasksList()
    if (response.results && response.results.length > 0) {
      dispatch(saveTodotasksListData(response.results, response.count))
    } else {
      dispatch(saveTodotasksListData([], 0))
    }
  }
  const getTotalNotifications = async () => {
    let EmailCredits = 0
    let SMSCredits = 0
    let notificationSent = 0
    const emails = await notificationService.getEmailCredits()
    if (emails) {
      EmailCredits = emails.email_credits
    }
    const sms = await notificationService.getSMSCredits()
    if (sms) {
      SMSCredits = sms.sms_credits
    }
    const notificationsSent = await todoService.notificationsSent()
    if (notificationsSent) {
      notificationSent = notificationsSent.notifications_sent_current_month
    }

    setCardData([
      {
        title: `Notifications sent in ${moment().format('MMMM')}`,
        mobile_title: 'Total sent',
        count: notificationSent,
      },
      {
        title: 'Email notifications',
        mobile_title: 'Email',
        count: EmailCredits,
      },
      {
        title: 'SMS notifications',
        mobile_title: 'SMS',
        count: SMSCredits,
      },
    ])
  }


  return (
    <>
      <Row className='mb-4'>
        <Col>
          <h2 className='title'>To-Do</h2>
        </Col>
        <Col className='d-flex align-items-center justify-content-end gap-2'>
          <Button
            className={'btn simple'}
            onClick={() => navigate(ROUTES.BUY_NOTIFICATIONS)}
          >
            Buy notifications
          </Button>

          <Button
            className='btn cta'
            onClick={() => navigate(ROUTES.CREATE_TODO_TASK)}
          >
            Create Task
          </Button>
        </Col>
      </Row>
      <Row className='m-0 mb-4 w-100 d-flex gap-3 row'>
        <CountCard
          heading='Notifications'
          subHeading={moment().format('MMMM')}
          data={cardData}
          icon={summaryNotificationIcon}
        />
      </Row>
      <Row className='pb-4'>
        <Col md={4}>
          <div className='searchbar'>
            <div className='searchbar-container searchbar-container--bg me-2'>
              <input className='form-control' placeholder='Search by task name' value={filters} onChange={(e) => {
                setFilters(e.target.value)
              }} />
              <div className='search-icon cursor-pointer'>
                <img src={searchIcon} alt='search-icon' />
              </div>
              {filters && (
                <a
                  onClick={() => {
                    setFilters('')
                  }}
                >
                  <img src={clearIcon} className='search-clear cursor-pointer' />
                </a>
              )}
            </div>
          </div>
        </Col>
      </Row>
      {todotaskList.length ? (
        <ToDoTasksList getTodoTasksData={getToDoTasks} />
      ) : (
        <NoListingView
          noPropText='No tasks'
          addPropText="You haven't added any tasks yet."
          image={noTodoImage}
          buttonText='Create task'
          onClick={() => navigate(ROUTES.CREATE_TODO_TASK)}
        />
      )}
    </>
  )
}

export default ToDoTasks
