import { API_URLS } from '../constants/appConstants'
import {
  PayoutRequest,
  AddCardRequest,
  SchedulePaymentRequest,
  UpdateEmailData,
} from '../types/setting'
import API from './api'

const updateUserPersonalDetail = async (userData: FormData) => {
  try {
    const response = await API.post(API_URLS.UPDATE_USER, userData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err: any) {
    return err
  }
}

const getSubscriptionList = async () => {
  try {
    const response = await API.get(API_URLS.GET_SUBSCRIPTION_LIST)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getUser = () => {
  const currentUser = localStorage.getItem('pmanagement.user')
  return JSON.parse(currentUser as string)
}

const getCreditCardDetails = async () => {
  try {
    const response = await API.get(API_URLS.GET_CREDIT_CARD)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const addCreditCard = async (payload: AddCardRequest) => {
  try {
    const response = await API.post(API_URLS.ADD_CREDITCARD, payload)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const cancelSubscription = async (id: string) => {
  try {
    const response = await API.get(API_URLS.CANCEL_SUBSCRIPTION.replace('{id}', id))
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const addConnectedAccount = async (bankType: string) => {
  try {
    const response = await API.get(
      `${API_URLS.ADD_CONNECTED_BANK_ACCOUNTS}?business_type=${bankType}`,
    )
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getConnectedBankAccount = async () => {
  try {
    const response = await API.get(API_URLS.GET_CONNECTED_BANK_ACCOUNT)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getConnectedBankAccounts = async () => {
  try {
    const response = await API.get(API_URLS.GET_CONNECTED_BANK_ACCOUNTS)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getUserDetails = async () => {
  try {
    const response = await API.get(API_URLS.GET_USER)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const confirmPaymentScheduling = async (payload: SchedulePaymentRequest) => {
  const payloadData = {
    amount: payload.amount,
    day: payload.day,
    status: payload.status,
    created: payload.created,
    payment_method: Number(payload.payment_method),
    id: payload.id,
  }
  try {
    const response = await API.post(API_URLS.SCHEDULE_PAYMENT, payloadData)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const updatePaymentScheduling = async (payload: SchedulePaymentRequest) => {
  try {
    const response = await API.put(API_URLS.UPDATE_SCHEDULE_PAYMENT, payload)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const initiatePayout = async (values: PayoutRequest) => {
  const payload = {
    amount: values?.amount,
    payment_method: Number(values.paymentMethod),
  }
  try {
    const response = await API.post(API_URLS.INITIATE_PAYOUT, payload)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const updateBankAccount = async (id: string, accountId: string) => {
  try {
    const response = await API.get(
      `${API_URLS.UPDATE_ACCOUNT.replace('{id}', id)}?account_id=${accountId}`,
    )
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getCumulativeRent = async (accountId: string) => {
  try {
    const response = await API.get(`${API_URLS.GET_CUMULATIVE_RENT}?account=${accountId}`)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getSchedulePayout = async () => {
  try {
    const response = await API.get(API_URLS.GET_SCHEDULE_PAYOUT)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getNotificationsData = async () => {
  try {
    const response = await API.get(API_URLS.GET_NOTIFICATIONS)
    if (response.status === 201 || response.status === 200) {
      return response.data?.results
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const markAsReadNotification = async (id: string) => {
  try {
    const response = await API.get(API_URLS.MARK_AS_READ_NOTIFICATION.replace('{id}', id))
    if (response.status === 201 || response.status === 200) {
      return response.data?.results
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const deleteSchedulePayout = async (id: string) => {
  return new Promise((resolve, reject) => {
    API.delete(API_URLS.DELETE_SCHEDULE_PAYOUT.replace('{id}', id))
      .then((response) => {
        if (response.status === 201 || response.status === 200 || response.status === 204) {
          return resolve(response.data)
        } else {
          throw `request failed with status ${response.status}`
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

const editSchedulePayout = async (data: SchedulePaymentRequest, id: string) => {
  try {
    const response = await API.put(API_URLS.EDIT_SCHEDULE_PAYOUT.replace('{id}', id), data)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const UpdateEmail = async (data: UpdateEmailData) => {
  try {
    const response = await API.post(API_URLS.UPDATE_EMAIL, data)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

export const settingService = {
  updateUserPersonalDetail,
  getUser,
  getSubscriptionList,
  getCreditCardDetails,
  addCreditCard,
  cancelSubscription,
  addConnectedAccount,
  getConnectedBankAccount,
  getConnectedBankAccounts,
  getUserDetails,
  confirmPaymentScheduling,
  initiatePayout,
  updateBankAccount,
  getCumulativeRent,
  getSchedulePayout,
  updatePaymentScheduling,
  getNotificationsData,
  markAsReadNotification,
  deleteSchedulePayout,
  editSchedulePayout,
  UpdateEmail,
}
