export interface CardProps {
  heading: string
  subHeading?: string
  data: CardData[]
  icon?: string
}

export interface CardData {
  title: string
  mobile_title?: string
  count: number
}

export const CountCard = (props: CardProps) => {

  const dataPositionClassname = (lenght: number, index: number) => {
    const isEvenLenght = lenght % 2 === 0
    const isEven = (index + 1) % 2 === 0
    const isLastRow = index >= lenght - 2
    if (isEvenLenght) {
      if (isEven) {
        return 'col-6 justify-content-end'
      }
      return 'col-6'
    }
    if (isEven && !isLastRow) {
      return 'col-6 justify-content-end'
    }
    if (lenght === 3) {
      if (index === 0) {
        return 'col-4 justify-content-start'
      }
      if (index === 1) {
        return 'col-4 justify-content-center'
      }
      if (index === 2) {
        return 'col-4 justify-content-end'
      }

    }
    if (isLastRow) {
      return 'last-row'
    }
    return 'col-6'
  }

  return (
    <>
      {/* Desktop version */}
      <div className="count-card-wrapper">
        <div className='count-card-container row'>
          {props.data.map((item, index: number) => {
            return (
              <div className='count-card-item' key={index}>
                <img className='count-card-icon' src={props.icon} alt="summary icon" />
                <div className="count-card-content">
                  <span className='count-card-title caption'>{item.title} </span>
                  <span className='count-card-amount'>{item.count}</span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {/* mobile version */}
      <div className="mobile-count-card-wrapper">
        <div className="heading">
          <h2 className='count-card-heading'>{props.heading}</h2>
          <img className='count-card-icon' src={props.icon} alt="summary icon" />
        </div>
        <span className='caption count-card-subheading'>{props.subHeading}</span>
        <div className='count-card-container row'>
          {props.data.map((item, index: number) => {
            const itemLength = props.data.length
            return (
              <div className={`${dataPositionClassname(itemLength, index)} count-card-item`} key={index}>
                <div className="count-card-content">
                  <span className='count-card-title caption'>{item.mobile_title ? item.mobile_title : item.title}</span>
                  <span className='count-card-amount'>{item.count}</span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}