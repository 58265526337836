import { useSearchParams, useNavigate } from 'react-router-dom'
import { Button } from "primereact/button";

import { settingService } from '../../helpers/settingService'
import { ErrorToast, SuccessToast } from '../../helpers/toster'
import { ROUTES } from '../../constants/routesConstant'
import { clearStorage } from '../../helpers/userService'
import { useAppDispatch } from '../../hooks/storeHook'
import { logout } from '../../store/auth/authSlice'
import PrivacyTerms from '../../components/common/PrivacyTerms/component'


import verifyEmailImg from "../../assets/images/verify_email.png";

const EmailUpdate = () => {
  const [params] = useSearchParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const oldEmail = params.get('oldEmail') || ""
  const newEmail = params.get('newEmail') || ""
  const oeToken = params.get('oetoken') || ""
  const vToken = params.get('vtoken') || ""
  const eToken = params.get('etoken') || ""

  const handleUpdateEmail = async () => {
    const response = await settingService.UpdateEmail({
      etoken: eToken,
      oetoken: oeToken,
      vtoken: vToken
    })

    if (response.success === true) {
      SuccessToast(response.msg)
      dispatch(logout())
      clearStorage()
      navigate(ROUTES.LOGIN)
    } else {
      ErrorToast(response.msg)
    }
  }

  return (
    <div className="email-update-container container-fluid">

      <div className="verify-email-img mb-4"><img alt="verify-email" src={verifyEmailImg} /></div>
      <h1>Email update</h1>
      <p>You`re updating your email from <strong>{oldEmail}</strong> to <strong>{newEmail}</strong></p>
      <p>Click <strong>Update email</strong> button to proceed.</p>
      <Button className='btn cta' onClick={() => { handleUpdateEmail() }}>Update email</Button>
      <PrivacyTerms />
    </div>
  )
}

export default EmailUpdate