import { useState } from 'react';
import axios, { isAxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import moment from 'moment';

import { Button } from "primereact/button";
import { Col, Row, FormGroup, Input, Label } from 'reactstrap';
import { API_URLS } from '../../constants/appConstants';
import { ROUTES } from '../../constants/routesConstant';
import API from '../../helpers/api';
import { ErrorToast, SuccessToast } from '../../helpers/toster';
import { Formik, Form } from 'formik';
import { setNewPassFormStates } from '../../types/auth';
import CompanyInfo from '../../components/CompanyInfo/CompanyInfo';

import eyeIcon from '../../assets/images/svgImages/eye_icon.svg'
import closeEyeIcon from '../../assets/images/svgImages/close_eye_icon.svg'


const initialValues = {
    pass: '',
    confirmPass: ''
}

const newPassSchema = () => Yup.object().shape({
    pass: Yup
        .string()
        .min(8, 'Password must be at least 8 charaters')
        .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, "Password must contain at least one letter, one number and one special character")
        .required('Password is required*'),
    confirmPass: Yup
        .string()
        .oneOf([Yup.ref('pass')], 'New Password and Confirm Password must match')
        .required('Confirm Password is required*'),
});

const SetNewPassword = () => {
    const navigate = useNavigate();
    const location = useLocation().search;
    const queryParams = new URLSearchParams(location);
    const token = queryParams.get('token');
    const uidb64 = queryParams.get('uidb64');
    const year = moment().format('YYYY');

    const [isShowPassword, setIsShowPassword] = useState(false)
    const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleResetPassword = async (values: setNewPassFormStates) => {
        const { pass, confirmPass } = values
        if (pass.trim().toLowerCase() !== confirmPass.trim().toLowerCase()) {
            ErrorToast("New Password and Confirm Password didn't match");
            return false;
        }
        const resetPasswordData = {
            token,
            uidb64,
            password: pass,
            confirm_password: confirmPass
        }

        try {
            setIsLoading(true)
            const response = await API.patch(API_URLS.SET_NEW_PASSWORD, resetPasswordData);
            if (response.status === 201 || response.status === 200) {
                navigate(ROUTES.LOGIN);
                SuccessToast(response.data.detail);
            } else {
                ErrorToast(response.data.detail);
            }
            setIsLoading(false)

        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.data?.detail) {
                    ErrorToast(error.response?.data?.detail);
                    return false;
                }
            }
            ErrorToast("Token expired");
            setIsLoading(false)
        }
    }

    return (
        <div className='password-recovery-container'>
            <Row className='d-flex justify-content-center align-items-center h-100 w-100 p-0'>
                <Col className='col-lg-6 col-12 h-100 p-0'>
                    <div className="password-recovery-wrapper">
                        <div className="w-100">
                        </div>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={(values: setNewPassFormStates) => handleResetPassword(values)}
                            validationSchema={() => newPassSchema()}>
                            {({
                                errors,
                                values,
                                touched,
                                handleBlur,
                                handleChange,
                                dirty,
                                isValid
                            }) => (
                                <Form className='password-recovery-form'>
                                    <h1>Forgotten password</h1>
                                    <br />
                                    <h3>Create new password</h3>
                                    <p className="balanced">Please create a new password. Try to use one that you don’t use anywhere else.</p>
                                    <FormGroup className="mb-4 mt-4">
                                        <div className="boxlabel-input-container">
                                            <Label for="pass">New password</Label>
                                            <div className="position-relative">
                                                <Input type={isShowPassword ? "text" : "password"} name="pass" value={values.pass} placeholder={'********'} onChange={handleChange} onBlur={handleBlur} required />
                                                <img
                                                    src={isShowPassword ? eyeIcon : closeEyeIcon}
                                                    alt='eye-icon'
                                                    className='img-icon'
                                                    onClick={() => setIsShowPassword((prevProp) => !prevProp)}
                                                />
                                            </div>

                                        </div>
                                        {touched.pass && errors.pass && (
                                            <span className='error-text text-danger'>{errors.pass}</span>
                                        )}
                                    </FormGroup>
                                    <FormGroup className="mb-4">
                                        <div className="boxlabel-input-container">
                                            <Label for="confirmPass">Confirm new password</Label>
                                            <div className="position-relative">
                                                <Input type={isShowConfirmPassword ? "text" : "password"} name="confirmPass" value={values.confirmPass} placeholder={'********'} onChange={handleChange} onBlur={handleBlur} required />
                                                <img
                                                    src={isShowConfirmPassword ? eyeIcon : closeEyeIcon}
                                                    alt='eye-icon'
                                                    className='img-icon'
                                                    onClick={() => setIsShowPassword((prevProp) => !prevProp)}
                                                />
                                            </div>

                                        </div>
                                        {touched.confirmPass && errors.confirmPass && (
                                            <span className='error-text text-danger'>{errors.confirmPass}</span>
                                        )}
                                    </FormGroup>
                                    <Button className="btn cta d-block" icon="pi pi-loading"
                                        loading={isLoading} type="submit" disabled={!dirty || !isValid}>Change Password</Button>
                                </Form>
                            )}
                        </Formik>
                        <div className="w-100 d-flex justify-content-center mt-4"><span className='caption'>Privacy Policy | Terms of Use | Copyright © {year}. All rights reserved</span></div>
                    </div>
                </Col>
                <Col className='col-lg-6 d-lg-flex d-none p-4'>
                    <CompanyInfo />
                </Col>
            </Row>
        </div >

    )
}

export default SetNewPassword;