import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Col, Row } from 'reactstrap'
import { Button } from "primereact/button";
import { ROUTES } from '../../constants/routesConstant'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHook'
import { setActivePageHeader } from '../../store/auth/authSlice'
import { saveTenantsListData } from '../../store/properties/propertiesSlice'
import { propertiesService } from '../../helpers/propertiesService'
import { PropertyData, TenantDetailById } from '../../types/properties'
import NoListingView from '../../components/common/NoListingView/NoListingView'
import TenantsList from './components/TenantsList'

import searchIcon from '../../assets/images/svgImages/search_icon.svg'
import clearIcon from '../../assets/images/svgImages/clear_input_icon.svg'
import NoListTenant from '../../assets/images/svgImages/no-list-tenant.svg'

const Tenants = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { tenantsList } = useAppSelector((state) => state.propertiesReducer)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [properties, setProperties] = useState<PropertyData[]>()
  const [checkedProperties, setCheckedProperties] = useState<PropertyData[]>([])
  const [filters, setFilters] = useState<string>("")

  useEffect(() => {
    dispatch(setActivePageHeader('Tenants'))
    getTenantsData()
  }, [])


  useEffect(() => {
    if (filters) {
      const filteredData = tenantsList.filter((data) => data.first_name.toLowerCase().includes(filters.toLowerCase()) || data.last_name.toLowerCase().includes(filters.toLowerCase()) || data.first_name_by_ll.toLowerCase().includes(filters.toLowerCase()) || data.last_name_by_ll.toLowerCase().includes(filters.toLowerCase()))
      dispatch(saveTenantsListData(filteredData, filteredData.length))
    } else {
      getTenantsData()
    }
  }
    , [filters])

  const getTenantsData = async (property?: string) => {
    const response = await propertiesService.getTenantList(property)
    setIsFilterOpen(false)
    if (response.results && response.results.length > 0) {
      dispatch(saveTenantsListData(response.results, response.count))
      if (!properties) {
        const propertyData: PropertyData[] = []
        response.results.map((data: TenantDetailById) => {
          if (data.property_id) {
            const filterUnit = propertyData.filter((x) => x.id == Number(data.property_id))
            if (!filterUnit.length) {
              propertyData.push({
                id: Number(data.property_id),
                title: data.property_title,
              })
            }
          }
        })
        setProperties(propertyData)
      }
      dispatch(setActivePageHeader('Tenants'))
    } else {
      dispatch(saveTenantsListData([], 0))
      dispatch(setActivePageHeader('Tenants'))
    }
  }

  const handleCheckboxChange = (data: PropertyData) => {
    const isChecked = checkedProperties.some((checkedCheckbox) => checkedCheckbox.id === data.id)
    if (isChecked) {
      setCheckedProperties(
        checkedProperties.filter((checkedCheckbox) => checkedCheckbox.id !== data.id),
      )
      /* console.log(checkedProperties) */
    } else {
      setCheckedProperties(checkedProperties.concat(data))
    }
  }

  return (
    <>
      <Row className='mb-5'>
        <Col>
          <h2 className='title'>Tenants</h2>
        </Col>
        <Col className='d-flex align-items-center justify-content-end'>
          <Button className='btn simple' onClick={() => navigate(ROUTES.SUBSCRIPTIONS)}>
            Buy unit connects
          </Button>
          <Button
            className='btn cta ms-2'
            onClick={() => navigate(ROUTES.ADD_TENANTS)}
          >
            New Tenant
          </Button>
        </Col>
      </Row>
      <Row className='pb-4'>
        <Col md={4}>
          <div className='searchbar'>
            <div className='searchbar-container searchbar-container--bg me-2'>
              <input className='form-control' placeholder='Search by tenant name' value={filters} onChange={(e) => {
                setFilters(e.target.value)
              }} />
              <div className='search-icon cursor-pointer'>
                <img src={searchIcon} alt='search-icon' />
              </div>
              {filters && (
                <a
                  onClick={() => {
                    setFilters('')
                  }}
                >
                  <img src={clearIcon} className='search-clear cursor-pointer' />
                </a>
              )}
            </div>
          </div>
        </Col>
      </Row>
      {tenantsList.length ? (
        <TenantsList getTenantsData={getTenantsData} />
      ) : (
        <NoListingView
          image={NoListTenant}
          noPropText='No Tenants'
          addPropText="You haven't added any tenants yet."
          buttonText='Create Tenant'
          onClick={() => navigate(ROUTES.ADD_TENANTS)}
        />
      )}
    </>
  )
}

export default Tenants
