import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { Formik, Form } from 'formik'
import { Button } from "primereact/button";
import { FormGroup, Label, Input, Row } from 'reactstrap'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { ROUTES } from '../../constants/routesConstant'
import { registerFormStates } from '../../pages/Signup'
import { TenantRegistrationRequest } from '../../types/tenantDetails'
import { tenantService } from '../../helpers/tenantService'
import { ErrorToast, SuccessToast } from '../../helpers/toster'
import Checkbox from '../common/Checkbox/Checkbox'

import eyeIcon from '../../assets/images/svgImages/eye_icon.svg'
import closeEyeIcon from '../../assets/images/svgImages/close_eye_icon.svg'

export interface signupFormProps {
  handleSubmit: (registerFormStates: registerFormStates) => void
}

const initialValues = {
  email: '',
  first_name: '',
  last_name: '',
  phone: '',
  password: '',
  retypePassword: '',
}

const signUpValidationSchema = () =>
  Yup.object().shape({
    first_name: Yup.string()
      .max(15, 'Must be 15 characters or less')
      .min(3, 'Must be 3 characters')
      .matches(/[A-Za-z]+/g, 'First Name must contain character only')
      .required('First Name is Required*'),
    last_name: Yup.string()
      .max(20, 'Must be 20 characters or less')
      .min(3, 'Must be 3 characters')
      .matches(/[A-Za-z]+/g, 'Last Name must contain character only')
      .required('Last Name is Required*'),
    // email: Yup.string().required('Email is required*').email('invalid email'),
    // phone: Yup.string().required('Phone Number is required*'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 charaters')
      .matches(
        /^(?=.*[A-Za-zZñÑáéíóúÁÉÍÓÚ])(?=.*\d)(?=.*[@$!%*#?&^()])[A-Za-zZñÑáéíóúÁÉÍÓÚ\d@$!%*#?&^()]{8,}$/,
        'Password must contain at least one letter, one number and one special character',
      )
      .required('Password is required*'),
  })

const SignUpForm = (props: signupFormProps) => {
  useEffect(() => {
    checkURL()
  }, [])
  const location = useLocation()
  const navigate = useNavigate()
  const [isTenant, setIsTenant] = useState(false)

  const [isShowPassword, setIsShowPassword] = useState(false)
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false)

  const [agreeTerms, setAgreeTerms] = useState(false)

  const searchParams = new URLSearchParams(window.location.search)
  const firstName = searchParams.get('first_name') || ''
  const lastName = searchParams.get('last_name') || ''
  const handleSubmit = async (formData: registerFormStates) => {
    if (isTenant) {
      const uidb64 = searchParams.get('uidb64')
      const token = searchParams.get('token')

      const payload: TenantRegistrationRequest = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        password: formData.password,
        confirm_password: formData.retypePassword,
        token: token as string,
        uidb64: uidb64 as string,
      }
      const response = await tenantService.registerTenant(payload)
      if (response?.email) {
        SuccessToast('User registered successfully. Please login')
        navigate(ROUTES.LOGIN)
      } else {
        ErrorToast(response?.response?.data?.password.join(', '))
      }
    } else {
      props.handleSubmit(formData)
    }
  }

  const checkURL = async () => {
    if (location.pathname.includes('tenant')) {
      setIsTenant(true)
      initialValues.first_name = firstName
      initialValues.last_name = lastName
    }
  }

  return (
    <>
      <div className="w-100 text-align-center mb-4">
        {isTenant ? (
          <p className='info-text'>You are attempting to register a tenant account</p>
        ) : (
          <p className='info-text'>You are creating a landlord account</p>
        )}
      </div>
      <div className='w-100 h-100 d-flex flex-column justify-content-center align-items-center'>
        {isTenant ? (
          <>
            <h3 className='form-heading mt-3'>
              Welcome, {firstName} {lastName}
            </h3>
            <div className='informative-content mb-5'>Please create a password and sign in</div>
          </>
        ) : (
          <h1>Sign up & set up your first unit <span style={{ color: "#00695e" }}>completely free</span>!</h1>
        )}


        <Formik
          initialValues={initialValues}
          onSubmit={(values: registerFormStates) => {
            handleSubmit(values)
          }}
          validationSchema={() => signUpValidationSchema()}
        >
          {({ errors, values, touched, handleBlur, handleChange, setFieldValue, isValid }) => (
            <Form className=''>
              {!isTenant && (
                <>
                  <Row>
                    <FormGroup className='col-md-6 mb-4 p-0'>
                      <Label>First name</Label>
                      <Input
                        name='first_name'
                        type={'text'}
                        placeholder='John'
                        onChange={handleChange}
                        value={isTenant ? firstName : values.first_name}
                        isRequired={true}
                        onBlur={handleBlur}
                        disabled={isTenant}
                      />
                      {touched.first_name && errors.first_name && (
                        <span className='error-text text-danger'>{errors.first_name}</span>
                      )}
                    </FormGroup>

                    <FormGroup className='col-md-6 mb-4 p-0'>
                      <Label>Last name</Label>
                      <Input
                        name='last_name'
                        type={'text'}
                        placeholder='Doe'
                        onChange={handleChange}
                        value={isTenant ? lastName : values.last_name}
                        isRequired={true}
                        onBlur={handleBlur}
                        disabled={isTenant}
                      />
                      {touched.last_name && errors.last_name && (
                        <span className='error-text text-danger'>{errors.last_name}</span>
                      )}
                    </FormGroup>
                  </Row>
                  <FormGroup className='col-12 my-4'>
                    <Label>Email address</Label>
                    <Input
                      name='email'
                      type={'text'}
                      placeholder='example@domain.com'
                      onChange={handleChange}
                      value={values.email}
                      isRequired={true}
                      onBlur={handleBlur}
                    />
                    {touched.email && errors.email && (
                      <span className='error-text text-danger'>{errors.email}</span>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <div
                      className={
                        !!values.phone === false
                          ? 'phone-input position-relative col-12 mb-4'
                          : 'phone-input position-relative mb-4 filled col-12'
                      }
                    >
                      <Label>Phone number</Label>
                      <PhoneInput
                        className='form-control'
                        value={values.phone}
                        defaultCountry='US'
                        smartCaret={true}
                        onChange={(e: any) => setFieldValue('phone', e)}
                        required
                        onBlur={handleBlur}
                        name='phone'
                        maxLength={14}
                      />

                      {touched.phone && errors.phone ? (
                        <span className='error-text text-danger'>{errors.phone}</span>
                      ) : values.phone && !errors.phone && isValidPhoneNumber(values.phone) === false ? (
                        <span className='error-text text-danger'>Invalid Phone Number</span>
                      ) :
                        <p className='form-input-text'>
                          Standard call, messaging or data rates may apply.
                        </p>
                      }
                    </div>
                  </FormGroup>
                </>
              )}

              <div className='row'>
                <FormGroup className='col-12 p-0'>
                  <Label>Password</Label>
                  <div className="position-relative">
                    <Input
                      name='password'
                      label={'Password'}
                      placeholder={'********'}
                      type={isShowPassword ? 'text' : 'password'}
                      onChange={handleChange}
                      value={values.password}
                      isRequired={true}
                      onBlur={handleBlur}
                    />
                    <img
                      alt='eye-icon'
                      className='img-icon'
                      src={isShowPassword ? eyeIcon : closeEyeIcon}
                      onClick={() => setIsShowPassword((prevProp) => !prevProp)}
                    />
                  </div>
                  {touched.password && errors.password && (
                    <span className='error-text text-danger'>{errors.password}</span>
                  )}
                </FormGroup>
              </div>

              {isTenant && (
                <FormGroup className='col-12 mb-4 position-relative'>
                  <Input
                    name='retypePassword'
                    label={'Repeat password'}
                    type={isShowConfirmPassword ? 'text' : 'password'}
                    onChange={handleChange}
                    value={values.retypePassword}
                    isRequired={true}
                    onBlur={handleBlur}
                  />
                  <img
                    alt='eye-icon'
                    className='img-icon'
                    src={isShowConfirmPassword ? eyeIcon : closeEyeIcon}
                    onClick={() => setIsShowConfirmPassword((prevProp) => !prevProp)}
                  />
                  {touched.password && errors.password && (
                    <span className='error-text text-danger'>{errors.password}</span>
                  )}
                </FormGroup>
              )}

              <div className='d-flex align-items-center justify-content-end'>
                <Link className='link font-bold me-3' to={ROUTES.LOGIN}>Already have an account?</Link>
                <Button
                  className='btn cta large'
                  type='submit'
                  disabled={!agreeTerms || !isValid}
                >
                  <span>{isTenant ? 'Sign in' : 'Start Free Trial'}</span>
                </Button>
              </div>
              <div className='mt-3 mb-1'>
                <Checkbox
                  checked={agreeTerms}
                  onChange={() => setAgreeTerms((prevProp) => !prevProp)}
                />
              </div>
              <hr />
              <span className="caption">This site is protected by reCAPTCHA and the Google{' '}
                <Link to='/' className='app-links'>
                  Privacy Policy
                </Link>{' '}
                and{' '}
                <Link to='/' className='app-links'>
                  Terms of Service
                </Link>{' '}
                apply.</span>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default SignUpForm
