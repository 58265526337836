import { toast } from 'react-toastify'

const SuccessToast = (message: string) => {
  toast.success(message, {
    position: toast.POSITION.BOTTOM_LEFT,
    className: 'toast-message',
  })
}
const ErrorToast = (message: string) => {
  toast.error(message, {
    position: toast.POSITION.BOTTOM_LEFT,
    className: 'toast-message',
  })
}

const WarnToast = (message: string) => {
  toast.warn(message, {
    position: toast.POSITION.BOTTOM_LEFT,
    className: 'toast-message',
  })
}

const InfoToast = (message: string) => {
  toast.info(message, {
    position: toast.POSITION.BOTTOM_LEFT,
    className: 'toast-message',
  })
}

export { SuccessToast, ErrorToast, WarnToast, InfoToast }
