import { Card, CardBody } from 'reactstrap';
import { DoughnutChart }  from '../DoughnutChart/DoughnutChart';
import GooglePieChart from '../GooglePieChart/GooglePieChart';

export interface CardProps {
  heading?: number | string | null
  subHeading?: string
  isChart?: boolean
  icon?: string
  isRingChart?: boolean
  doughnutChartData?: object
}

const data = [
  ["Task", "Hours per Day"],
  ["Paid", 11],
  ["Due", 2],
];

const options = {
  width: 100,
  height: 90,
  pieHole: 0.7
  ,
  is3D: false,
  legend: {
    position: 'bottom',
  },
  radius: "10px",
  pieSliceText: 'none',
  colors: [ "#02AD88", "#D02F44"]
};

export const OverviewDetailCard = (props: CardProps) => {
  const { heading = '', subHeading = '', isChart = false , icon , isRingChart = false, doughnutChartData } = props;

  return (
    <Card className='payment-doughnutCard h-100'>
      <CardBody>
        <div className='d-flex justify-content-between align-items-center h-100'>
          <div className='headings'>
            <div className='main-heading'>{heading}</div>
            <div className='sub-heading'>{subHeading}</div>
          </div>
          { isChart &&
            <div className='count-ring'>
              <div className=''>
                <DoughnutChart
                  {...doughnutChartData}
                />
              </div>
            </div>
          }
          { (!isChart && !isRingChart) && 
            <div className='Detail'>
              <p className='mb-0'>
              {
                <img src={icon} alt="" />
              }
              </p>
            </div>
          }
          {(isRingChart && !isChart) &&
            <GooglePieChart chartData={data} chartOptions={options}></GooglePieChart>
          }
        </div>
      </CardBody>
    </Card>
  )
}
