import { useNavigate } from 'react-router-dom'
import { LoginUserDetail } from '../types/auth'
import { parseJwt } from './utils'
import { ROUTES } from '../constants/routesConstant'
import { useAppDispatch } from '../hooks/storeHook'
import { logout } from '../store/auth/authSlice'
import API from './api'
import { API_URLS } from '../constants/appConstants'

export const setUser = async (userData: LoginUserDetail) => {
  await localStorage.setItem('pmanagement.user', JSON.stringify(userData))
}
export const getUser = (): LoginUserDetail => {
  return JSON.parse(localStorage.getItem('pmanagement.user') || '{}')
}
export const setToken = (token: string) => {
  localStorage.setItem('pmanagement.token', token)
}
export const getToken = () => {
  return localStorage.getItem('pmanagement.token')
}

export const isTokenExpired = () => {
  return new Promise((resolve) => {
    API.get(API_URLS.IS_AUTHENTICATED).then((response) => {
      if (response.status === 200 || response.status === 201) {
        return resolve(true)
      } else {
        const refreshToken = JSON.parse(`{"refresh" : "${getUser().tokens.refresh}"}`)
        API.post(API_URLS.REFRESH_TOKEN, refreshToken).then((response) => {
          if (response.status === 200 || response.status === 201) {
            setToken(response.data.access)
            return resolve(response.data)
          } else {
            const navigate = useNavigate()
            const dispatch = useAppDispatch()
            clearStorage()
            dispatch(logout())
            navigate(ROUTES.LOGIN)
            return false
          }
        })
      }
    })
  })
}

export const isLoggedIn = () => {
  const user = getUser()
  const decodedJwt = parseJwt(getToken() || '')
  if (decodedJwt && decodedJwt.exp * 1000 < Date.now()) {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    clearStorage()
    dispatch(logout())
    navigate(ROUTES.LOGIN)
    return false
  }
  return Object.keys(user).length > 0 && Object.keys(user.tokens).length > 0
}
export const clearStorage = () => {
  localStorage.removeItem('pmanagement.token')
  localStorage.removeItem('pmanagement.user')
}

export const getMenuPreference = () => {
  return localStorage.getItem('menu_is_collapsed')
}

export const setMenuPreference = (data: boolean) => {
  return localStorage.setItem('menu_is_collapsed', data.toString())
}
