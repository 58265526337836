

import React from 'react';
import { Images } from "../../../types/properties";

interface ImagePreviewProps {
  imagesData: Images[];
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ imagesData }) => {
  return (
    <div className="image-preview-container">
      {imagesData ? imagesData.map((image: any, index: number) => (
        <div className="image-preview" key={index}>
          <img src={image.image} alt="property" />
        </div>
      ))
        : null}
    </div>
  )
}

export default ImagePreview