import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'

import { useAppDispatch, useAppSelector } from '../../../hooks/storeHook'
import { clearSelecteditem } from '../../../store/properties/propertiesSlice'
import { ROUTES } from '../../../constants/routesConstant'
import { PropertiesListData } from '../../../types/properties'
import { propertiesService } from '../../../helpers/propertiesService'
import { ErrorToast } from '../../../helpers/toster'

import { Col, Row } from 'reactstrap'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Badge } from 'primereact/badge';
import { ProgressBar } from 'primereact/progressbar'
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator'
import Dropdown from '../../../components/common/Dropdown/Dropdown'
import DetailView from "../../../components/common/DetailView/DetailView"
import DeleteConfirmModal from '../../../components/common/DeleteConfirm/DeleteConfirmModal'
import ImagePreview from '../../../components/common/ImagePreview/ImagePreview'

interface PropertyListProps {
  getPropertyData: () => void
}

const PropertiesList = (props: PropertyListProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { propertiesList, totalPropertiesCount } = useAppSelector(
    (state) => state.propertiesReducer,
  )
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(8)
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsToShow, setRowsToShow] = useState<PropertiesListData[]>([])
  const [selectedProperty, setSelectedProperty] = useState<PropertiesListData | null>(null)
  const [showDeleteConfirmModal, setDeleteConfirmModal] = useState(false)
  const [showDetailsView, setShowDetailsView] = useState(false)

  useEffect(() => {
    dispatch(clearSelecteditem('selectedProperty'))
    setRowsToShow(propertiesList.slice(0, 8))
  }, [])

  useEffect(() => {
    setRowsToShow(propertiesList.slice(0, 8))
  }, [propertiesList])

  const onPageChange = (e: PaginatorPageChangeEvent) => {
    setFirst(e.first)
    setRows(e.rows)
    setCurrentPage(e.page + 1)
    setRowsToShow(propertiesList.slice(e.first, e.rows * (e.page + 1)))
  }

  const handleDeleteProperty = async () => {
    const selectedPropertyId = selectedProperty?.id || 0
    try {
      await propertiesService.deleteProperty(selectedPropertyId)
      props.getPropertyData()
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.data?.detail) {
          ErrorToast(err.response?.data?.detail || 'Error in delete property')
        } else {
          ErrorToast('Error in delete property')
        }
      }
    }
  }

  const formatRent = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };


  /* Templates */
  const dropDownTemplate = (rowData: PropertiesListData) => {
    const DropdownRef = useRef<any>(null)
    const DropdownItems = [
      {
        text: 'Open Details',
        onClick: (e: any) => {
          DropdownRef.current?.toggle(e)
          setShowDetailsView(true)
          setSelectedProperty(rowData)
        },
      },
      {
        text: 'Edit Property',
        onClick: (e: any) => {
          DropdownRef.current?.toggle(e)
          navigate(`${ROUTES.EDIT_PROPERTY}/${rowData.id}`)
        },
      },
      {
        text: 'Delete Property',
        onClick: (e: any) => {
          DropdownRef.current?.toggle(e)
          setSelectedProperty(rowData)
          setDeleteConfirmModal(true)
        },
        className: 'text-danger',
      },
    ]
    return (
      <Dropdown ref={DropdownRef} onClick={(e: any) => DropdownRef.current?.toggle(e)} content={DropdownItems} />
    )
  }

  const featureTemplate = (rowData: PropertiesListData) => {
    return (
      <div className='features-list'>
        {rowData.features.map((feature, index) => {
          if (index > 3) {
            return null
          } else if (index === 3) {
            return <span key={index} className='me-1'>...</span>
          }
          return <Badge key={index} className='me-1 badge-list' value={feature} />
        })}
      </div>
    )
  }

  const occupancyTemplate = (rowData: PropertiesListData) => {
    return (
      <div className='occupancy-progress'>
        <ProgressBar
          value={rowData.occupied_percentage}
          showValue={false}
          style={{ height: '1.5rem' }}
        />
        <div className='occupancy-percentage'>{rowData.occupied_percentage}%</div>
      </div>
    )
  }

  const totalRentTemplate = (rowData: PropertiesListData) => {
    return <span>{rowData.total_rent ? '' + `$${formatRent(rowData.total_rent)}` : 'N/A'}</span>
  }

  const createdDateTemplate = (rowData: PropertiesListData) => {
    return <span>{moment(rowData.created).format("MMM D, YYYY")}</span>
  }

  const descriptionTemplate = (rowData: PropertiesListData) => {
    return (
      <div>{`${rowData.description.slice(0, 26)}${rowData.description.length > 26 ? '...' : ''
        }`}</div>
    )
  }

  const paginationTemplate = {
    layout: 'PrevPageLink PageLinks NextPageLink',
  }

  return (
    <>
      <DetailView closeButton={showDetailsView} onCloseView={(e: boolean) => { setShowDetailsView(e) }}
        editButton={() => navigate(`${ROUTES.EDIT_PROPERTY}/${selectedProperty?.id}`)}
        deteleButton={() => setDeleteConfirmModal(true)}>
        <h2>{selectedProperty?.title}</h2>
        <Row>
          <Row className='col-6 col-sm-12'>
            <Col className='col-12 col-sm-6'><h4>Type</h4></Col>
            <Col className='col-12 col-sm-6'><p>{selectedProperty?.property_type.title}</p></Col>
          </Row>
          <Row className='col-6 col-sm-12'>
            <Col className='col-12 col-sm-6'><h4>Total rent</h4></Col>
            <Col className='col-12 col-sm-6'><p>{selectedProperty?.total_rent ? `USD ${formatRent(selectedProperty.total_rent)}` : 'N/A'}</p></Col>
          </Row>
        </Row>
        <Row className='col-12'>
          <Col className='col-12 col-sm-6'><h4>Address</h4></Col>
          <Col className='col-12 col-sm-6'><p>{selectedProperty?.address}</p></Col>
        </Row>
        <hr />
        <h3 className='mb-4'>Property Details</h3>
        <div className='col-12'>
          <Col className='col-12 col-sm-6'><h4>Features</h4></Col>
          <Col className='col-12 col-sm-6'><p>{selectedProperty?.features.map((feature, index) => {
            return (<Badge key={index} className='me-1 badge-list' value={feature} />)
          })}</p></Col>
        </div>
        <div className='col-12'>
          <Col className='col-12 col-sm-6'><h4>Description</h4></Col>
          <Col className='col-12 col-sm-6'><p>{selectedProperty?.description ? descriptionTemplate(selectedProperty) : null}</p></Col>
        </div>
        <div className='col-12'>
          <Col md={12}><h4>Images</h4></Col>
          <Col md={12}><ImagePreview imagesData={selectedProperty?.images || []} /></Col>
        </div>
      </DetailView>


      <div className='data-list datatable-listing pb-2 mb-3'>
        <DataTable
          editMode='row'
          value={rowsToShow}
          dataKey='id'
          tableStyle={{ minWidth: '1300px' }}
        >
          <Column
            sortable
            className='fw-semibold text-black'
            field='title'
            header='Property Name'
            body={(rowData: PropertiesListData) => (<span>{rowData.title}</span>)}

          ></Column>
          <Column
            sortable
            className='fw-semibold text-black'
            field='type'
            header='Type'
            body={(rowData: PropertiesListData) => (<span>{rowData.property_type.title}</span>)}
          ></Column>
          <Column
            sortable
            className='fw-semibold text-black'
            field='address'
            header='Address'
          ></Column>
          <Column
            sortable
            className='fw-semibold text-black'
            field='features'
            header='Features'
            body={featureTemplate}
          ></Column>

          <Column
            sortable
            className='fw-semibold text-black occupancy-column'
            field='occupied_percentage'
            header='Occupancy'
            body={occupancyTemplate}
          ></Column>
          <Column
            sortable
            className='fw-semibold text-black'
            field='description'
            header='Description'
            body={descriptionTemplate}
          ></Column>
          <Column
            sortable
            className='fw-semibold text-black'
            field='totalRent'
            header='Total Rent, USD'
            body={totalRentTemplate}
          ></Column>
          <Column
            sortable
            className='fw-semibold text-black'
            field='created'
            header='Date Created'
            body={createdDateTemplate}
          ></Column>
          <Column headerStyle={{ width: '4rem' }} body={dropDownTemplate}></Column>
        </DataTable>

        <div className='pagination-section mt-3 d-flex align-items-center'>
          <div className='total-records'>
            Results: {first + 1}-
            {totalPropertiesCount - first < rows
              ? first + (totalPropertiesCount - first)
              : rows * currentPage}{' '}
            of {totalPropertiesCount}
          </div>
          {propertiesList.length > 8 && (
            <div className='pagination-container ms-auto'>
              <Paginator
                template={paginationTemplate}
                first={first}
                rows={rows}
                totalRecords={totalPropertiesCount}
                onPageChange={(e) => onPageChange(e)}
                className='justify-content-start'
              />
            </div>
          )}
        </div>
      </div>

      <DeleteConfirmModal
        handleReject={() => {
          setDeleteConfirmModal(false)
        }}
        handleAccept={() => { handleDeleteProperty(); setDeleteConfirmModal(false) }}
        isModalOpen={showDeleteConfirmModal}
        header={'Delete Confirmation'}
        message={'Do you want to delete this property'}
      />
    </>
  )
}

export default PropertiesList
