
import React, { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Card, CardBody, CardHeader } from "reactstrap";
import { DialogData } from "../../../types/setting";

interface DialogProps {
    open: boolean
    setOpen: (status: boolean) => void
    isSuccess?: boolean,
    data?: DialogData
}
export default function Index(props: DialogProps) {
    const { open, setOpen, data } = props;
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        setVisible(open)
    }, [open])



    const [visible, setVisible] = useState(false);

    const openNewTab = () => {
        if (data?.link) {
            window.open(data?.link, '_blank', 'noreferrer')
        } else {
            return
        }
    }

    return (

        <div className="d-flex justify-content-center">
            <Dialog className="dialog" visible={visible} style={{ width: '50vw' }} onHide={() => setOpen(false)}>
                <Card className="form-card h-100">
                    <CardHeader className="dialogHeader"></CardHeader>
                    <CardBody className="d-flex">
                        <div className="d-flex cardBody">
                            <div className="card-content">
                                {data?.image &&
                                    <div className='chechbox d-flex justify-content-center mt-4'>
                                        <img src={data?.image} alt="no-image" />
                                    </div>
                                }
                                {!data?.image &&

                                    <div className='chechbox d-flex justify-content-center mt-4'>
                                        <input className="form-check-input" type="checkbox" checked={!checked} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            e.preventDefault();
                                            setChecked(!checked)
                                        }} />
                                    </div>
                                }

                                <p className="payment-message">{data?.heading}</p>
                                <p className="regards">{data?.subheading}</p>
                                {data?.link &&
                                    <p className="regards"><a className="redirect-Link" onClick={() => { openNewTab() }} >{data?.link}</a></p>
                                }

                                <div className="d-flex justify-content-center">
                                    <Button onClick={() => setOpen(false)}  >{data?.buttonText}</Button>
                                </div>
                            </div>
                        </div>

                    </CardBody>
                </Card>
            </Dialog>
        </div>






    )
}
