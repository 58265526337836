import { useEffect, useState } from 'react'
import moment from 'moment'

import { Button } from "primereact/button";
import { Modal, ModalBody, Label, Col, Row, Input } from 'reactstrap'
import { Calendar } from 'primereact/calendar'
import { Hours, Minutes, Meridiem } from '../../../constants/appConstants'

interface CustomScheduleModal {
  isModalOpen: boolean
  handleClose: () => void
  handleButtonAction: (e: string) => void
}

const CustomScheduleModal = (props: CustomScheduleModal) => {
  const [date, setDate] = useState(new Date())
  const [time, setTime] = useState({
    hour: 12,
    minute: 0,
    meridiem: 'a',
  })
  const [dateTime, setDateTime] = useState('')

  useEffect(() => {
    const Date = moment(date).format('YYYY-MM-DD')
    const meridiem = time.meridiem === 'a' ? 'AM' : 'PM'
    const timeString = moment(`${time.hour}:${time.minute} ${meridiem}`, 'h:m A').format('HH:mm')

    const dateTime = moment(`${Date} ${timeString}`, 'YYYY-MM-DD HH:mm').format()
    setDateTime(dateTime)
  }, [date, time])

  const handleClose = () => {
    props.handleClose()
  }

  return (
    <Modal
      size='md'
      centered
      className='common-alert-modal custom-recurrence-modal'
      isOpen={props.isModalOpen}
      toggle={props.handleClose}
    >
      <div className='modal-header-container'>
        <div className='header-heading'>Send Message</div>
      </div>
      <ModalBody className='common-body-container p-4 d-flex flex-column justify-content-beetween align-items-stretch'>
        <div className='modal-body-content custom-schedule-modal'>
          <div>
            <Col className='mt-3'>
              <Label>When</Label>
              <Calendar
                showIcon
                value={date}
                onChange={(date: any) => {
                  setDate(moment(date).toDate())
                }}
                placeholder='Select a date'
                name='date'
                minDate={new Date()}
                maxDate={moment().endOf('month').toDate()}
                dateFormat='yy-mm-dd'
              />
            </Col>
            <Row className='mt-3 ModalHourInputs'>
              <Label>Time</Label>
              <Col>
                <Input
                  id='hour'
                  type='select'
                  name='hour'
                  value={time.hour}
                  onChange={(e) => {
                    setTime({ ...time, hour: parseInt(e.target.value) })
                  }}
                >
                  <>
                    {Hours.map((data, index) => (
                      <option key={index} value={data}>
                        {data}
                      </option>
                    ))}
                  </>
                </Input>
              </Col>
              <Col>
                <Input
                  id='at_minutes'
                  type='select'
                  name='at_minutes'
                  value={time.minute}
                  onChange={(e) => {
                    setTime({ ...time, minute: parseInt(e.target.value) })
                  }}
                >
                  <>
                    {Minutes.map((data, index) => (
                      <option key={index} value={data}>
                        {data}
                      </option>
                    ))}
                  </>
                </Input>
              </Col>
              <Col>
                <Input
                  id='meridiem'
                  type='select'
                  name='meridiem'
                  value={time.meridiem}
                  onChange={(e) => {
                    setTime({ ...time, meridiem: e.target.value })
                  }}
                >
                  <>
                    {Meridiem.map((meridiem, index) => {
                      return (
                        <option key={index} value={meridiem.value}>
                          {meridiem.label}
                        </option>
                      )
                    })}
                  </>
                </Input>
              </Col>
            </Row>
          </div>
          <div className='modal-button d-flex justify-content-end mt-3 w-full'>
            <Button
              className='btn simple me-2'
              onClick={() => handleClose()}
            >
              {'Cancel'}
            </Button>
            <Button
              className='btn cta'
              onClick={() => {
                props.handleButtonAction(dateTime)
              }}
            >
              {'Set'}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CustomScheduleModal
