import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from "primereact/button";
import { FormGroup, Input, Label } from 'reactstrap';
import eyeIcon from '../../assets/images/svgImages/eye_icon.svg';
import closeEyeIcon from '../../assets/images/svgImages/close_eye_icon.svg';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { loginFormStates } from '../../types/auth';
import { ROUTES } from '../../constants/routesConstant';

export interface loginFormProps {
    handleSubmit: (loginFormStates: loginFormStates) => void;
}

const initialValues = {
    email: '',
    password: '',
    portal: "tenant"
}

const signInValidationSchema = () =>
    Yup.object().shape({
        email: Yup.string().required('Email is required*').email('invalid email'),
        password: Yup.string().required("Password is required*"),
    })

const SignInForm = (props: loginFormProps) => {
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isTenant, setIsTenant] = useState(true);

    const handleSubmit = (formData: loginFormStates) => {
        formData.portal = isTenant ? "tenant" : "landlord";
        props.handleSubmit(formData);
    }

    return (
        <>
            <div className="w-100 d-flex justify-content-center align-items-center mb-4">
                <div className="portal-tab">
                    <button className={`tab-button ${isTenant ? 'active' : ''}`} onClick={() => setIsTenant(true)}>Tenant account</button>
                    <button className={`tab-button ${!isTenant ? 'active' : ''}`} onClick={() => setIsTenant(false)}>Landlord account</button>
                </div>
            </div>
            <Formik
                initialValues={initialValues}
                onSubmit={(values: loginFormStates) => handleSubmit(values)}
                validationSchema={() => signInValidationSchema()}>
                {({
                    errors,
                    values,
                    touched,
                    handleBlur,
                    handleChange,
                    dirty,
                    isValid
                }) => (
                    <Form className='login-form'>
                        <h1>Sign in</h1>
                        <span>New user? <Link to="signup" className='link'>Create an account</Link>.</span>
                        <FormGroup className="my-4">
                            <Label>Email address<span className="required">*</span></Label>
                            <Input name="email" placeholder={'example@domain.com'} type={'text'} onChange={handleChange} value={values.email} isRequired={true} onBlur={handleBlur} />
                            {touched.email && errors.email && (
                                <span className='error-text text-danger'>{errors.email}</span>
                            )}
                        </FormGroup>
                        <FormGroup className="mb-4">
                            <Label>Password<span className="required">*</span></Label>
                            <div className="position-relative">
                                <Input name="password" placeholder={'********'} type={isShowPassword ? 'text' : 'password'} onChange={handleChange} value={values.password} isRequired={true} onBlur={handleBlur} />
                                <img alt="eye-icon" className="img-icon" src={isShowPassword ? eyeIcon : closeEyeIcon} onClick={() => setIsShowPassword(prevProp => !prevProp)} />
                            </div>
                            {touched.password && errors.password && (
                                <span className='error-text text-danger'>{errors.password}</span>
                            )}
                        </FormGroup>
                        <div className="d-flex align-items-center justify-content-end gap-4">
                            <Link to={ROUTES.PASSWORD_RECOVERY} className='link'>Forgot Password?</Link>
                            <Button className="btn cta" type="submit" disabled={!dirty || !isValid}>Sign In</Button>
                        </div>
                        <hr />
                        <span className='caption'>This site is protected by reCAPTCHA and the Google <span className="link caption font-bold">Privacy Policy</span> and <span className="link caption font-bold">Terms of Service</span> apply.</span>
                    </Form>
                )}
            </Formik>
        </>


    )
}

export default SignInForm;