interface AddPaymentMethodViewProps {
    pymtMethodImage: string;
}

const AddPaymentMethodView = (props: AddPaymentMethodViewProps) => {
    return (
        <div className='add-pymt-method-view'>
            <div className='text-center'>
                <img className="mt-2" height={'170'} src={props.pymtMethodImage} alt='no-payment-method' />  
            </div>
        </div>
    )
}

export default AddPaymentMethodView;
