import axios from 'axios';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

import moment from 'moment';
import { Formik, Form } from 'formik';
import { Button } from "primereact/button";
import { Col, Row, FormGroup, Input, Label } from 'reactstrap';
import { API_URLS } from '../../constants/appConstants';
import API from '../../helpers/api';
import { ErrorToast, SuccessToast } from '../../helpers/toster';
import { passRecoveryFormStates } from '../../types/auth';
import CompanyInfo from '../../components/CompanyInfo/CompanyInfo';
import { BackButton } from '../../components/common/BackButton/BackButton';
import { ROUTES } from '../../constants/routesConstant';

const initialValues = {
    email: '',
}

const year = moment().format('YYYY') || "2024"

const PassRecoveryValidationSchema = () =>
    Yup.object().shape({
        email: Yup.string().required('Email is required*').email('Invalid email')
    })

const PasswordRecovery = () => {

    const handleRecoveryEmail = async (formValues: passRecoveryFormStates) => {
        try {
            const response = await API.post(API_URLS.REQUEST_RESET_PASSWORD_EMAIL, formValues);
            if (response.status === 201 || response.status === 200) {
                SuccessToast("We have sent you an email to reset your password.");
            } else {
                ErrorToast("Error while sending email. Please try after some time");
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.data.email) {
                    ErrorToast(error.response?.data.email[0]);
                    return false;
                }
                if (error.response?.data.password) {
                    ErrorToast(error.response?.data.password[0]);
                    return false;
                }
            }
            ErrorToast("Invalid request");
        }
    }
    const handleSubmit = (formData: passRecoveryFormStates) => {
        handleRecoveryEmail(formData);
    }

    return (
        <div className='password-recovery-container'>
            <Row className='d-flex justify-content-center align-items-center h-100 w-100 p-0'>
                <Col className='col-lg-6 col-12 h-100 p-0'>
                    <div className="password-recovery-wrapper">
                        <div className="w-100">
                            <span className='d-flex justify align-items-center gap-2'><BackButton /> Back to login</span>
                        </div>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={(values: passRecoveryFormStates) => handleSubmit(values)}
                            validationSchema={() => PassRecoveryValidationSchema()}>
                            {({
                                errors,
                                values,
                                touched,
                                handleBlur,
                                handleChange,
                                dirty,
                                isValid
                            }) => (
                                <Form className='password-recovery-form'>
                                    <>
                                        <h1>Password recovery</h1>
                                        <br />
                                        <h3>Forgot password?</h3>
                                        <p className='balanced'>No worriest! Just enter your email and we’ll send you a reset password link.</p>
                                        <FormGroup className="mb-4 mt-4">
                                            <div className="boxlabel-input-container">
                                                <Label for="email">Email address</Label>
                                                <Input type="email" name="email" placeholder="example@domain.com" onChange={handleChange} value={values.email} onBlur={handleBlur} required />
                                            </div>
                                            {touched.email && errors.email && (
                                                <span className='error-text text-danger'>{errors.email}</span>
                                            )}
                                        </FormGroup>
                                        <Button className="btn cta mb-3 d-block" type="submit" disabled={!dirty || !isValid}>Send Recovery Email</Button>
                                        <p className='font-bold'>Just remembered? <Link className='link' to={ROUTES.LOGIN}>Login</Link></p>
                                    </>
                                </Form>
                            )}
                        </Formik>
                        <div className="w-100 d-flex justify-content-center ">
                            <span className="font-bold">Having trouble? <span style={{ color: "#00695e" }}>Get help</span>.</span>
                        </div>
                        <div className="w-100 d-flex justify-content-center mt-4"><span className='caption'>Privacy Policy | Terms of Use | Copyright © {year}. All rights reserved</span></div>
                    </div>
                </Col>
                <Col className='col-lg-6 d-lg-flex d-none p-4'>
                    <CompanyInfo />
                </Col>
            </Row>
        </div >
    )
}

export default PasswordRecovery;