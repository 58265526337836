import { API_URLS } from '../constants/appConstants'
import API from './api'


const getNonPaymentFinancialData = async () =>{
  try {
    const response = await API.get(API_URLS.GET_NON_PAYMENT_DASHBOARD_DATA)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getMetricsDashboardData = async () =>{
  try {
    const response = await API.get(API_URLS.GET_METRICS_DASHBOARD_DATA)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const gePaymentHistoryList = async () => {
  try {
    const response = await API.get(API_URLS.GET_PAYMENt_HISTORY_LIST)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const updatePaymentHistoryStatus = async (status: string, id: string) => {
  try {
    const response = await API.post(API_URLS.UPDATE_PAYMENt_HISTORY_STATUS.replace('{id}', id), {status: status})
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

export const overviewService = {
  getNonPaymentFinancialData,
  getMetricsDashboardData,
  gePaymentHistoryList,
  updatePaymentHistoryStatus
}