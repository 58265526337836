import moment from 'moment'
import { API_URLS } from '../constants/appConstants'
import {
  AddTenantFormStates,
  BuyUnitsFormStates,
  ChangePaymentStatus,
  DiscountCouponDetail,
  DeleteMultipleTanants,
  ResponseStructure,
} from '../types/properties'
import API from './api'

const getPropertyTypes = async () => {
  try {
    const response = await API.get(API_URLS.GET_PROPERTY_TYPES)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getPropertySubTypes = async () => {
  try {
    const response = await API.get(API_URLS.GET_PROPERTY_SUBTYPES)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getPropertyList = async () => {
  try {
    const response = await API.get(API_URLS.PROPERTY)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getPropertyById = async (id?: string) => {
  try {
    const response = await API.get(`${API_URLS.PROPERTY}${id}/`)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const saveProperty = async (data: FormData) => {
  return new Promise((resolve, reject) => {
    API.post(API_URLS.PROPERTY, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          return resolve(response.data)
        } else {
          throw `request failed with status ${response.status}`
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

const editProperty = async (data: FormData, id?: string) => {
  return new Promise((resolve, reject) => {
    API.put(`${API_URLS.PROPERTY}${id}/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          return resolve(response.data)
        } else {
          throw `request failed with status ${response.status}`
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

const savePropertyUnit = (data: FormData) => {
  return new Promise((resolve, reject) => {
    API.post(API_URLS.PROPERTY_UNIT, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          return resolve(response.data)
        } else {
          throw `request failed with status ${response.status}`
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

const editPropertyUnit = async (data: FormData, id?: string) => {
  return new Promise((resolve, reject) => {
    API.put(`${API_URLS.PROPERTY_UNIT}${id}/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          return resolve(response.data)
        } else {
          throw `request failed with status ${response.status}`
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

const getAllPropertyUnits = async () => {
  try {
    const response = await API.get(API_URLS.PROPERTY_UNIT)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getPropertyUnitById = async (id?: string) => {
  try {
    const response = await API.get(`${API_URLS.PROPERTY_UNIT}${id}/`)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getUnitsByProperty = async (properties: string, types: string) => {
  try {
    let url = `${API_URLS.PROPERTY_UNIT}`
    if (properties && !types) {
      url = `${API_URLS.PROPERTY_UNIT}?assigned_properties=${properties}`
    } else if (types && !properties) {
      url = `${API_URLS.PROPERTY_UNIT}?property_types=${types}`
    } else {
      url = `${API_URLS.PROPERTY_UNIT}?assigned_properties=${properties}&property_types=${types}`
    }

    const response = await API.get(url)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

export const getDisplayTenants = async () => {
  try {
    const response = await API.get(API_URLS.DISPLAY_TENANTS)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const addTenant = async (data: AddTenantFormStates) => {
  const payload = {
    first_name_by_ll: data.first_name_by_ll,
    last_name_by_ll: data.last_name_by_ll,
    email: data.email,
    phone: data.phone,
    property: data.property ? data.property : undefined,
    unit: data?.unit ? Number(data.unit) : undefined,
    lease_start_date: data.lease_start_date
      ? moment(data.lease_start_date).format('YYYY-MM-DD')
      : undefined,
    lease_end_date: data.lease_start_date
      ? moment(data.lease_end_date).format('YYYY-MM-DD')
      : undefined,
  }

  !payload.unit ?? delete payload['unit']
  ;(!payload.property || payload.property == '') ?? delete payload['property']
  !payload.lease_start_date ?? delete payload['lease_start_date']
  !payload.lease_end_date ?? delete payload['lease_end_date']

  return new Promise((resolve, reject) => {
    API.post(API_URLS.ADD_TENANT, payload)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          if (response.data?.success === false) {
            throw response.data?.msg
          } else {
            return resolve(response.data)
          }
        } else {
          throw `request failed with status ${response.status}`
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

const editTenant = async (data: AddTenantFormStates, tenantId?: string) => {
  return new Promise((resolve, reject) => {
    const url = API_URLS.EDIT_TENANT.replace('{id}', tenantId ? tenantId : '')
    const payload = {
      first_name_by_ll: data.first_name_by_ll,
      last_name_by_ll: data.last_name_by_ll,
      email: data.email,
      phone: data.phone,
      property: data.property ? data.property : undefined,
      unit: data?.unit ? Number(data.unit) : undefined,
      lease_start_date: data.lease_start_date
        ? moment(data.lease_start_date).format('YYYY-MM-DD')
        : undefined,
      lease_end_date: data.lease_start_date
        ? moment(data.lease_end_date).format('YYYY-MM-DD')
        : undefined,
    }

    !payload.unit ?? delete payload['unit']
    ;(!payload.property || payload.property == '') ?? delete payload['property']
    !payload.lease_start_date ?? delete payload['lease_start_date']
    !payload.lease_end_date ?? delete payload['lease_end_date']

    API.put(url, payload)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          return resolve(response.data)
        } else {
          throw `request failed with status ${response.status}`
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

export const getDisplayProperties = async () => {
  try {
    const response = await API.get(API_URLS.DISPLAY_PROPERTIES)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

export const getDisplayPropertyUnits = async (
  propertyId: string,
  excludeAssignedUnits: boolean,
) => {
  try {
    const url = `${API_URLS.DISPLAY_PROPERTY_UNITS}?property_id=${propertyId}${
      excludeAssignedUnits ? '&assigned_unit__active=False' : ''
    }`
    const response = await API.get(`${url}`)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getTenantList = async (property?: string) => {
  try {
    let url = API_URLS.TENANTS_LIST
    if (property) {
      url = `${API_URLS.TENANTS_LIST}?assigned_properties=${property}`
    }
    const response = await API.get(url)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getTenantById = async (id?: string) => {
  try {
    const response = await API.get(`${API_URLS.TENANTS_LIST}${id}/`)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const updatePaymentStatus = async (data: ChangePaymentStatus) => {
  return new Promise((resolve, reject) => {
    API.put(API_URLS.UPDATE_PAYMENT_STATUS, data)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          if (response.data.success) {
            return resolve(response.data)
          } else {
            return reject(response.data)
          }
        } else {
          throw `request failed with status ${response.status}`
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

const buyUnits = async (data: BuyUnitsFormStates) => {
  return new Promise((resolve, reject) => {
    API.post(API_URLS.BUY_UNITS, data)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          if (response.data.success) {
            return resolve(response.data)
          } else {
            return reject(response.data)
          }
        } else {
          throw `request failed with status ${response.status}`
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

const getCreditCardDetail = async () => {
  try {
    const response = await API.get(API_URLS.GET_CREDIT_CARD)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getSubscriptions = async () => {
  try {
    const response = await API.get(API_URLS.GET_SUBSCRIPTION_LIST)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getPaidConnects = async () => {
  try {
    const response = await API.get(API_URLS.GET_PAID_CONNECTS)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getUnitPrice = async () => {
  try {
    const response = await API.get(API_URLS.GET_UNIT_PRICE)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const deleteProperty = async (id: number | null) => {
  return new Promise((resolve, reject) => {
    API.delete(`${API_URLS.PROPERTY}${id}/`)
      .then((response) => {
        if (response.status === 201 || response.status === 200 || response.status === 204) {
          return resolve(response.data)
        } else {
          throw `request failed with status ${response.status}`
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

const deletePropertyUnit = async (id: number | null) => {
  return new Promise((resolve, reject) => {
    API.delete(`${API_URLS.PROPERTY_UNIT}${id}/`)
      .then((response) => {
        if (response.status === 201 || response.status === 200 || response.status === 204) {
          return resolve(response.data)
        } else {
          throw `request failed with status ${response.status}`
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

const deleteTenants = async (data: DeleteMultipleTanants) => {
  return new Promise((resolve, reject) => {
    API.delete(API_URLS.DELETE_MULTIPLE_TENANTS, {
      data: data,
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200 || response.status === 204) {
          return resolve(response.data)
        } else {
          throw `request failed with status ${response.status}`
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

const deleteSavedUploadedImage = async (url: string) => {
  return new Promise((resolve, reject) => {
    API.delete(url)
      .then((response) => {
        if (response.status === 201 || response.status === 200 || response.status === 204) {
          return resolve(response.data)
        } else {
          throw `request failed with status ${response.status}`
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

const getDiscountCouponDetail = async (payload: DiscountCouponDetail) => {
  try {
    const response = await API.post(API_URLS.GET_DISCOUNTED_COUPON_DETAIL, payload)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getStates = async () => {
  try {
    const response = await API.get(API_URLS.GET_STATES)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

export const propertiesService = {
  getPropertyTypes,
  getPropertySubTypes,
  getPropertyList,
  getPropertyById,
  saveProperty,
  savePropertyUnit,
  getUnitsByProperty,
  getAllPropertyUnits,
  getDisplayTenants,
  addTenant,
  getTenantById,
  updatePaymentStatus,
  getDisplayProperties,
  getDisplayPropertyUnits,
  getTenantList,
  getPropertyUnitById,
  buyUnits,
  getCreditCardDetail,
  getSubscriptions,
  getPaidConnects,
  getUnitPrice,
  deleteProperty,
  deletePropertyUnit,
  deleteTenants,
  deleteSavedUploadedImage,
  editProperty,
  editPropertyUnit,
  editTenant,
  getDiscountCouponDetail,
  getStates,
}
