import axios from 'axios';
import { isLoggedIn, getToken } from './userService';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' }
});

API.interceptors.request.use(function (config) {
  return new Promise((resolve) => {
    if(isLoggedIn()) {
      const token = getToken();
      const user = JSON.parse(localStorage.getItem("pmanagement.user") || '{}');
      API.defaults.headers.common["Authorization"] = token;
      config.headers.Authorization =  "Bearer "+token;
      if(user)
        config.headers.Portal = user.portal;
      resolve(config);
    } else {
      // No logged-in user: don't set auth header
      resolve(config);
    }
  })
});

// API.interceptors.response.use(null, (error) => {
//   // handle error
//   // Do something with response error
//   if (error.response) {
//     const { response } = error;
//     const { data } = response;
//     return Promise.reject(data);
//   } else {
//     if (error.message === "Network Error") {
//       return Promise.reject(error.message);
//     } else {
//       return Promise.reject(error);
//     }
//   }
// });

export default API;