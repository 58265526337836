import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: 'black',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: 'black' },
      '::placeholder': { color: '#000' },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: 'black',
    },
  },
  showIcon: true,
}

const CheckoutForm = () => {
  return (
    <>
      <form>
        {/* <div className='checkout-input mt-3'>
          <label className='form-label'>Name on Card</label>
          <FormGroup>
            <Input
              id='title'
              name='Name'
              className='card-element'
              placeholder='Enter Name on Card'
            />
          </FormGroup>
        </div> */}
        <div className='checkout-input'>
          <label className='form-label'>Card Number</label>
          <div className='card-element'>
            <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-lg-3 col-md-4 col-6 checkout-input me-3'>
            <label className='form-label'>Expiry</label>
            <div className='card-element'>
              <CardExpiryElement />
            </div>
          </div>
          <div className='col-lg-3 col-md-4 col-6 checkout-input'>
            <label className='form-label'>CVV</label>
            <div className='card-element'>
              <CardCvcElement />
            </div>
          </div>
        </div>
      </form>
      {/* <Form onSubmit={handleSubmit}>
                <CardElement />
                <button className="btn-pay">Buy Now</button>
            </Form>     */}
    </>
  )
}

export default CheckoutForm
