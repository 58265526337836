import { API_URLS } from '../constants/appConstants'
import API from './api'

const getMaintenanceList = async () => {
  try {
    const response = await API.get(API_URLS.GET_MAINTENANCE_LIST)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const updateMaintenanceTask = async (id: string, type: string) => {
  if (type === 'Complete') {
    const response = await API.get(API_URLS.UPDATE_TASK_AS_COMPLETE.replace('{id}', id))
    if (response.status === 201 || response.status === 200) {
      return response.data.msg
    } else {
      return `request failed with status ${response.status}`
    }
  }
  if (type === 'progress') {
    const response = await API.get(API_URLS.UPDATE_TASK_AS_INPROGRESS.replace('{id}', id))
    if (response.status === 201 || response.status === 200) {
      return response.data.msg
    } else {
      return `request failed with status ${response.status}`
    }
  }
}

const removeSelectedTask = async (id: string) => {
  try {
    const response = await API.delete(API_URLS.REMOVESELECTEDTASK.replace('{id}', id))
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch(err) {
    return err
  }
}

const deleteMultipleTask = async (selectedId: number[]) => {
  try {
    const response = await API.delete(API_URLS.DELETE_MULTIPLE_TASKS, {
      data: { ids: selectedId },
    })
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

export const maintenanceService = {
  getMaintenanceList,
  updateMaintenanceTask,
  removeSelectedTask,
  deleteMultipleTask,
}
