import companyLogo from '../../assets/images/company-logo.png';

import user1 from '../../assets/images/user1.png';
import user2 from '../../assets/images/user2.png';
import user3 from '../../assets/images/user3.png';

const CompanyInfo = () => {
    return (
        <div className="company-info text-white px-4">
            <div className="company-logo">
                <img src={companyLogo} />
            </div>
            <div className="people-joined mt-4">
                <div className="users">
                    <img src={user1} alt="" />
                    <img src={user2} alt="" />
                    <img src={user3} alt="" />
                </div>
                <p>Helping 3k+ people manage their properties, join them now!</p>
            </div>
            <span className='login-title'>One Tool for All Your Properties</span>
            <div className="mt-4 company-content">{`Managing properties has become arguably the most time consuming or expensive effort in the rental property game. We've built a tool to make your rental property effortless all under one roof!`}</div>
        </div>
    )
}

export default CompanyInfo;