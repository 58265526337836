import { useNavigate } from 'react-router-dom'
import backIcon from '../../../assets/images/svgImages/Back.svg'

export const BackButton: React.FC = (): JSX.Element => {
  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  return (
    <div className='back-icon cursor-pointer' onClick={goBack}>
      <img src={backIcon} alt='back-icon' />
    </div>
  )
}
