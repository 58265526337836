import { useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import './App.scss'
import 'react-phone-number-input/style.css'
import 'react-toastify/dist/ReactToastify.css'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './assets/Style/main.scss'

import { ROUTES } from './constants/routesConstant'
import Login from './pages/Login'
import Signup from './pages/Signup'
import PasswordRecovery from './pages/PasswordRecovery'
import SetNewPassword from './pages/SetnewPassword'
import EmailUpdate from './pages/EmailUpdate'
import EmailVerification from './pages/EmailVerification'
import AuthRoutes from './routes/AuthRoutes'
import LandLordMainPage from './components/LandlordMainPage/LandlordMainPage'
import Properties from './pages/Properties'
import Overview from './pages/Overiew'
import Settings from './pages/Settings'
import Maintenance from './pages/Maintenance'
import Tenants from './pages/Tenants'
import Units from './pages/Units'
import NewProperties from './pages/Properties/components/NewProperties'
import PublicRoutes from './routes/PublicRoutes'
import AddUnits from './pages/Units/components/AddUnits'
import AddTenants from './pages/Tenants/components/AddTenants'
import Subscriptions from './pages/Settings/components/Subscriptions/Subscriptions'
import TenantOverview from './pages/TenantOverview/TenantOverview'
import TenantMainPage from './components/TenantMainPage/TenantMainPage'
import TenantMaintenance from './pages/TenantMaintenance/TenantMaintenance'
import SchedulePaymentComponent from './pages/TenantPayments/SchedulePayment'
import PayRentComponent from './pages/TenantPayments/PayRent'
import TenantMaintenanceCreateComponent from './pages/TenantMaintenance/TenantMaintenanceCreate'
import TenantMaintenanceDetailComponent from './pages/TenantMaintenance/TenantMaintenanceDetail'
import ToDoTasks from './pages/ToDo'
import CreateTodoTasks from './pages/ToDo/components/CreateToDoTask'
import BuyNotifications from './pages/ToDo/components/BuyNotifications'
import ChatMainPage from './components/ChatMainPage/ChatMainPage'
import Chat from './pages/Chat'



function App() {
  // const location = useLocation();
  const searchParams = new URLSearchParams(window.location.search)
  const queryParamValue = searchParams.get('uidb64')

  useEffect(() => {
    if (queryParamValue !== '' && queryParamValue !== null && queryParamValue !== undefined) {
      localStorage.clear()
      navigateToTenantSignUp()
    }
  }, [])

  const navigateToTenantSignUp = () => {
    return <Navigate to={ROUTES.TENANT_SIGNUP} />
  }

  return (
    <div className='App'>
      <header className='App-header'></header>
      <ToastContainer
        position='top-center'
        autoClose={2000}
        closeOnClick={true}
        limit={5}
        closeButton={true}
        hideProgressBar={true}
      />
      <BrowserRouter>
        <Routes>
          <Route
            path={ROUTES.LOGIN}
            element={
              <PublicRoutes>
                <Login />
              </PublicRoutes>
            }
          />
          <Route
            path={ROUTES.SIGNUP}
            element={
              <PublicRoutes>
                <Signup />
              </PublicRoutes>
            }
          />

          <Route
            path={ROUTES.TENANT_SIGNUP}
            element={
              <PublicRoutes>
                <Signup />
              </PublicRoutes>
            }
          />
          <Route path={ROUTES.EMAIL_UPDATE} element={<EmailUpdate />} />

          <Route path={ROUTES.PASSWORD_RECOVERY} element={<PasswordRecovery />} />
          <Route path={ROUTES.SET_NEW_PASSWORD} element={<SetNewPassword />} />
          <Route path={ROUTES.EMAIL_VERIFICATION} element={<EmailVerification />} />

          {/* private routes */}
          <Route
            path={ROUTES.LANDLORD_MAIN_PAGE}
            element={
              <AuthRoutes>
                <LandLordMainPage />
              </AuthRoutes>
            }
          >
            <Route path={ROUTES.LANDLORD_MAIN_PAGE} element={<Navigate to={ROUTES.OVERVIEW} />} />
            <Route path={ROUTES.PROPERTIES} element={<Properties />} />
            <Route path={ROUTES.NEW_PROPERTIES} element={<NewProperties />} />
            <Route path={`${ROUTES.EDIT_PROPERTY}/:propertyId`} element={<NewProperties />} />
            <Route path={ROUTES.OVERVIEW} element={<Overview />} />
            <Route path={ROUTES.UNITS} element={<Units />} />
            <Route path={ROUTES.ADD_UNITS} element={<AddUnits />} />
            <Route path={`${ROUTES.EDIT_UNITS}/:unitId`} element={<AddUnits />} />
            <Route path={ROUTES.TENANTS} element={<Tenants />} />
            <Route path={ROUTES.ADD_TENANTS} element={<AddTenants />} />
            <Route path={`${ROUTES.EDIT_TENANT}/:tenantId`} element={<AddTenants />} />
            {/* <Route path={ROUTES.PAYMENTS_OVERVIEW} element={<PaymentsOverview />} /> */}
            <Route path={ROUTES.MAINTENANCE} element={<Maintenance />} />
            <Route path={ROUTES.LANDLORD_SETTINGS} element={<Settings />} />
            <Route path={ROUTES.SUBSCRIPTIONS} element={<Subscriptions />} />
            <Route path={ROUTES.TODO} element={<ToDoTasks />} />
            <Route path={`${ROUTES.EDIT_TODO}/:taskId`} element={<CreateTodoTasks />} />
            <Route path={ROUTES.CREATE_TODO_TASK} element={<CreateTodoTasks />} />
            <Route path={ROUTES.BUY_NOTIFICATIONS} element={<BuyNotifications />} />
          </Route>

          {/* Tenant Routes  */}
          <Route
            path={ROUTES.TENANT_MAIN_PAGE}
            element={
              <AuthRoutes>
                <TenantMainPage />
              </AuthRoutes>
            }
          >
            <Route
              path={ROUTES.TENANT_MAIN_PAGE}
              element={<Navigate to={ROUTES.TENANT_OVERVIEW} />}
            />
            <Route path={ROUTES.TENANT_OVERVIEW} element={<TenantOverview />} />
            {/* <Route path={ ROUTES.TENANT_PAYMENTS } element={ <TenantPayments /> }  /> */}
            <Route path={ROUTES.TENANT_MAINTENANCE} element={<TenantMaintenance />} />
            <Route path={ROUTES.TENANT_PAY_RENT} element={<PayRentComponent />} />
            <Route path={ROUTES.TENANT_SCHEDULE_PAYMENT} element={<SchedulePaymentComponent />} />
            <Route
              path={ROUTES.TENANT_MAINTENANCE_CREATE}
              element={<TenantMaintenanceCreateComponent />}
            />
            <Route
              path={ROUTES.TENANT_MAINTENANCE_DETAIL}
              element={<TenantMaintenanceDetailComponent />}
            />
            {/* <Route path={ ROUTES.TENANT_ADDBANK_DETAILS } element={ <AddTenantBankDetailComponent /> }  /> */}
            <Route path={ROUTES.TENANT_SETTINGS} element={<Settings />} />
            <Route
              path={ROUTES.TENANT_MAINTENANCE_EDIT}
              element={<TenantMaintenanceCreateComponent />}
            />
          </Route>
          <Route
            path={ROUTES.CHAT}
            element={
              <AuthRoutes>
                <ChatMainPage />
              </AuthRoutes>
            }
          >
            <Route path={ROUTES.CHAT} element={<Chat />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
