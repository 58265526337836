import { ReactElement } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { isLoggedIn } from "../helpers/userService";
import { ROUTES } from "../constants/routesConstant";

interface AuthRouteProps {
  children: ReactElement
}

const PublicRoutes = ({ children }: AuthRouteProps) => {
  const location = useLocation();
  const isUserLoggedIn = isLoggedIn();

  return isUserLoggedIn === false ? (
    children
  ) : (
    <Navigate to={ROUTES.LANDLORD_MAIN_PAGE} replace state={{ path: location.pathname }} />
  );
}

export default PublicRoutes;