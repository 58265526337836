import { Button, Modal, ModalBody } from 'reactstrap';


import closeIcon from '../../../assets/images/svgImages/Close.svg';

interface DeleteConfirmModal {
    handleReject: () => void;
    handleAccept: () => void;
    message: string;
    header: string;
    isModalOpen: boolean;
}

const DeleteConfirmModal = (props: DeleteConfirmModal) => {
    const { handleReject, handleAccept, message, header, isModalOpen } = props;
    return (
        <>
            <Modal
                size='md'
                centered
                className='common-alert-modal'
                isOpen={isModalOpen}
                toggle={handleReject}
            >
                <div className='modal-header-container justify-content-end'>
                    <div className='btn flat' onClick={handleReject}>
                        <img src={closeIcon} />
                    </div>
                </div>
                <ModalBody className='common-body-container delete-modal'>
                    <div className='modal-body-content pb-5'>
                        <h3 className='heading-text'>{header}</h3>
                        <p>{message}</p>
                    </div>
                    <div className='modal-button '>
                        <Button className='btn cta-warning me-2' onClick={handleAccept}>Delete</Button>
                        <Button className='btn simple' onClick={handleReject}>Cancel</Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default DeleteConfirmModal