import { useEffect, useState } from 'react'

import { Card, CardBody, FormGroup, Input } from 'reactstrap'
import { Button } from 'primereact/button'
import { setActivePageHeader } from '../../../../store/auth/authSlice'
import { useAppDispatch } from '../../../../hooks/storeHook'
import lockIcon from '../../../../assets/images/svgImages/lock_icon.svg'
import arrowRightSquare from '../../../../assets/images/svgImages/arrow_right_square.svg'
import inboxIcon from '../../../../assets/images/svgImages/inbox_icon.svg'
import cardIcon from '../../../../assets/images/card_icon.png'

import selectedPeriodIcon from '../../../../assets/images/svgImages/round_tick_icon.svg'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js'
import CheckoutForm from '../../../../components/CheckoutForm/CheckoutForm'
import { propertiesService } from '../../../../helpers/propertiesService'
import axios from 'axios'
import { ErrorToast, SuccessToast } from '../../../../helpers/toster'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../../constants/routesConstant'
import { CardDetail } from '../../../../types/properties'
import { getUser } from '../../../../helpers/userService'

const initialCardDetails = {
  card_id: '',
  cc_last4: '',
  exp_month: '',
  exp_year: '',
  landlord: null,
}
export interface OrderSummaryProps {
  unitQuantity: number
  unitPrice: number
  planBillType: string
}

const stripe = loadStripe(
  process.env.REACT_APP_STRIPE_API_KEY ? process.env.REACT_APP_STRIPE_API_KEY : '',
)

const OrderSummaryComponent = (props: OrderSummaryProps) => {
  const { unitQuantity, unitPrice, planBillType } = props
  const navigate = useNavigate()
  const userData = getUser()

  const stripe = useStripe()
  const elements = useElements()
  const [subTotal] = useState(unitQuantity * unitPrice * (planBillType === 'Y' ? 12 : 1))
  const [cardDetails, setCardDetails] = useState<CardDetail>(initialCardDetails)
  const [isPaymentLoading, setisPaymentLoading] = useState(false)
  const [isDiscountCodeLoading, setisDiscountCodeLoading] = useState(false)
  const [discountInput, setDiscountInput] = useState('')
  const [discountCodeError, setDiscountCodeError] = useState('')
  const [discountAmount, setDiscountAmount] = useState(0)

  useEffect(() => {
    getCreditCardData()
  }, [])

  const handleSubmit = async () => {
    if (cardDetails.card_id !== '') {
      setisPaymentLoading(true)
      handleCheckout()
      return
    }
    if (!stripe && !elements) {
      return
    }
    const card = elements?.getElement(CardNumberElement)
    if (card) {
      setisPaymentLoading(true)
      try {
        const result = await stripe?.createToken(card)
        if (result?.error) {
          setisPaymentLoading(false)
          ErrorToast(result?.error?.message || 'Error in payment')
        } else {
          setisPaymentLoading(false)
          handleCheckout(result?.token?.id || '')
        }
      } catch (err) {
        setisPaymentLoading(false)
        ErrorToast('Error in payment')
      }
    }


    // const {error, paymentMethod} = await stripe?.createPaymentMethod({
    //     type: "card",
    //     card: elements!.getElement(CardNumberElement)
    // });
  }

  const handleCheckout = async (token?: string) => {
    const formData = {
      cc_token: cardDetails.card_id !== '' ? undefined : token,
      subscription_plan: planBillType,
      paid_connects: unitQuantity,
      code: Number(discountAmount) > 0 && !!discountInput === true ? discountInput : '',
    }
    try {
      await propertiesService.buyUnits(formData)
      SuccessToast('Payment Success')
      setisPaymentLoading(false)
      navigate(ROUTES.TENANTS)
    } catch (err) {
      if (axios.isAxiosError(err)) {
        setisPaymentLoading(false)
        if (err.response?.data) {
          ErrorToast(err.response?.data?.msg || 'Error in payment')
        } else {
          ErrorToast('Error in payment')
        }
      } else {
        setisPaymentLoading(false)
        ErrorToast('Error in payment')
      }
    }
  }

  const getCreditCardData = async () => {
    const response = await propertiesService.getCreditCardDetail()
    if (response) {
      setCardDetails(response)
    } else {
      setCardDetails(initialCardDetails)
    }
  }

  return (
    <Card className='propertyM-card order-summary-wrapper'>
      <CardBody className='px-md-5'>
        <div className='row'>
          <div className='col-md-6 pe-lg-5'>
            <div className='heading-text mb-1'>Checkout</div>
            <div className='sub-heading-text mb-1'>Fill in your purchase details</div>
            <div className='heading-text mb-1 mt-5'>Billing period</div>

            <div className='plan-period mt-4'>
              <div
                className={
                  planBillType === 'Y'
                    ? 'yearly-plan selected-plan d-flex align-items-center'
                    : 'yearly-plan unselected-plan d-flex align-items-center'
                }
              >
                {planBillType === 'Y' ? (
                  <img src={selectedPeriodIcon} />
                ) : (
                  <div className='plan-unselected-circle'></div>
                )}
                <div className='plan-period-text ms-3'>Yearly</div>
              </div>
              <div
                className={
                  planBillType === 'M'
                    ? 'monthly-plan selected-plan d-flex align-items-center'
                    : 'monthly-plan unselected-plan d-flex align-items-center'
                }
              >
                {planBillType === 'M' ? (
                  <img src={selectedPeriodIcon} />
                ) : (
                  <div className='plan-unselected-circle'></div>
                )}
                <div className='plan-period-text ms-3'>Monthly</div>
              </div>
            </div>
            {cardDetails.card_id !== '' ? (
              <div className='card-details-section mt-5'>
                <div className='heading-text me-2'>Payment method</div>
                <div className='sub-heading-text mb-3'>Change your payment method</div>
                <div className='card-details w-100'>
                  <div className='d-flex align-items-start'>
                    <div className='px-2 py-1'>
                      <img src={cardIcon} />
                    </div>
                    <div className='mx-2 px-2 py-1'>
                      <div className='card-number'>
                        ****{'  '}****{'  '}****{'  '}
                        {cardDetails.cc_last4}
                      </div>
                      <div className='card-exp-date mt-1'>
                        {cardDetails.exp_month} / {cardDetails.exp_year}
                      </div>
                      <div className='card-exp-date mt-1'>
                        <img className='me-2' src={inboxIcon} />
                        {userData?.email}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className='d-flex align-items-end mt-5'>
                  <div className='heading-text me-2'>Payment Info</div>
                  <div className='cursor-pointer'>
                    <img src={lockIcon} />
                  </div>
                </div>
                <CheckoutForm />
              </>
            )}
          </div>

          <div className='payment-info col-md-6 ps-lg-5'>
            <div className='d-flex align-items-center w-100'>
              <div className='summary-block-text selected-plan'>
                Your plan: <span className='fw-semibold'>Custom</span>
              </div>
              <div className='summary-block-text plan-price ms-auto'>${unitPrice}</div>
            </div>
            <div className='d-flex align-items-center mt-2 w-100'>
              <div className='summary-block-text selected-plan'>Period</div>
              <div className='summary-block-text plan-price ms-auto selected-plan'>
                {planBillType === 'Y' ? 'Yearly' : 'Monthly'} (${unitPrice + 'x' + unitQuantity})
              </div>
            </div>
            <div className='mt-4 pb-1'>
              <div className='row mb-4'>
                <div className='col-md-8 mb-md-0 mb-3'>
                  <FormGroup className='mb-0'>
                    <Input
                      className='py-2'
                      id='discount'
                      name='discount'
                      placeholder='Discount code'
                      value={discountInput}
                      onChange={(e) => setDiscountInput(e.target.value)}
                      onBlur={async (event: React.ChangeEvent<HTMLInputElement>) => {
                        if (event.target.value === '') {
                          setDiscountCodeError('')
                          setDiscountAmount(0)
                        }
                      }}
                      readOnly={Number(discountAmount) > 0 && !!discountInput === true}
                    />
                    {discountCodeError && discountCodeError !== null ? (
                      <span className='error-text text-danger'>{discountCodeError}</span>
                    ) : discountAmount && Number(discountAmount) > 0 && !!discountInput === true ? (
                      <span className='success-text'>{discountInput} discount code is applied</span>
                    ) : (
                      ''
                    )}
                  </FormGroup>
                </div>
                <div className='col-md-4 ml-md-3 ml-0'>
                  {discountAmount && Number(discountAmount) > 0 && !!discountInput === true ? (
                    <Button
                      className='apply-coupon-now-btn w-100'
                      onClick={() => {
                        setDiscountCodeError('')
                        setDiscountInput('')
                        setDiscountAmount(0)
                      }}
                    >
                      <span>Remove Code</span>
                    </Button>
                  ) : (
                    <Button
                      loading={isDiscountCodeLoading}
                      loadingIcon='pi pi-spin pi-sun'
                      className='apply-coupon-now-btn w-100'
                      onClick={async () => {
                        if (discountInput !== '') {
                          setisDiscountCodeLoading(true)
                          const result = await propertiesService.getDiscountCouponDetail({
                            code: discountInput,
                          })
                          if (result?.success) {
                            setDiscountCodeError('')
                            const discountedAmount =
                              (Number(subTotal) * Number(result?.discount_percentage)) / 100
                            setDiscountAmount(discountedAmount)
                            setisDiscountCodeLoading(false)
                          } else {
                            ErrorToast(result?.msg || 'Error in get dicount detail')
                            setDiscountCodeError(result?.msg || 'Error in get dicount detail')
                            setDiscountAmount(0)
                            setisDiscountCodeLoading(false)
                          }
                        }
                      }}
                    >
                      <span>Apply Code</span>
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <hr />
            <div className='d-flex align-items-center w-100 pt-3'>
              <div className='plan-summary-text'>Subtotal</div>
              <div className='plan-summary-text ms-auto'>${subTotal.toFixed(2)}</div>
            </div>
            <div className='d-flex align-items-center w-100 mt-2 pb-3'>
              <div className='plan-summary-text'>Discount</div>
              <div className='plan-summary-text ms-auto'>${discountAmount.toFixed(2)}</div>
            </div>
            <hr />
            <div className='d-flex align-items-center w-100 pt-4'>
              <div className='total-amount-text'>Total amount:</div>
              <div className='total-amount ms-auto'>
                ${(Number(subTotal) - Number(discountAmount)).toFixed(2)}
              </div>
            </div>
            <div className='mt-5 mb-2'>
              <Button
                icon="pi pi-spin pi-spinner-dotted"
                loading={isPaymentLoading}
                className='btn cta'
                onClick={handleSubmit}
              >
                Pay Now
              </Button>
            </div>
            <div className='text-center'>
              <img className='me-1' width={14} height={14} src={lockIcon} />
              <span className='payment-secure-text'>Payments are secure</span>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

const OrderSummary = (props: OrderSummaryProps) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setActivePageHeader('Order Summary'))
  }, [])

  return (
    <Elements stripe={stripe}>
      <OrderSummaryComponent {...props} />
    </Elements>
  )
}

export default OrderSummary
