import { useState, ReactElement, useEffect } from 'react'
import { Button } from "primereact/button";
import plus from '../../../assets/images/svgImages/plus.svg'
import minus from '../../../assets/images/svgImages/minus.svg'
import radioSelected from '../../../assets/images/svgImages/radio_selected.svg'
import radioUnselected from '../../../assets/images/svgImages/radio_unselected.svg'

interface NotificationCardInfo {
  title?: string
  price?: string
  description?: string
  value: string
  id: string
  onChecked?: any
  children?: ReactElement<any> | boolean
}

interface NotificationQuantityControllerInfo {
  text?: string
  initialValue: number
  increaseValue: number
  onChange: any
}

export const NotificationCard = (props: NotificationCardInfo) => {
  const [isChecked, setIsChecked] = useState(false)
  const handleCheck = (e: any) => {
    props.onChecked(e)
  }

  useEffect(() => {
    const targetData: any = document.getElementById(props.id)
    setIsChecked(targetData.checked)
  })
  return (
    <div className={isChecked ? 'notification-plan checked' : 'notification-plan'}>
      <label htmlFor={props.id}>
        <div className='heading'>
          <div className='title'>
            <input
              type='radio'
              name='notification-plan'
              id={props.id}
              onChange={(e) => handleCheck(e)}
              value={props.value}
            />
            <img src={isChecked ? radioSelected : radioUnselected} />
            {props.title}
          </div>
          {props.price && (
            <div className='price'>
              $ {props.price}
              <span className='subtext'> / Notification</span>
            </div>
          )}
        </div>
        <div className='description mt-3'>{props.description}</div>
      </label>
      {isChecked && props.children}
    </div>
  )
}

export const NotificationQuantityController = (props: NotificationQuantityControllerInfo) => {
  const [quantity, setQuantity] = useState(props.initialValue ? props.initialValue : 1)

  const increment = () => {
    const increase = props.increaseValue ? props.increaseValue : 1
    const value = quantity + increase
    setQuantity(value)
    props.onChange(value)
  }

  const decrement = () => {
    const increase = props.increaseValue ? props.increaseValue : 1
    const value = quantity - increase
    setQuantity(value)
    props.onChange(value)
  }

  return (
    <div className='quantity-controller-wrapper'>
      <span>{props.text}</span>
      <div className='quantity-controller'>
        <Button onClick={decrement} disabled={quantity <= props.increaseValue ? true : false}>
          <img src={minus} />
        </Button>
        <span>{quantity}</span>
        <Button onClick={increment}>
          <img src={plus} />
        </Button>
      </div>
    </div>
  )
}
