import { TenantBankAccount, TenantMaintenanceListData, TenantPaymentList } from './../../types/tenantDetails';
import { createSlice, Dispatch } from '@reduxjs/toolkit';

interface TenantDetailStates {
    overviewPaymentList: TenantPaymentList[],
    selectedPaymentDetail: TenantPaymentList | null,
    totalListCount: number
    currentActivatedBankAccount: TenantBankAccount
    tenantMaintenanceList: TenantMaintenanceListData[]
    selectedTenantMaintenance: TenantMaintenanceListData
    totalMaintenanceListCout: number
}

const initialState: TenantDetailStates = {
    overviewPaymentList: [],
    selectedPaymentDetail: null,
    totalListCount: 0,
    currentActivatedBankAccount: {} as TenantBankAccount,
    tenantMaintenanceList: [ ],
    selectedTenantMaintenance: {
        assigned_unit_name: '',
        assignee_name: '',
        created:'',
        description: '',
        id: 0,
        images: [],
        property_address: '',
        property_title: '',
        status: '',
        status_name: '',
        task_type: '',
        type: 0,
        title:'',
        unit: 0,
    },
    totalMaintenanceListCout: 0
}

const tenantSlice = createSlice({
    name: 'tenant',
    initialState,
    reducers: {
        setOverviewPaymentList: (state, action) => {
            state = { ...state, overviewPaymentList: action.payload.overViewList, totalListCount: action.payload.totalListCount }
            return state;
        },
        selectedPaymentDetail: (state, action) => {
            state = { ...state, selectedPaymentDetail: action.payload }
            return state;
        },
        setTenantMaintenanceListData: ( state, action ) => {
            state = { ...state, tenantMaintenanceList: action.payload.maintenanceList, totalMaintenanceListCout: action.payload.totalCount }
            return state;
        },
        setSelectedTenantMaintenance: ( state, action ) => {
            state = { ...state, selectedTenantMaintenance: action.payload }
            return state
        },
        setTenantBankAccount:( state, action ) => {
            state = { ...state, currentActivatedBankAccount: action.payload }
            return state
    },
}});

const { setOverviewPaymentList, selectedPaymentDetail, setTenantMaintenanceListData, setSelectedTenantMaintenance, setTenantBankAccount  } = tenantSlice.actions;

export const saveOverviewPaymentList = (overViewList: TenantPaymentList[], totalListCount: number) => async (dispatch: Dispatch) => {
    return dispatch(setOverviewPaymentList({overViewList, totalListCount}));
}

export const setSelectedOverview = (overViewPaymentData: TenantPaymentList) => async (dispatch: Dispatch) => {
    return dispatch(selectedPaymentDetail(overViewPaymentData));
}

export const setMaintenanceListData = (maintenanceList: TenantMaintenanceListData[], totalCount: number) => async (dispatch: Dispatch) => {
    return dispatch(setTenantMaintenanceListData({maintenanceList, totalCount}));
}

export const setTenantSelectedMaintenance = (selectedMaintenance: TenantMaintenanceListData) => async (dispatch: Dispatch) => {
    return dispatch(setSelectedTenantMaintenance(selectedMaintenance));
}


export const setTenantBankDetails = ( bankDetails: TenantBankAccount ) => async (dispatch: Dispatch) => {
    return dispatch(setTenantBankAccount(bankDetails));
}


export default tenantSlice.reducer;