import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHook'
import { setActivePageHeader } from '../../store/auth/authSlice'
import { overviewService } from '../../helpers/overviewService'
import grossAmountIcon from '../../assets/images/svgImages/gross_amt_icon.svg'
import receiptIcon from '../../assets/images/svgImages/receipt_icon.svg'
import maintenanceIcon from '../../assets/images/svgImages/maintenance_overview_ic.svg'
import propertiesIcon from '../../assets/images/svgImages/properties_icon.svg'
import tenantsIcon from '../../assets/images/svgImages/tenants_overview_ic.svg'
import {  Card, CardBody, Col, Row } from 'reactstrap'
import { OverviewDetailCard } from '../../components/common/OverviewDetailCard/OverviewDetailCard'
import NoListingView from '../../components/common/NoListingView/NoListingView'
import { DashboardMetricsData } from '../../types/overview'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../constants/routesConstant'
import { getCurrentMonthName } from '../../helpers/utils'
import PaymentHistoryList from '../../components/PaymentHistoryList'
import { ErrorToast } from '../../helpers/toster'
import axios from 'axios'
import { savePaymentHistoryList } from '../../store/overview/overviewSlice'

const Overview = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { paymentHistoryList, totalListCount } = useAppSelector((state) => state.overViewReducer)
  const [ dasboardMetricsData, setDasboardMetrics ] = useState({} as DashboardMetricsData)

  useEffect(() => {
    dispatch(setActivePageHeader('Overview'));
    getPaymentHistoryListData();
    getMetricsDashboardData();
  }, [])


  const getPaymentHistoryListData = async () => {
    try {
      const response = await overviewService.gePaymentHistoryList();
      dispatch(savePaymentHistoryList(response.results, response.count))
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.data?.detail) {
          ErrorToast(err.response?.data?.detail || 'Error in get Payment History List')
        } else {
          ErrorToast('Error in get Payment History List')
        }
      }
    }
  }

  const getMetricsDashboardData = async () =>{
    const response = await overviewService.getMetricsDashboardData()
    setDasboardMetrics(response)
  }

  return (
    <div className='p-4'>
      <Row className='pb-4'>
        {/* <Col md={3}>
          <Card className='overviewCard h-100'>
            <div className='d-flex justify-content-between align-items-center h-100'>
              <CardBody>
                <div className='d-flex align-items-center justify-content-between'>
                  <div className='card-details'>
                    <div className='heading'>{`$${dasboardMetricsData?.total_available_balance?.available[0]?.amount || 0}`}</div>
                    <div className='subHeading'>{'Available Balance'}</div>
                  </div>
                  <div className='paymentCard'>
                    <div className='paymentCardDetails'>{<img src={grossAmountIcon} alt='' />}</div>
                  </div>
                </div>
              </CardBody>
            </div>  
          </Card>
        </Col>
        <Col md={3}>
          <Card className='overviewCard h-100'>
            <div className='d-flex justify-content-between align-items-center h-100'>
              <CardBody>
                <div className='d-flex align-items-center justify-content-between'>
                  <div className='card-details'>
                    <div className='overview-stripe heading'>{`$${dasboardMetricsData?.total_available_balance?.available[0]?.amount || 0}`}</div>
                    <div className='overview-stripe subHeading'>Banking transaction fees <br />this month</div>
                  </div>
                  <div className='paymentCard'>
                    <div className='paymentCardDetails'>{<img src={receiptIcon} alt='' />}</div>
                  </div>
                </div>
              </CardBody>
            </div>
          </Card>
        </Col> */}
        {/* <Col md={3}> */}
          {/* <OverviewDetailChartCard heading={`${dasboardMetricsData?.monthly_total_rent ? '$' + dasboardMetricsData?.monthly_total_rent : 'N/A' }`} subHeading={`${getCurrentMonthName()} Payment`} paidPayment={dasboardMetricsData?.monthly_collect_rent || 0} duePayment={dasboardMetricsData?.monthly_total_rent - (dasboardMetricsData?.monthly_collect_rent || 0)} ></OverviewDetailChartCard> */}
          {/* <OverviewDetailCard 
            heading={`${dasboardMetricsData?.monthly_total_rent ? '$' + dasboardMetricsData?.monthly_total_rent : 'N/A' }`} subHeading={`${getCurrentMonthName()} Payment`} 
            isChart={true} 
            doughnutChartData = {{
              color1: "#02AD88",
              color2: "#E1E1E1",
              borderWidth: 0,
              borderRadius: 3,
              cutout: '65%',
              chartData: [dasboardMetricsData?.monthly_collect_rent || 0, (dasboardMetricsData?.monthly_total_rent - (dasboardMetricsData?.monthly_collect_rent || 0))],
              chartLabel: `${getCurrentMonthName()}`
            }}
          /> */}
        {/* </Col> */}
        {/* <Col md={3}>
          <Card className='add-unit-connect-card h-100 cursor-pointer' onClick={() => navigate(ROUTES.SUBSCRIPTIONS)}>
            <CardBody className='p-4'>
              <div className='d-flex align-items-center justify-content-center h-100'>
                <div className='card-details'>
                  <div className='addBankHeading'>{'Buy Unit Connects'}</div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col> */}
      </Row>
      <Row className='pb-4'>
        <Col md={3}>
          <OverviewDetailCard heading={`${dasboardMetricsData?.total_properties || 0}`} subHeading='Total Properties'  icon={propertiesIcon} ></OverviewDetailCard>
        </Col>
        <Col md={3}>
          <OverviewDetailCard heading={`${dasboardMetricsData?.total_tenants || 0}`} subHeading='Total Tenants'  icon={tenantsIcon} ></OverviewDetailCard>
        </Col>
        <Col md={3}>
          <OverviewDetailCard heading={`${dasboardMetricsData?.total_empty_units || 0}`} subHeading='Empty Units'  icon={tenantsIcon} ></OverviewDetailCard>
        </Col>
        <Col md={3}>
          <OverviewDetailCard heading={`${dasboardMetricsData?.total_maintenance_tasks || 0}`} subHeading='Maintenance Tasks' icon={maintenanceIcon} ></OverviewDetailCard>
        </Col>
      </Row>
      
      {/* hide payments history data for now */}
      {/* {paymentHistoryList?.length ? <PaymentHistoryList paymentHistoryList= { paymentHistoryList } totalPaymentHistoryCount={ totalListCount } getPaymentHistoryData={getPaymentHistoryListData} /> :
        <NoListingView noPropText="You Do Not Have Any Payment History" addPropText="Please Check" />
      } */}
      <NoListingView noPropText="You Do Not Have Any Payment History" addPropText="Please Check" />
    </div>
  )
}

export default Overview
