import { forwardRef } from 'react'
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import moreVerticleIcon from '../../../assets/images/svgImages/more_vertical_icon.svg'

interface ComponentProps {
  content: Array<any>
  onClick: (e: any) => void
}

const Component = forwardRef(function Dropdown(props: ComponentProps, ref: any,) {
  return <>
    <Button className='btn flat' type='button' onClick={(e) => props.onClick(e)}>
      <img src={moreVerticleIcon} />
    </Button>
    <OverlayPanel className='dropdown-list' ref={ref}>
      {props.content?.map((child: any, index: number) => {
        return <div className={`dropdown-item ${child.className}`} onClick={child.onClick} key={index}>{child.text}</div>
      })
      }
    </OverlayPanel>
  </>
})

export default Component