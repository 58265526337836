import React, { useState, ChangeEvent, useEffect } from 'react'

import { Button } from "primereact/button";
import { FormGroup, Input, Label } from 'reactstrap'
import { Formik, Form } from 'formik'
import {
  TenantMaintenanceCreateRequest,
  TenantMaintenanceListData,
} from '../../types/tenantDetails'
import UploadImages from '../../components/common/UploadImages/UploadImages'
import { ErrorToast, SuccessToast } from '../../helpers/toster'
import { tenantService } from '../../helpers/tenantService'
import { ROUTES } from '../../constants/routesConstant'
import { useAppDispatch } from '../../hooks/storeHook'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import * as Yup from 'yup'
import { setActivePageHeader } from '../../store/auth/authSlice'

let initialValues: TenantMaintenanceCreateRequest = {
  title: '',
  description: '',
}

const validatonSchema = () =>
  Yup.object().shape({
    title: Yup.string().required('Title is required*'),
    description: Yup.string().required('Description is required*'),
  })

const TenantMaintenanceCreateComponent = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [isLoaded, SetIsloaded] = useState(false)
  const [isEdit, setMaintenanceIsEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [files, setFiles] = useState<File[]>([])

  useEffect(() => {
    dispatch(setActivePageHeader('New Maintenance Task'))
    MaintenanceEdit()
  }, [])

  // eslint-disable-next-line
  const handleSubmit = async (formValues: any) => {
    setIsLoading(true)
    if (files.length === 0) {
      ErrorToast('Please upload image')
      setIsLoading(false)
      return false
    }

    const formdata = new FormData()
    const objKeys = Object.keys(formValues) as Array<keyof TenantMaintenanceCreateRequest>

    objKeys.forEach((key) => {
      if (Array.isArray(formValues[key as keyof TenantMaintenanceCreateRequest])) {
        formValues[key as keyof TenantMaintenanceCreateRequest].forEach((arr_item: string) => {
          formdata.append(key, arr_item)
        })
      } else {
        formdata.append(key, formValues[key])
      }
    })

    files.forEach((_file, index) => {
      formdata.append(`images[${index}]image`, files[index])
    })

    if (!isEdit) {
      try {
        const response = await tenantService.createTenantMaintenanceTask(formdata)
        if (response.id) {
          SuccessToast('Task created Successfully')
          setIsLoading(false)
          navigate(ROUTES.TENANT_MAINTENANCE)
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const error = err.response?.data
          for (const key in error) {
            ErrorToast(error[key].toString())
          }
        } else {
          ErrorToast('Error in add new Maintenance')
        }
        setIsLoading(false)
      }
    } else {
      const maintenanceId = new URLSearchParams(window.location.search).get('id')
      try {
        const response = await tenantService.updateTenantMaintenance(
          formdata,
          maintenanceId as string,
        )
        if (response.id) {
          SuccessToast('Task Updated Successfully')
          navigate(ROUTES.TENANT_MAINTENANCE)
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response?.data?.detail) {
            ErrorToast(err.response?.data?.detail || 'Error in updating Maintenance')
          } else {
            ErrorToast('Error in updating Maintenance')
          }
        }
      }
    }
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    const filesData = target.files ? Array.from(target.files) : []
    if (filesData.length) {
      let uploadFiles = files.slice()
      uploadFiles = uploadFiles.concat(filesData)
      if (uploadFiles.length > 5) {
        ErrorToast('Maximum 5 files can be uploaded')
        return false
      } else {
        setFiles(uploadFiles)
      }
    }
  }

  const handleFileDelete = (index: number) => {
    const uploadFiles = files.slice()
    uploadFiles.splice(index, 1)
    setFiles(uploadFiles)
  }

  const MaintenanceEdit = async () => {
    const route = location.pathname
    if (route.includes('edit')) {
      const searchParams = new URLSearchParams(window.location.search)
      const maintenanceId = searchParams.get('id')
      await tenantService
        .getMaintenanceById(maintenanceId as string)
        .then((res: TenantMaintenanceListData) => {
          const newValue = {
            title: res?.title,
            description: res?.description,
          }
          if (res?.id) {
            SetIsloaded(true)
            initialValues = newValue
            //  setFiles(res?.images)
          }
          setMaintenanceIsEdit(true)
        })
        .catch((err) => {
          console.error(err)
        })
    } else {
      SetIsloaded(true)
      setMaintenanceIsEdit(false)
      initialValues = {
        title: '',
        description: '',
      }
    }
  }

  const onCancelCreation = () => {
    navigate(ROUTES.TENANT_MAINTENANCE)
  }

  return (
    <>
      {isLoaded && (
        <div className='col-12 col-sm-10'>
          <h2 className="title mb-5">{isEdit ? "Edit a task" : "Create a task"}</h2>
          <Formik
            initialValues={initialValues}
            onSubmit={(values: TenantMaintenanceCreateRequest) => handleSubmit(values)}
            validationSchema={() => validatonSchema()}
          >
            {({ errors, values, touched, handleBlur, handleChange, isValid }) => (
              <Form>
                <FormGroup className='mb-4'>
                  <Label for='title'>Task Name<span className="required">*</span></Label>
                  <Input
                    id='title'
                    name='title'
                    placeholder='Enter Task name'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                  />
                  {touched.title && errors.title && (
                    <span className='error-text text-danger'>{errors.title}</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for='description'>Description<span className="required">*</span></Label>
                  <Input
                    id='description'
                    name='description'
                    type='textarea'
                    placeholder='Enter Description'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    maxLength={400}
                  />
                  {touched.description && errors.description ? (
                    <span className='error-text text-danger'>{errors.description}</span>
                  ) : (
                    <span className="input-group-caption">Maximum of 400 characters</span>
                  )}
                </FormGroup>
                <FormGroup className='upload-image-section pt-2'>
                  <Label for='uploadImage'>Upload Images of your Property<span className="required">*</span></Label>
                  <UploadImages
                    files={files}
                    handleFileChange={handleFileChange}
                    handleFileDelete={handleFileDelete}
                  />
                </FormGroup>
                <div className="submit-wrapper">
                  <Button type='submit' className='btn cta' icon="pi pi-loading"
                    loading={isLoading} disabled={!isValid}>
                    Save Task
                  </Button>
                  <Button className='btn simple' onClick={() => onCancelCreation()}>
                    Cancel
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  )
}

export default TenantMaintenanceCreateComponent
