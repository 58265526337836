import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'
import { useRef } from "react";
import { Images } from "../../../types/properties";

interface ImageGalleryStates {
    imagesData: Images[]
}

const ImageGallery = ({ imagesData }: ImageGalleryStates) => {
    const slider = useRef<Slider>(null);
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1
    };

    return (
        <div className="gallery">
            {imagesData.length ?
                <>
                    <p className="detail">Gallery</p>
                    {imagesData.length > 3 && <div> <i onClick={() => slider?.current?.slickNext()} className="next-btn pi pi-angle-right" /></div>}
                    <Slider ref={slider} {...settings}>
                        {imagesData.map((image, index) => (
                            <div key={index} className="slide">
                                <img className="rounded" src={image.image} />
                            </div>
                        ))}
                    </Slider>
                </> : ''
            }
        </div>
    )
}

export default ImageGallery