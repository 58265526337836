import { useEffect, useState } from 'react'

import { setActivePageHeader } from '../../store/auth/authSlice'
import { useAppDispatch } from '../../hooks/storeHook'
import { chatServices } from '../../helpers/chatService'
import NewChatModal from '../../components/common/NewChatModal/NewChatModal'
import Chats from './components/chats'
import NoListingView from '../../components/common/NoListingView/NoListingView'

import noChatImage from '../../assets/images/svgImages/no-list-chat.svg'

const Chat = () => {
  const dispatch = useAppDispatch()
  const [showNewChatModal, setShowNewChatModal] = useState(false)
  const [chatList, setChatList] = useState([])
  const [recipientUser, setRecipientUser] = useState(0)

  useEffect(() => {
    dispatch(setActivePageHeader('Chat'))
    getChatList()
  }, [])

  const getChatList = async () => {
    const response = await chatServices.getChatList()
    setChatList(response)
  }

  return (
    <>
      {chatList.length > 0 || recipientUser !== 0 ? (
        <Chats recipientId={recipientUser} firstChat={recipientUser ? true : false} />
      ) : (
        <NoListingView
          image={noChatImage}
          noPropText='No chats here yet'
          addPropText='Start a conversation to begin communication'
          onClick={() => setShowNewChatModal(true)}
          buttonText='New chat'
        />
      )}

      {showNewChatModal && (
        <NewChatModal
          isModalOpen={showNewChatModal}
          handleClose={() => {
            setShowNewChatModal(false)
          }}
          bottonText='Go Back'
          handleButtonAction={(value) => {
            setShowNewChatModal(false)
            setRecipientUser(value)
          }}
        />
      )}
    </>
  )
}

const ChatView = () => {
  return <Chat />
}

export default ChatView
