import { ReactElement } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { isLoggedIn } from "../helpers/userService";

interface AuthRouteProps {
  children: ReactElement
}

const AuthRoutes = ({ children }: AuthRouteProps) => {
  const location = useLocation();
  const isUserLoggedIn = isLoggedIn();

  return isUserLoggedIn === true ? (
    children
  ) : (
    <Navigate to="/" replace state={{ path: location.pathname }} />
  );
}

export default AuthRoutes;