import { Outlet } from 'react-router-dom'
import Header from '../../layout/Header'
import SidebarLayout from '../../layout/Sidebar'

const LandLordMainPage = () => {
  return (
    <div className='container-fluid main-page p-0'>
      <div className='sidebar-wrapper'>
        <SidebarLayout />
      </div>
      <div className='page-right-content'>
        <Header />
        <div className='main-page-content p-4 containers'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default LandLordMainPage
