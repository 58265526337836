import React from 'react'
import { Card, CardBody, CardFooter } from 'reactstrap'
import cardIcon from '../../../assets/images/svgImages/account-card.svg'

export interface AccountCardProps {
  heading: string
  lastDigiit?: string
  isCard: boolean
  cardId?: string
  created?: string
  expMonth?: string
  expYear?: string
  id?: number
  landlord?: number
  holderName?: string
}

const AccountCard = (props: { data: AccountCardProps; isEdit: (status: boolean) => void }) => {
  const currentCard = { ...props.data }

  const editCard = async () => {
    if (!props.data.isCard) {
      props.isEdit(true)
    }
  }

  return (
    <Card className={currentCard?.isCard ? 'account-card active' : 'account-card'}>
      <CardBody>
        <div className='account-header d-flex justify-content-between align-items-center'>
          <p className='card-heading m-0'>{currentCard?.heading}</p>
          <img src={cardIcon} alt='account-card-image' />
        </div>
        <div className='card-number d-flex align-items-center pt-5 pb-4'>
          <div>* * * *</div>
          <div>* * * *</div>
          <div>* * * *</div>
          <div>{currentCard?.lastDigiit}</div>
        </div>

        <div className='edit-button'>
          <span
            className='edit-text border rounded'
            onClick={() => {
              editCard()
            }}
          >
            {currentCard?.isCard ? 'Remove Card' : 'Edit'}
          </span>
        </div>
      </CardBody>
      <CardFooter className='px-4 py-3'>
        <div className='d-flex justify-content-between px-2'>
          <div>
            <p className='info m-0'>{currentCard.isCard ? 'VALID UNTIL' : 'Email'}</p>
            {currentCard?.isCard && (
              <p className='m-0'>{`${currentCard?.expMonth}/${currentCard?.expYear}`}</p>
            )}
            {!currentCard?.isCard && (
              <p className='m-0'>{currentCard?.expMonth ? `${currentCard?.expMonth}` : 'N/A'}</p>
            )}
          </div>

          {currentCard?.holderName && (
            <div>
              <p className='info m-0'>{currentCard?.holderName ? 'NAME' : ''}</p>
              <p className='m-0'>{currentCard?.holderName ? currentCard?.holderName : ''}</p>
            </div>
          )}
        </div>
      </CardFooter>
    </Card>
  )
}

export default AccountCard
