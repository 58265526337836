import { Outlet } from 'react-router-dom'
import Header from '../../layout/Header'
import Sidebar from '../../layout/Sidebar'

const ChatMainPage = () => {
  return (
    <div className='container-fluid main-page p-0'>
      <div className='sidebar-wrapper'>
        <Sidebar />
      </div>
      <div className='page-right-content'>
        <Header />
        <div className='main-page-content containers chat-main-page'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default ChatMainPage
