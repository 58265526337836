import { createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URLS } from '../../constants/appConstants';
import API from '../../helpers/api';
import { loginFormStates, LoginUserDetail } from '../../types/auth';
import { NotificationsData, PersonalProfile } from '../../types/setting';

interface AuthState {
  loginUserDetail: LoginUserDetail,
  loginLoading: boolean
  loginSucces: boolean,
  loginError: boolean,
  loginErrMessage?: string,
  activePageHeaderText?: string;
  userInfo: PersonalProfile,
  isUserTenant: boolean,
  notificationData: NotificationsData[],
}

const initialState: AuthState = {
  loginUserDetail: {
    email: "",
    first_name: "",
    id: 0,
    last_name: "",
    is_landlord: false,
    is_tenant: false,
    portal: "",
    password: "",
    avatar: "",
    tokens: {
      access: "",
      refresh: "",
    }
  },
  loginLoading: false,
  loginSucces: false,
  loginError: false,
  loginErrMessage: "",
  activePageHeaderText: "",
  userInfo: {
    first_name: '',
    new_password: '',
    old_password: '',
    note: '',
    phone: '',
    avatar: '',
    email: '',
    last_name: '',
    confirmNewPassword: '',
    role: '',
    portal: '',
  },
  isUserTenant: false,
  notificationData: []
}

export const login = createAsyncThunk<
  LoginUserDetail,
  loginFormStates,
  {
    rejectValue: string
  }
>("auth", async (formValues, { rejectWithValue }) => {
  try {
    const response = await API.post(API_URLS.LOGIN, formValues);
    if(response.status === 201 || response.status === 200) {
      return response.data;
    } else {
      return rejectWithValue("SigIn failed. Something went wrong");
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if(error.response?.data?.detail) {
        return rejectWithValue(error.response?.data?.detail);
      } else {
        return rejectWithValue("SigIn failed. Something went wrong");
      }
    }
    return rejectWithValue("SigIn failed. Something went wrong");
  }
})

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthStates: (state) => {
      state = { ...initialState }
      return state;
    },
    clearAuthErrorStates: (state) => {
      state = { ...state, loginError: false, loginErrMessage: "" }
      return state;
    },
    setActivePageHeaderText: (state, action) => {
      state = { ...state, activePageHeaderText: action.payload }
      return state;
    },
    setUserInfo : (state, action) => {
      state = { ...state, userInfo: action.payload }
      return state;
    },
    setUserAsTenant : (state, action) => {
      state = { ...state , isUserTenant:action.payload }
    },
    setNotifications : (state, action) => {
      state = { ...state, notificationData: action.payload }
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loginLoading = true;
      state.loginUserDetail = initialState.loginUserDetail;
      state.loginSucces = false;
      state.loginError = false;
      state.loginErrMessage = "";
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.loginLoading = false;
      state.loginUserDetail = payload;
      state.loginSucces = true;
      state.loginError = false;
      state.loginErrMessage = "";
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loginLoading = false;
      state.loginUserDetail = initialState.loginUserDetail;
      state.loginSucces = false;
      state.loginError = true;
      state.loginErrMessage = action.payload;
    });
  },
});

const { resetAuthStates, clearAuthErrorStates, setActivePageHeaderText, setUserInfo, setUserAsTenant, setNotifications } = authSlice.actions;

export const logout = () => async (dispatch: Dispatch) => {
  return dispatch(resetAuthStates());
}

export const clearAuthState = () => async (dispatch: Dispatch) => {
  return dispatch(clearAuthErrorStates());
}

export const setCurrentUserInfo = (userDetail: PersonalProfile) => async (dispatch: Dispatch) => {
  return dispatch(setUserInfo(userDetail));
}

export const setActivePageHeader = (text: string) => async (dispatch: Dispatch) => {
  return dispatch(setActivePageHeaderText(text));
}

export const setUserAsTanet = (status: boolean) => (dispatch: Dispatch) =>{
  return dispatch(setUserAsTenant(status))
}

export const setNotificationsData = (notificationDetails: NotificationsData) => async (dispatch: Dispatch) => {
  return dispatch(setNotifications(notificationDetails));
}

export default authSlice.reducer;