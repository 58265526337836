import { createSlice, Dispatch } from '@reduxjs/toolkit'
import { ToDoTasksListData } from '../../types/todoTasks'
import moment from 'moment'

interface TodoStates {
  todotaskList: ToDoTasksListData[]
  totalTodoTasksCount: number
  selectedTodoTasks: ToDoTasksListData
}

const initialState: TodoStates = {
  todotaskList: [],
  totalTodoTasksCount: 0,
  selectedTodoTasks: {
    id: 0,
    properties_title: '',
    units_title: '',
    name: '',
    last_run: '',
    status_name: '',
    description: '',
    notification_means: '',
    notification_recipient: '',
    status: '',
    schedule: {
      repeat_frequency: '',
      repeat_type: '',
      ends: '',
      at_hour: parseInt(moment().format('h')),
      at_minutes: parseInt(moment().format('m')),
      meridiem: moment().format('a') === 'am' ? 'a' : 'p',
      timezone: '',
    },
    units: [],
    created: '',
  },
}

const todoSlice = createSlice({
  name: 'todotasks',
  initialState,
  reducers: {
    setTodoTasksList: (state, action) => {
      state = {
        ...state,
        todotaskList: action.payload.todoTasksList,
        totalTodoTasksCount: action.payload.totalTodoTasksCount,
      }
      return state
    },
    setSelectedTodoTasks: (state, action) => {
      state = { ...state, selectedTodoTasks: action.payload }
      return state
    },
  },
})

const { setTodoTasksList, setSelectedTodoTasks } = todoSlice.actions

export const saveTodotasksListData =
  (todoTasksList: ToDoTasksListData[], totalTodoTasksCount: number) =>
  async (dispatch: Dispatch) => {
    return dispatch(setTodoTasksList({ todoTasksList, totalTodoTasksCount }))
  }

export const setSelectedTodos =
  (selectedToDos: ToDoTasksListData) => async (dispatch: Dispatch) => {
    return dispatch(setSelectedTodoTasks(selectedToDos))
  }

export default todoSlice.reducer
