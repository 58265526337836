import { API_URLS } from '../constants/appConstants'
import { CreateTodoTasksFormStates } from '../types/todoTasks'
import API from './api'

export const getTreeSelectUnitsGroups = async () => {
  try {
    const response = await API.get(API_URLS.TREE_SELECT_UNITS_GROUPS)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getTodoTasksList = async () => {
  try {
    const response = await API.get(API_URLS.TODO_TASK)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const deleteToDoTask = async (id: number | null) => {
  return new Promise((resolve, reject) => {
    API.delete(`${API_URLS.TODO_TASK}${id}/`)
      .then((response) => {
        if (response.status === 201 || response.status === 200 || response.status === 204) {
          return resolve(response.data)
        } else {
          throw `request failed with status ${response.status}`
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

const CreateToDoTasks = async (data: CreateTodoTasksFormStates) => {
  return new Promise((resolve, reject) => {
    API.post(API_URLS.TODO_TASK, data)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          if (response.data?.success === false) {
            throw response.data?.msg
          } else {
            return resolve(response.data)
          }
        } else {
          throw `request failed with status ${response.status}`
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

const UpdateTask = async (id: number | null, data: CreateTodoTasksFormStates) => {
  return new Promise((resolve, reject) => {
    API.put(`${API_URLS.TODO_TASK}${id}/`, data)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          if (response.data?.success === false) {
            throw response.data?.msg
          } else {
            return resolve(response.data)
          }
        } else {
          throw `request failed with status ${response.status}`
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

const getTaskById = async (id?: string) => {
  try {
    const response = await API.get(API_URLS.TODO_TASK + id)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const calculateNotificationPriceFromTask = async (data: CreateTodoTasksFormStates) => {
  return new Promise((resolve, reject) => {
    API.post(API_URLS.CALCULATE_PRICE_FROM_TASK, data)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          if (response.data?.success === false) {
            throw response.data?.msg
          } else {
            return resolve(response.data)
          }
        } else {
          throw `request failed with status ${response.status}`
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

const ResumeTask = async (id: number | null) => {
  return new Promise((resolve, reject) => {
    API.post(`${API_URLS.TODO_TASK}${id}${API_URLS.RESUME_TASK}`)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          return resolve(response.data)
        } else {
          throw `request failed with status ${response.status}`
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

const PauseTask = async (id: number | null) => {
  return new Promise((resolve, reject) => {
    API.post(`${API_URLS.TODO_TASK}${id}${API_URLS.PAUSE_TASK}`)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          return resolve(response.data)
        } else {
          throw `request failed with status ${response.status}`
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

const notificationsSent = async () => {
  try {
    const response = await API.get(API_URLS.NOTIFICATIONS_SENT)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

export const todoService = {
  getTreeSelectUnitsGroups,
  getTodoTasksList,
  getTaskById,
  CreateToDoTasks,
  UpdateTask,
  calculateNotificationPriceFromTask,
  deleteToDoTask,
  ResumeTask,
  PauseTask,
  notificationsSent,
}
