import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'

import { Col, Row } from 'reactstrap'
import { Button } from "primereact/button";
import { Badge } from 'primereact/badge'
import { Column } from 'primereact/column'
import { classNames } from 'primereact/utils'
import { DataTable } from 'primereact/datatable'
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator'
import { ROUTES } from '../../../constants/routesConstant'
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHook'
import { setSelectedTenant } from '../../../store/properties/propertiesSlice'
import { setActivePageHeader } from '../../../store/auth/authSlice'
import { ErrorToast, SuccessToast } from '../../../helpers/toster'
import { propertiesService } from '../../../helpers/propertiesService'
import { TenantsListData } from '../../../types/properties'
import DeleteConfirmModal from '../../../components/common/DeleteConfirm/DeleteConfirmModal'
import Dropdown from '../../../components/common/Dropdown/Dropdown'
import DetailView from '../../../components/common/DetailView/DetailView'

const paginationTemplate = {
  layout: 'PrevPageLink PageLinks NextPageLink',
}

interface TenantListProps {
  getTenantsData: () => void
}

const TenantsList = (props: TenantListProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { tenantsList, totalTenantsCount } = useAppSelector((state) => state.propertiesReducer)
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(8)
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsToShow, setRowsToShow] = useState<TenantsListData[]>([])
  const [showDeleteConfirmModal, setDeleteConfirmModal] = useState(false)
  const [tenantsIds, setTenantsIds] = useState<number[]>([])
  const [showDetailsView, setShowDetailsView] = useState(false)
  const [selectedTenantData, setSelectedTenantData] = useState<TenantsListData | null>(null)

  useEffect(() => {
    setRowsToShow(tenantsList.slice(0, 8))
  }, [])

  useEffect(() => {
    setRowsToShow(tenantsList.slice(0, 8))
  }, [tenantsList])

  const handleRowSelect = (rowData: TenantsListData) => {
    dispatch(setSelectedTenant(rowData))
    navigate(ROUTES.TENANTS)
  }

  const onPageChange = (e: PaginatorPageChangeEvent) => {
    setFirst(e.first)
    setRows(e.rows)
    setCurrentPage(e.page + 1)
    setRowsToShow(tenantsList.slice(e.first, e.rows * (e.page + 1)))
  }

  const formatRent = (number: number) => {
    return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0';
  }

  const handleDeleteTenants = async (ids: Array<number>) => {
    const data = {
      ids: ids,
    }
    try {
      await propertiesService.deleteTenants(data)
      props.getTenantsData()
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.data?.detail) {
          ErrorToast(err.response?.data?.detail || 'Error in delete tenant')
        } else {
          ErrorToast('Error in delete property tenant')
        }
      }
    }
  }

  const handleUpdatePaymentStatus = async (ids: Array<number>, paymentStatus: string) => {
    const data = {
      ids: ids,
      status: paymentStatus,
    }

    const response = await propertiesService.updatePaymentStatus(data)
    if (response) {
      props.getTenantsData()
      SuccessToast("Payment status updated successfully")
    }
  }

  /* Templates */

  const dropDownTemplate = (rowData: TenantsListData) => {
    const DropdownRef = useRef<any>(null)
    const DropdownItems = [
      {
        text: 'Open details',
        onClick: (e: any) => {
          DropdownRef.current?.toggle(e)
          setSelectedTenantData(rowData)
          setShowDetailsView(true)
        },
      },
      {
        text: 'Edit tenant',
        onClick: (e: any) => {
          DropdownRef.current?.toggle(e)
          navigate(`${ROUTES.EDIT_TENANT}/${rowData.id}`)
        },
      },
      {
        text: `Mark as ${rowData.status === 'Unpaid' ? 'Paid' : 'Unpaid'}`,
        onClick: (e: any) => {
          DropdownRef.current?.toggle(e)
          handleUpdatePaymentStatus([rowData.id], rowData.status === 'Unpaid' ? 'p' : 'u')
        },
      },
      {
        text: 'Delete tenant',
        className: 'text-danger',
        onClick: (e: any) => {
          DropdownRef.current?.toggle(e)
          setDeleteConfirmModal(true)
          setTenantsIds([rowData.id])
        },
      },
    ]
    return (
      <Dropdown ref={DropdownRef} onClick={(e: any) => DropdownRef.current?.toggle(e)} content={DropdownItems} />
    )
  }

  const StatusTemplate = (rowData: TenantsListData) => {
    return (
      <Badge
        className={classNames({
          'status-badge badge-list': rowData.status === '--',
          'status-badge badge-success': rowData?.status === 'Paid',
          'status-badge badge-danger': rowData?.status === 'Unpaid',

        })}
        severity={rowData.status === 'Paid' ? 'success' : rowData.status === 'Unpaid' ? 'danger' : null}
        value={rowData.status}
      >
        {rowData.status}
      </Badge>

    )
  }

  const leaseDateTemplate = (date: string) => {
    if (!date) return ''
    else
      return (
        moment(date).format('MMM D, YYYY')
      )
  }

  return (
    <>
      <DetailView closeButton={showDetailsView} onCloseView={(e: boolean) => { setShowDetailsView(e) }}
        editButton={() => navigate(`${ROUTES.EDIT_TENANT}/${selectedTenantData?.id}`)}
        deteleButton={() => setDeleteConfirmModal(true)}>
        <h2>{selectedTenantData?.first_name_by_ll ? `${selectedTenantData?.first_name_by_ll} ${selectedTenantData?.last_name_by_ll}` : `${selectedTenantData?.first_name} ${selectedTenantData?.last_name}`}</h2>
        <Row>
          <Col className='col-12 col-sm-6'><h4>Email address</h4></Col>
          <Col className='col-12 col-sm-6'><p>{selectedTenantData?.email}</p></Col>
        </Row>
        <Row>
          <Col className='col-12 col-sm-6'><h4>Phone number</h4></Col>
          <Col className='col-12 col-sm-6'><p>{selectedTenantData?.phone}</p></Col>
        </Row>
        <hr />
        <h3 className='mb-4'>Assigned property</h3>
        <Row>
          <Col className='col-12 col-sm-6'><h4>Property name</h4></Col>
          <Col className='col-12 col-sm-6'><p>{selectedTenantData?.property_title}</p>
          </Col>
        </Row>
        <Row>
          <Col className='col-12 col-sm-6'><h4>Unit name</h4></Col>
          <Col className='col-12 col-sm-6'><p>{selectedTenantData?.unit_name}</p></Col>
        </Row>
        <Row>
          <Col className='col-12 col-sm-6'><h4>Lease term</h4></Col>
          <Col className='col-12 col-sm-6'><p>{selectedTenantData?.lease_start_date && selectedTenantData?.lease_end_date ? `${leaseDateTemplate(selectedTenantData?.lease_start_date)} - ${leaseDateTemplate(selectedTenantData?.lease_end_date)}` : ''}</p></Col>
        </Row>
        <Row>
          <Row className='col-6 col-sm-12'>
            <Col className='col-12 col-sm-6'><h4>Rent</h4></Col>
            <Col className='col-12 col-sm-6'><p>{selectedTenantData ? `USD ${formatRent(selectedTenantData?.monthly_rent)}` : `USD 0`}</p></Col>
          </Row>
          <Row className='col-6 col-sm-12'>
            <Col className='col-12 col-sm-6'><h4>Status</h4></Col>
            <Col className='col-12 col-sm-6'><p>{selectedTenantData?.status && StatusTemplate(selectedTenantData)}</p></Col>
          </Row>
        </Row>
      </DetailView>
      <div className='data-list datatable-listing pb-2 mb-3'>
        <DataTable
          scrollable
          editMode='row'
          value={rowsToShow}
          dataKey='id'
          tableStyle={{ minWidth: '50rem', minHeight: '100%' }}
        >
          <Column
            sortable
            className='fw-semibold text-black'
            field='tenant_name'
            header='Tenant Name'
            body={(rowData) => (
              <div
                onClick={(e) => {
                  e.stopPropagation()
                  handleRowSelect(rowData)
                }}
              >
                {rowData.first_name || rowData.first_name_by_ll
                  ? `${rowData.first_name || rowData.first_name_by_ll} ${rowData.last_name || rowData.last_name_by_ll
                  }`
                  : rowData.email}
              </div>
            )}
          />
          <Column
            sortable
            className='fw-semibold text-black'
            field='property_title'
            header='Property'
          />
          <Column
            sortable
            className='fw-semibold text-black'
            field='unit_name'
            header='Unit'
          />
          <Column
            sortable
            className='fw-semibold text-black'
            header='Rent'
            body={(rowData: TenantsListData) =>
              `USD ${formatRent(rowData.monthly_rent)}`
            }
          />
          <Column
            sortable
            className='fw-semibold text-black'
            field='lease_start_date'
            header='Lease start date'
            body={(rowData: TenantsListData) => leaseDateTemplate(rowData.lease_start_date)}
          />
          <Column
            sortable
            className='fw-semibold text-black'
            field='lease_end_date'
            header='Lease end date'
            body={(rowData: TenantsListData) => leaseDateTemplate(rowData.lease_end_date)}
          />
          <Column
            sortable
            field='status'
            header='Status'
            body={StatusTemplate}
          />
          <Column headerStyle={{ width: '4rem' }} body={dropDownTemplate} />
        </DataTable>

        <div className='pagination-section mt-3 d-flex align-items-center'>
          <div className='total-records'>
            Results: {first + 1}-
            {totalTenantsCount - first < rows
              ? first + (totalTenantsCount - first)
              : rows * currentPage}{' '}
            of {totalTenantsCount}
          </div>
          {tenantsList.length > 8 && (
            <div className='pagination-container ms-auto'>
              <Paginator
                template={paginationTemplate}
                first={first}
                rows={rows}
                totalRecords={totalTenantsCount}
                onPageChange={(e) => onPageChange(e)}
                className='justify-content-start'
              />
            </div>
          )}
        </div>
      </div>

      <DeleteConfirmModal
        handleReject={() => {
          setDeleteConfirmModal(false)
        }}
        handleAccept={() => { handleDeleteTenants(tenantsIds); setDeleteConfirmModal(false) }}
        isModalOpen={showDeleteConfirmModal}
        header={'Delete Confirmation'}
        message={
          tenantsIds.length > 1
            ? 'Do you want to delete ' + tenantsIds.length + ' tenants?'
            : 'Do you want to delete this tenant?'
        }
      />
    </>
  )
}

export default TenantsList
