import { Button } from "primereact/button";
import { Modal, ModalBody } from 'reactstrap'
import closeIcon from '../../../assets/images/svgImages/Close.svg'

interface commonAlertModalProps {
  isModalOpen: boolean
  handleClose: () => void
  message: string
  subHeading: string
  modalIcon?: string
  bottonText: string
  handleButtonAction: () => void
  link?: string
  requiredFieldsArray?: string[]
}

const CommonAlertModal = (props: commonAlertModalProps) => {
  const {
    isModalOpen,
    handleClose,
    message,
    subHeading,
    modalIcon,
    handleButtonAction,
    bottonText,
    link,
    requiredFieldsArray = [],
  } = props

  const openNewTab = () => {
    if (props?.link) {
      window.open(props?.link, '_blank', 'noreferrer')
    } else {
      return false
    }
  }

  return (
    <>
      <Modal
        size='md'
        centered
        className='common-alert-modal'
        isOpen={isModalOpen}
        toggle={handleClose}
      >
        <div className='modal-header-container'>
          <div className='close-icon text-end cursor-pointer' onClick={handleClose}>
            <img src={closeIcon} />
          </div>
        </div>
        <ModalBody className='common-body-container d-flex justify-content-center align-items-start'>
          <div className='modal-body-content h-100'>
            {modalIcon && <div className='icon-wrapper mb-3'>
              <img alt='modal-icon' src={modalIcon} />
            </div>}
            <div className='message-text mb-3'>{message}</div>
            {requiredFieldsArray && requiredFieldsArray.length
              ? requiredFieldsArray.map((data, index) => (
                <div key={index} className='sub-heading-text mb-2'>
                  Please provide the <span className=''>{data}</span>
                </div>
              ))
              : ''}
            <div className='sub-heading-text mb-5'>{subHeading}</div>
            {props?.link && (
              <div className='mb-4 cursor-pointer'>
                <a className='redirect-link' onClick={openNewTab}>
                  {link}
                </a>
              </div>
            )}
            <div className='modal-button'>
              <Button className='btn cta' onClick={handleButtonAction}>
                {bottonText}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default CommonAlertModal
