import { useEffect, useState, useRef, useCallback } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import moment from 'moment'

import { Button } from "primereact/button";
import { Input } from 'reactstrap'
import { WS_URLS, scheduleMessage } from '../../../constants/appConstants'
import { getUser } from '../../../helpers/userService'
import { chatServices } from '../../../helpers/chatService'
import { ErrorToast } from '../../../helpers/toster'
import { chatListInformation } from '../../../types/chat'
import CustomScheduleModal from './CustomScheduleModal'
import NewChatModal from '../../../components/common/NewChatModal/NewChatModal'

import sendMessageIcon from '../../../assets/images/svgImages/send_message.svg'
import searchIcon from '../../../assets/images/svgImages/search_icon.svg'
import clock from '../../../assets/images/svgImages/clock.svg'
import addIcon from '../../../assets/images/add_chat_icon.png'
import backIcon from '../../../assets/images/svgImages/Back.svg'

interface Chat {
  recipientId: number
  firstChat: boolean
}

const Chats = (props: Chat) => {
  const currentUser = getUser()
  const [showNewChatModal, setShowNewChatModal] = useState(false)
  const [displayScheduleModal, setDisplayScheduleModal] = useState(false)
  const [showCustomScheduleModal, setShowCustomScheduleModal] = useState(false)
  const [messageHistory, setMessageHistory] = useState([])
  const [scheduledMessages, setScheduledMessages] = useState([])
  const [chatList, setChatList] = useState<Array<any>>([])
  const [chatsUsersIds, setChatsUsersIds] = useState<number[]>([])
  const [usersData, setUsersData] = useState([])
  const [recipientUser, setRecipientUser] = useState<number>(
    props.recipientId ? props.recipientId : 0,
  )
  const [newMessage, setNewMessage] = useState('')
  const [scheduleType, setScheduleType] = useState({ label: 'Now', value: 'n' })
  const [newChat, setNewChat] = useState(props.firstChat ? props.firstChat : false)
  const [sortDateFromMessages, setSortDateFromMessages] = useState<any>([])
  const [firstUnreadMessage, setFirstUnreadMessage] = useState(0)
  const [sendTime, setSendTime] = useState<string | null>(null)
  const [filters, setFilters] = useState<string>("")
  const [filteredData, setFilteredData] = useState<any>([])


  const chatContainerRef = useRef<HTMLUListElement>(null)

  const WS_CHAT = process.env.REACT_APP_WEBSOCKET + WS_URLS.CHAT + currentUser.id + '/'
  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(WS_CHAT, {
    shouldReconnect: (closeEvent) => true,
    reconnectInterval: 500,
    reconnectAttempts: 10,
  })

  useEffect(() => {
    getChatList()
    getChatHistory()
    getScheduleMessages()
    getChatsUsersIds()
    getDataUsers()

    setTimeout(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollIntoView({
          block: 'end',
          inline: 'nearest',
        })
      }
    }, 300)
  }, [lastMessage, recipientUser])

  useEffect(() => {
    if (filters) {
      const filter = chatList.filter((data) => data.correspondent.full_name.toLowerCase().includes(filters.toLowerCase()))
      setFilteredData(filter)
    } else {
      setFilteredData(chatList)
    }
  }
    , [filters])

  useEffect(() => {
    if (scheduleType.value === 'c') {
      setShowCustomScheduleModal(true)
    }
  }, [scheduleType])

  useEffect(() => {
    if (messageHistory) {
      const sortedMessages = messageHistory.sort((a: any, b: any) => {
        return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      })
      const groupedMessages = sortedMessages.reduce((acc: any, message: any) => {
        const date = moment(message.timestamp).format('YYYY-MM-DD')
        if (!acc[date]) {
          acc[date] = []
        }
        acc[date].push(message)
        return acc
      }, {})
      setSortDateFromMessages(Object.entries(groupedMessages))
    }

    if (chatContainerRef.current) {
      chatContainerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      })
    }
  }, [messageHistory])

  const getDataUsers = async () => {
    if (currentUser.portal === 'tenant') {
      const response = await chatServices.getLandlordsData()
      setUsersData(response.results)
    } else if (currentUser.portal === 'landlord') {
      const response = await chatServices.getTenantsData()
      setUsersData(response.results)
    }
  }

  const showScheduledMessageOptions = (id: number) => {
    const scheduledMessageOptions = document.getElementById(`scheduled-message-options-${id}`)
    if (scheduledMessageOptions) {
      scheduledMessageOptions.style.display === 'block'
        ? (scheduledMessageOptions.style.display = 'none')
        : (scheduledMessageOptions.style.display = 'block')
    }
  }

  const cancelScheduledMessages = async (id: number) => {
    await chatServices.cancelScheduledMessage(id)
    getScheduleMessages()
    getChatHistory()
  }

  const getChatHistory = async () => {
    const response = await chatServices.getChatHistory(recipientUser)
    setMessageHistory(response)

    const firstUnreadMessage = response.find((message: any) => message.is_read === false)
    setFirstUnreadMessage(firstUnreadMessage?.id)
  }

  const getScheduleMessages = async () => {
    const response = await chatServices.getScheduledMessages(recipientUser)
    setScheduledMessages(response)
  }

  const getChatList = async () => {
    const response = await chatServices.getChatList()
    setChatList(response)
    setFilteredData(response)
  }

  const getChatsUsersIds = () => {
    if (chatList) {
      const ids: Array<number> = []
      chatList.map((user: chatListInformation) => {
        ids.push(user.correspondent.id)
      })

      setChatsUsersIds(ids)
      showNewChatWindow(ids)
    }
  }

  const showNewChatWindow = (ids: Array<number>) => {
    let matches = 0
    ids.map((id: number) => {
      if (recipientUser === id) {
        matches += 1
      }
    })

    if (matches === 0) {
      setNewChat(true)
    } else {
      setNewChat(false)
    }
  }

  const markAsReadMessage = async (id?: number) => {
    await chatServices.markAsReadMessage(id ? id : recipientUser)
  }

  const handleClickSendMessage = useCallback(() => {
    const currentMessage = newMessage
    sendJsonMessage(
      {
        recipient_id: recipientUser,
        message: currentMessage,
        schedule_type: scheduleType.value,
        send_time: sendTime,
      },
      false,
    )
    setNewMessage('')

    if (newChat) {
      setNewChat(false)
    }

    setTimeout(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollIntoView({
          block: 'end',
          inline: 'nearest',
        })
      }
    }, 300)

    markAsReadMessage()
    getChatList()
    getDataUsers()
    getChatHistory()
    getScheduleMessages()
  }, [newMessage])

  const handleEnterKey = (e: any) => {
    if (e.keyCode === 13) {
      if (connectionStatus !== 'Open') {
        ErrorToast('There seems to be a problem with our chat app. Please connect with us through support@happypropy.com')
      } else {
        handleClickSendMessage()
      }
    }
  }

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState]

  return (
    <div className='App'>
      <div className='chat-container'>
        <div className={`col-lg-3 col-12 chat-list-container ${recipientUser !== 0 ? "chat-selected" : ""}`}>
          <div className='chat-management'>
            <div className='col searchbar-wrapper'>
              <div className='searchbar-container searchbar-container--bg'>
                <input className='form-control' placeholder='Search by name' value={filters} onChange={(e) => { setFilters(e.target.value) }} />
                <div className='search-icon cursor-pointer'>
                  <img src={searchIcon} alt='search-icon' />
                </div>
              </div>
            </div>
            <button
              className='searchbar-container add-chat-button'
              onClick={() => setShowNewChatModal(true)}
            >
              <img src={addIcon} alt='search-icon' />
            </button>
          </div>
          <div className='chat-list mt-5'>
            {chatList &&
              filteredData.map((chat: chatListInformation, idx: number) => {
                return (
                  <div
                    key={idx}
                    className={`chat-item ${recipientUser === chat.correspondent.id ? 'active' : ''} ${chat.is_read ? '' : 'new-message'}`}
                    onClick={() => {
                      setRecipientUser(chat.correspondent.id)
                      markAsReadMessage(chat.correspondent.id)
                      setNewChat(false)
                    }}
                  >
                    <div className='user-image'>
                      {chat.correspondent.avatar ? (
                        <img className='avatar' src={chat.correspondent.avatar} alt='user image' />
                      ) : (
                        <div className='avatar'></div>
                      )}
                      <div className='status'></div>
                    </div>
                    <div className='chat-info'>
                      <div className='heading'>
                        <div className='chat-name'>{chat.correspondent.full_name}</div>
                        <div className='time'>{moment(chat.send_time).format('HH:mm A')}</div>
                      </div>
                      <div className='chat-last-message'>{chat.message}</div>
                    </div>
                  </div>
                )
              })
            }
            {newChat ? (
              usersData.map((user: any, idx) => {
                if (user.id === recipientUser) {
                  if (currentUser.portal === 'landlord') {
                    user['display_name'] = user.first_name_by_ll + ' ' + user.last_name_by_ll
                  } else if (currentUser.portal === 'tenant') {
                    user['display_name'] = user.first_name + ' ' + user.last_name
                  }
                  return (
                    <div key={idx} className={'chat-item active'}>
                      <div className='user-image'>
                        {user.avatar ? <img src={user.avatar} alt='user image' /> : ''}
                        <div className='status'></div>
                      </div>
                      <div className='chat-info'>
                        <div className='heading'>
                          <div className='chat-name'>{user.display_name}</div>
                        </div>
                        <div className='no-message'>No messages here yet</div>
                      </div>
                    </div>
                  )
                }
              })
            ) : (
              ''
            )}
          </div>
        </div>
        <div className='col chat-view'>
          {recipientUser ? (
            <>
              {usersData.map((user: any, idx) => {
                if (user.id === recipientUser) {
                  if (currentUser.portal === 'landlord') {
                    user['fullName'] = user.first_name_by_ll + ' ' + user.last_name_by_ll
                  } else if (currentUser.portal === 'tenant') {
                    user['fullName'] = user.first_name + ' ' + user.last_name
                  }
                  return (
                    <div className='chat-header' key={idx}>
                      <div className='addresse'>
                        <Button className='back-icon flat cursor-pointer d-block d-lg-none' onClick={() => setRecipientUser(0)}>
                          <img src={backIcon} alt="close icon" />
                        </Button>
                        <div className='addresse-image'>
                          {user.avatar ? (
                            <img className='avatar' src={user.avatar} alt='user image' />
                          ) : (
                            <div className='avatar'></div>
                          )}
                          <div className='status'></div>
                        </div>
                        <span className='heading-text'>{user.fullName}</span>
                      </div>
                    </div>
                  )
                }
              })}
              <div className='chat-content'>
                <ul ref={chatContainerRef}>
                  {sortDateFromMessages.map(([date, messages]: any) => {
                    const calendar = moment(date).calendar(null, {
                      sameDay: '[Today]',
                      nextDay: '[Tomorrow]',
                      nextWeek: 'dddd',
                      lastDay: '[Yesterday]',
                      lastWeek: '[Last] dddd',
                      sameElse: 'DD/MM/YYYY',
                    })
                    return (
                      <div key={date}>
                        <div className='date'>
                          <hr />
                          <span>{calendar}</span>
                        </div>
                        <div className='message-history'>
                          {messages.map((message: any, idx: number) => (
                            <>
                              <div
                                key={idx}
                                className={
                                  !message.is_read
                                    ? message.sender !== currentUser.id
                                      ? 'message-received new-message'
                                      : 'message-sent'
                                    : message.sender !== currentUser.id
                                      ? 'message-received'
                                      : 'message-sent'
                                }
                              >
                                {firstUnreadMessage === message.id &&
                                  message.sender === recipientUser && (
                                    <div className='unread-message'>
                                      <hr />
                                      <span>new</span>
                                    </div>
                                  )}
                                <div className='message-hour'>
                                  <div className='message'>{message.message}</div>
                                  <div className='hour'>
                                    {moment(message.send_time).format('HH:mm')}
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                    )
                  })}
                  {scheduledMessages.map((message: any, idx: number) => (
                    <div
                      key={idx}
                      className='message-scheduled'
                      onClick={() => showScheduledMessageOptions(message.id)}
                    >
                      <div className='message-hour'>
                        <div className='message'>{message.message}</div>
                        <div className='hour'>
                          <img src={clock} />
                          {` Will send on ${moment(message.send_time).format(
                            'DD/MM/YYYY [at] h:m A',
                          )}`}
                        </div>
                        <div
                          className='scheduled-message-options'
                          style={{ display: 'none' }}
                          id={`scheduled-message-options-${message.id}`}
                        >
                          <button
                            className='delete-message'
                            onClick={() => cancelScheduledMessages(message.id)}
                          >
                            Cancel the sending
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className='scrollBottom'></div>
                </ul>
              </div>
              <div className='chat-footer'>
                <div className='chat-inputs'>
                  <div className='message-input'>
                    <Input
                      id='message'
                      name='message'
                      type='text'
                      autoComplete='off'
                      placeholder='Enter your message'
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      onKeyDown={handleEnterKey}
                    />
                  </div>
                  <div className='message-button'>
                    <Button
                      className='send-message-button'
                      onClick={handleClickSendMessage}
                      disabled={
                        newMessage && recipientUser && connectionStatus === 'Open' ? false : true
                      }
                    >
                      <img src={sendMessageIcon} alt='send-icon' />
                    </Button>
                  </div>
                </div>
                {/* <div className='schedule'>
                  <div
                    className='select-schedule'
                    style={{ display: displayScheduleModal ? 'block' : 'none' }}
                  >
                    <div className='schedule-options'>
                      {scheduleMessage.map((schedule, idx) => {
                        return (
                          <a
                            key={idx}
                            className='schedule-option'
                            onClick={() => {
                              setScheduleType({ label: schedule.label, value: schedule.value })
                              setDisplayScheduleModal(false)
                            }}
                          >
                            {schedule.label}
                          </a>
                        )
                      })}
                    </div>
                  </div>
                  <span>
                    Send message:{' '}
                    <a className='scheduled-messages' onClick={() => setDisplayScheduleModal(true)}>
                      {scheduleType.value === 'c'
                        ? sendTime
                          ? moment(sendTime).format('DD/MM/YYYY HH:mm')
                          : scheduleType.label
                        : scheduleType.label}
                    </a>
                  </span>
                </div> */}
              </div>
            </>
          ) : (
            <div className='no-chat-selected'>Select a chat to start messaging</div>
          )}
        </div>
      </div>
      <CustomScheduleModal
        isModalOpen={showCustomScheduleModal}
        handleClose={() => setShowCustomScheduleModal(false)}
        handleButtonAction={(e) => {
          setSendTime(e)
          setShowCustomScheduleModal(false)
        }}
      />
      <NewChatModal
        isModalOpen={showNewChatModal}
        handleClose={() => setShowNewChatModal(false)}
        handleButtonAction={(value) => {
          setShowNewChatModal(false)
          setRecipientUser(value)

          if (chatList) {
            getChatsUsersIds()
          }
        }}
      />
    </div>
  )
}

export default Chats
