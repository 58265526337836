export interface inputFieldProps {
  value?: string;
  label?: string;
  name?: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = (props: inputFieldProps) => {
  return (
    <div className="form-check">
      <label className="form-check-label caption ps-2">
        <input className="form-check-input" type="checkbox" checked={props.checked} onChange={props.onChange} />
        By clicking Create account, I agree that I have read and accepted the <span className="font-bold">Terms of Use</span> and <span className="font-bold">Privacy Policy</span>.
      </label>
    </div>
  );
};
export default Checkbox;