import { useEffect, useState, useRef } from 'react'
import useWebSocket from 'react-use-websocket'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import { Col, Row } from 'reactstrap'
import { DataTable } from 'primereact/datatable'
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator'
import { classNames } from 'primereact/utils'
import { Column } from 'primereact/column'
import { Badge } from 'primereact/badge'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHook'
import { setTenantSelectedMaintenance } from '../../store/tenant/tenantSlice'
import { setActivePageHeader } from '../../store/auth/authSlice'
import { ROUTES } from '../../constants/routesConstant'
import { WS_URLS } from '../../constants/appConstants'
import { getUser } from '../../helpers/userService'
import { tenantService } from '../../helpers/tenantService'
import { SuccessToast } from '../../helpers/toster'
import { TenantMaintenanceListData } from '../../types/tenantDetails'
import DeleteConfirmModal from '../../components/common/DeleteConfirm/DeleteConfirmModal'
import Dropdown from '../../components/common/Dropdown/Dropdown'
import DetailView from '../../components/common/DetailView/DetailView'

export interface TenantMaintenanceListProps {
  tenantMaintenanceList: TenantMaintenanceListData[]
  totalMaintenanceListCout: number
  refreshTable: () => void
}

const TenantMaintenanceListPageComponent = (props: TenantMaintenanceListProps) => {
  const { refreshTable, tenantMaintenanceList, totalMaintenanceListCout } = props
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { selectedTenantMaintenance } = useAppSelector((state) => state.tenantReducer)
  const [selectedId, setSelectedId] = useState(0)
  const [showDetailsView, setShowDetailsView] = useState(false)
  const [selectedMaintenance, setSelectedMaintenance] = useState<TenantMaintenanceListData>()

  const [currentPage, setCurrentPage] = useState(1)
  const [rowsToShow, setRowsToShow] = useState(tenantMaintenanceList.slice(0, 8))
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(8)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const userData = getUser()

  const WS_MAINTENANCE =
    process.env.REACT_APP_WEBSOCKET + WS_URLS.MAINTENANCE_NOTIFICATIONS + userData?.id + '/'
  const { lastMessage: lastNotificationMaintenance } = useWebSocket(WS_MAINTENANCE, {
    shouldReconnect: () => true,
  })

  useEffect(() => {
    dispatch(setActivePageHeader('Maintenance List'))
  }, [])

  useEffect(() => {
    refreshTable()
  }, [lastNotificationMaintenance])

  useEffect(() => {
    setRowsToShow(tenantMaintenanceList.slice(0, 8))
  }, [tenantMaintenanceList])

  const processDate = (date: string) => {
    return moment(date).format('MMM D, YYYY')
  }

  const dropdownDeleteClick = async () => {
    const id = selectedTenantMaintenance?.id
    tenantService
      .deleteSelectedMaintenance(id.toString())
      .then((res) => {
        if (res === 'Data deleted succesfully') {
          SuccessToast('Task Deleted Successfully')
          refreshTable()
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const dropdownEditClick = async (id: number) => {
    navigate(`${ROUTES.TENANT_MAINTENANCE_EDIT}?id=${id}`)
  }

  const handleRowSelect = (rowData: TenantMaintenanceListData) => {
    dispatch(setTenantSelectedMaintenance(rowData))
    dispatch(setActivePageHeader('Maintenance Detail'))
    navigate(ROUTES.TENANT_MAINTENANCE_DETAIL)
  }

  const paginationTemplate = {
    layout: 'PrevPageLink PageLinks NextPageLink',
  }

  const onPageChange = (e: PaginatorPageChangeEvent) => {
    setFirst(e.first)
    setRows(e.rows)
    setCurrentPage(e.page + 1)
    setRowsToShow(tenantMaintenanceList.slice(e.first, e.rows * (e.page + 1)))
  }

  const createdDateTemplate = (rowData: TenantMaintenanceListData) => {
    return processDate(rowData?.created)
  }

  const DescriptionTemplate = (rowData: TenantMaintenanceListData) => {
    return (
      <span>{`${rowData.description.slice(0, 26)}${rowData.description.length > 26 ? '...' : ''
        }`}</span>
    )
  }

  const StatusTemplate = (rowData: TenantMaintenanceListData) => {
    return (
      <Badge
        className={classNames({
          'status-badge badge-list': rowData.status_name === 'Open',
          'status-badge badge-gradient': rowData?.status_name === 'In-Progress',
          'status-badge badge-success': rowData?.status_name === 'Completed',
        })}

        severity={rowData.status_name === 'Completed' ? 'success' : null}
        value={rowData.status_name}
      />
    )
  }

  const dropDownTemplate = (rowData: TenantMaintenanceListData) => {
    const DropdownRef = useRef<any>(null)
    const DropdownItems = [
      { text: 'Open details', onClick: (e: any) => { DropdownRef.current.toggle(e); setSelectedMaintenance(rowData); setShowDetailsView(true) } },
      {
        text: 'Edit Task',
        className: '',
        onClick: (e: any) => {
          DropdownRef.current.toggle(e)
          dropdownEditClick(rowData.id)
        },
      },
      {
        text: 'Remove Task',
        className: 'text-danger',
        onClick: (e: any) => {
          setSelectedId(rowData.id)
          setShowDeleteModal(true)
          DropdownRef.current.toggle(e)
        },
      },
    ]
    return (
      <Dropdown ref={DropdownRef} onClick={(e: any) => DropdownRef.current?.toggle(e)} content={DropdownItems} />
    )
  }



  return (
    <>
      <DetailView closeButton={showDetailsView} onCloseView={(e: boolean) => { setShowDetailsView(e) }}
        deteleButton={() => setShowDeleteModal(true)}>
        <h2>{selectedMaintenance?.title}</h2>
        <Row>
          <Col sm={6}><h4>Tenant</h4></Col>
          <Col sm={6}><p>{selectedMaintenance?.assignee_name}</p></Col>
        </Row>
        <Row>
          <Col sm={6}><h4>Property</h4></Col>
          <Col sm={6}><p>{selectedMaintenance?.property_title}</p></Col>
        </Row>
        <Row>
          <Col sm={6}><h4>Unit</h4></Col>
          <Col sm={6}><p>{selectedMaintenance?.assigned_unit_name}</p></Col>
        </Row>
        <Row>
          <Col sm={6}><h4>Creation date</h4></Col>
          <Col sm={6}><p>{selectedMaintenance?.created ? processDate(selectedMaintenance.created) : "N/A"}</p></Col>
        </Row>
        <Row>
          <Col sm={6}><h4>Status</h4></Col>
          <Col sm={6}><p>{selectedMaintenance && StatusTemplate(selectedMaintenance)}</p></Col>
        </Row>
        <Row>
          <Col sm={6}><h4>Description</h4></Col>
          <Col sm={6}><p>{selectedMaintenance?.description}</p></Col>
        </Row>
      </DetailView>
      <div className='data-list datatable-listing pb-2 mb-3'>
        <DataTable
          editMode='row'
          value={rowsToShow}
          dataKey='id'
          tableStyle={{ minWidth: '50rem' }}
        >
          {/* <Column
              selectionMode='multiple'
              headerStyle={{ width: '3rem', textAlign: 'center' }}
            ></Column> */}
          <Column
            sortable
            frozen
            className='fw-semibold text-black'
            field='title'
            header='Task name'
            body={(rowData: TenantMaintenanceListData) => (
              <div
                onClick={() => {
                  handleRowSelect(rowData)
                }}
              >
                {rowData?.title}
              </div>
            )}
          />
          <Column
            sortable
            className='fw-semibold text-black'
            field='assignee_name'
            header='Tenant'
          />
          <Column
            sortable
            className='fw-semibold text-black'
            field='property_title'
            header='Property'
          />
          <Column
            sortable
            className='fw-semibold text-black'
            field='assigned_unit_name'
            header='Unit'
          />
          <Column
            sortable className='fw-semibold text-black' field='created' header='Creation date' body={createdDateTemplate} />
          <Column
            sortable
            className='fw-semibold text-black'
            field='status_name'
            header='status'
            body={StatusTemplate}
          />
          <Column
            sortable
            frozen
            className='fw-semibold text-black'
            field='description'
            header='Description'
            body={DescriptionTemplate} />
          <Column headerStyle={{ width: '4rem' }} body={dropDownTemplate} />
        </DataTable>
        <div className='pagination-section mt-3 px-4 d-flex align-items-center'>
          <div className='total-records'>
            Results: {first + 1}-
            {totalMaintenanceListCout - first < rows
              ? first + (totalMaintenanceListCout - first)
              : rows * currentPage}{' '}
            of {totalMaintenanceListCout}
          </div>

          {tenantMaintenanceList.length > 8 && (
            <div className='pagination-container ms-auto'>
              <Paginator
                template={paginationTemplate}
                first={first}
                rows={rows}
                totalRecords={totalMaintenanceListCout}
                onPageChange={(e) => onPageChange(e)}
                className='justify-content-start'
              />
            </div>
          )}
        </div>
      </div>

      <DeleteConfirmModal
        handleReject={() => {
          setShowDeleteModal(false)
          setSelectedId(0)
        }}
        handleAccept={() => {
          dropdownDeleteClick(); setShowDeleteModal(false)
        }}
        isModalOpen={showDeleteModal}
        header={'Delete Confirmation'}
        message={'Do you want to delete this property unit'}
      />
    </>
  )
}

export default TenantMaintenanceListPageComponent
