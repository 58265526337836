import { API_URLS } from '../constants/appConstants'
import { TenantMaintenanceListData, TenantPaymentSchedulingRequest, TenantRegistrationRequest, TenantRentPayRequest } from '../types/tenantDetails'
import API from './api'


const getTenantPaymentHistory = async () => {

    try {
        const response = await API.get(API_URLS.TENANT_LANDING_PAGE)
        if (response.status === 201 || response.status === 200) {
          return response.data
        } else {
          throw `request failed with status ${response.status}`
        }
      } catch (err) {
        return err
      }

}

const getTenantMaintenanceTasks = async ( ) => {
    try {
        const response = await API.get(API_URLS.GET_MAINTENANCE_LIST)
        if (response.status === 201 || response.status === 200) {
          return response.data
        } else {
          throw `request failed with status ${response.status}`
        }
      } catch (err) {
        return err
      }
}

const createTenantMaintenanceTask = async (payload: FormData ) => {
    return new Promise<TenantMaintenanceListData>((resolve, reject) => {
      API.post(API_URLS.CREATE_MAINTENANCE_TASK, payload, {
          headers: {
              "Content-Type": "multipart/form-data",
          }
      }).then((response) => {
          if(response.status === 201 || response.status === 200) {
              return resolve(response.data );
          } else {
              throw `request failed with status ${response.status}`;
          }
      }).catch(err => {
          return reject(err);
      })
  });
}

const getNonFinancialStatsForTenant = async () =>{
  try {
    const response = await API.get(API_URLS.GET_NON_FINANCIAL_STATS_FOR_TENANT)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const setUpPaymentIntentForTenant = async () => {
  try {
    const response = await API.get(API_URLS.SETUP_PAYMENT_INTENT_FOR_TENANT)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const tenantPayRent = async (payload: TenantRentPayRequest) => {

  try {
    const response = await API.post(API_URLS.TENANT_PAY_RENT, payload)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
} 

const TenantPaymentScheduling = async (payload: TenantPaymentSchedulingRequest) => {

  try {
    const response = await API.post(API_URLS.TENANT_PAYMENT_SCHEDULING, payload)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
} 

const registerTenant = async (payload: TenantRegistrationRequest) =>{
  try {
    const response = await API.post(API_URLS.REGISTER_TENANT,payload)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getPaymentMethod = async () =>{
  try {
    const response = await API.get(API_URLS.TENANT_PAYMENT_METHODS)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const updateTenantMaintenance = async (payload: FormData,id: string) => {
  return new Promise<TenantMaintenanceListData>((resolve, reject) => {
    API.put(API_URLS.TENANT_MAINTENANCE_EDIT.replace('{id}',id), payload, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }).then(response => {
        if(response.status === 201 || response.status === 200) {
            return resolve(response.data);
        } else {
            throw `request failed with status ${response.status}`;
        }
    }).catch(err => {
        return reject(err);
    })
});
}

const getMaintenanceById = async(id:string) => {
  try {
    const response = await API.get(API_URLS.GET_MAINTENANCE_BY_ID.replace('{id}',id))
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const deleteSelectedMaintenance = async (id: string ) => {
      try {
        const response = await API.delete(API_URLS.DELETE_SELECTED_MAINTENANCE_BY_ID.replace('{id}',id))
        if (response.status === 204 || response.status === 200 || response.status === 201) {
          return ('Data deleted succesfully')
        } else {
          throw `request failed with status ${response.status}`
        }
      } catch (err) {
        return err
      }
}


export const tenantService = {
    getTenantPaymentHistory,
    getTenantMaintenanceTasks,
    createTenantMaintenanceTask,
    getNonFinancialStatsForTenant,
    setUpPaymentIntentForTenant,
    registerTenant,
    tenantPayRent,
    TenantPaymentScheduling,
    getPaymentMethod,
    updateTenantMaintenance,
    getMaintenanceById,
    deleteSelectedMaintenance,

  }