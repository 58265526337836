import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { PaymentHistoryListData } from '../../types/overview';

interface OverviewStates {
    paymentHistoryList: PaymentHistoryListData[],
    selectedPaymentHistory: PaymentHistoryListData | null,
    totalListCount: number
}

const initialState: OverviewStates = {
    paymentHistoryList: [],
    selectedPaymentHistory: null,
    totalListCount: 0,
}

const overviewSlice = createSlice({
    name: 'overview',
    initialState,
    reducers: {
        setPaymentHistoryListData: (state, action) => {
            state = { ...state, paymentHistoryList: action.payload.paymentHistoryList, totalListCount: action.payload.totalListCount }
            return state;
        },
        setSelectedPaymentHistory: (state, action) => {
            state = { ...state, selectedPaymentHistory: action.payload }
            return state;
        },
    },
});

const { setPaymentHistoryListData, setSelectedPaymentHistory} = overviewSlice.actions;

export const savePaymentHistoryList = (paymentHistoryList: PaymentHistoryListData[], totalListCount: number) => async (dispatch: Dispatch) => {
    return dispatch(setPaymentHistoryListData({paymentHistoryList, totalListCount}));
}

export const setSelectedPaymentHistoryData = (overViewData: PaymentHistoryListData) => async (dispatch: Dispatch) => {
    return dispatch(setSelectedPaymentHistory(overViewData));
}

export default overviewSlice.reducer;