import { API_URLS } from '../constants/appConstants'
import API from './api'

const getTenantsData = async () => {
  try {
    const response = await API.get(API_URLS.USER_TENANTS_DISPLAY_NAME)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getLandlordsData = async () => {
  try {
    const response = await API.get(API_URLS.USER_LADNDLORDS_DISPLAY_NAME)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getChatList = async () => {
  try {
    const response = await API.get(API_URLS.LAST_MESSAGES)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getChatHistory = async (id: number) => {
  try {
    const response = await API.get(API_URLS.CHAT_HISTORY, {
      params: {
        recipient_id: id,
      },
    })
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getScheduledMessages = async (id: number) => {
  try {
    const response = await API.get(API_URLS.SCHEDULED_MESSAGES, {
      params: {
        recipient_id: id,
      },
    })

    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const markAsReadMessage = async (id: number) => {
  try {
    const response = await API.patch(API_URLS.MARK_AS_READ_MESSAGE, {
      sender_id: id,
    })
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const cancelScheduledMessage = async (id: number) => {
  try {
    const URL = API_URLS.CHATS + id + API_URLS.CANCEL_SCHEDULED_MESSAGE
    const response = await API.post(URL)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

export const chatServices = {
  getTenantsData,
  getLandlordsData,
  getChatList,
  getChatHistory,
  markAsReadMessage,
  getScheduledMessages,
  cancelScheduledMessage,
}
